import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchDeviceChannelConfigById } from "./deviceChannelConfigAPI";

export const initialState = {
  loading: false,
};

const errorMessage = (err) => {
  if (err?.response?.status === 404) {
    return "The device channel configuration could not be found";
  }
  return err?.message;
};

export const fetchDeviceChannelConfig = createAsyncThunk(
  "deviceChannelConfig/fetch",
  async ({ deviceId, profile }, thunkApi) => {
    try {
      return await fetchDeviceChannelConfigById(deviceId, profile);
    } catch (err) {
      // Not dispatching notification - errors will be shown in dialog
      return thunkApi.rejectWithValue(errorMessage(err));
    }
  }
);

export const deviceChannelConfigSlice = createSlice({
  name: "deviceChannelConfig",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeviceChannelConfig.pending, (state) => {
        state.loading = true;
        state.current = null;
        state.error = null;
      })
      .addCase(fetchDeviceChannelConfig.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.current = payload;
      })
      .addCase(
        fetchDeviceChannelConfig.rejected,
        (state, { payload, error }) => {
          state.loading = false;
          state.error = payload || error.message;
        }
      );
  },
});

export const selectDeviceChannelConfig = (state) => state.deviceChannelConfig;

export default deviceChannelConfigSlice.reducer;
