import { Logout } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import PropType from "prop-types";
import { useState } from "react";
import logo from "../../images/logo.png";
import EditProfile from "../UserManagement/EditProfile";
import UserPermissionView from "../UserManagement/UserPermissionView";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const permissionsModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const TopAppBar = ({ onLogout, dateOverride }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [openUserPermissionModal, setOpenUserPermissionModal] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    onLogout();
    handleClose();
  };

  const handleEditProfileClick = () => {
    setEditProfileModalOpen(true);
    handleClose();
  };

  const handleEditProfileClose = () => {
    setEditProfileModalOpen(false);
  };

  const handleViewUserPermissions = () => {
    setOpenUserPermissionModal(true);
    handleClose();
  };

  const handleUserPermissionsClose = () => {
    setOpenUserPermissionModal(false);
  };

  return (
    <Toolbar data-testid="component-topappbar">
      <Link to="/">
        <Box component="img" src={logo} sx={{ maxWidth: "100px" }} />
      </Link>
      <Box sx={{ flexGrow: 1 }} />
      {dateOverride && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "white", fontSize: "8pt" }}>
            Date Override Active
          </Typography>
          <Typography sx={{ color: "white", fontSize: "8pt" }}>
            {dateOverride}
          </Typography>
        </Box>
      )}
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          data-testid="menu-icon"
        >
          <Avatar />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleEditProfileClick}>
          <PersonIcon fontSize="small" data-testid="edit-profile-button" />
          Edit Profile
        </MenuItem>
        <MenuItem onClick={handleViewUserPermissions}>
          <AccessibilityIcon
            fontSize="small"
            data-testid="view-user-permissions-button"
          />
          Permissions
        </MenuItem>
        <MenuItem onClick={handleLogoutClick}>
          <Logout fontSize="small" data-testid="logout-button" />
          Logout
        </MenuItem>
      </Menu>

      <Modal
        open={editProfileModalOpen}
        onClose={handleEditProfileClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <EditProfile onClose={handleEditProfileClose} />
        </Box>
      </Modal>

      <Modal
        open={openUserPermissionModal}
        onClose={handleUserPermissionsClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={permissionsModalStyle}>
          <UserPermissionView onClose={handleUserPermissionsClose} />
        </Box>
      </Modal>
    </Toolbar>
  );
};

TopAppBar.propTypes = {
  onLogout: PropType.func.isRequired,
  dateOverride: PropType.string,
};

export default TopAppBar;
