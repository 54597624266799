import React from "react";
import { Button, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

const CustomButton = ({
  text,
  onClick,
  isLoading,
  sx,
  startIcon,
  size = "medium",
}) => {
  const defaultSx = {
    color: "white",
    "&:hover": {
      backgroundColor: "#2196f3",
      opacity: "70%",
    },
    backgroundColor: "#2196f3",
    opacity: "100%",
    width: "120px",
    maxWidth: "120px",
    borderRadius: 10,
    ...sx,
  };

  return (
    <Button startIcon={startIcon} size={size} onClick={onClick} sx={defaultSx}>
      {text}
      {isLoading && (
        <CircularProgress size={24} style={{ marginLeft: "10px" }} />
      )}
    </Button>
  );
};

CustomButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  sx: PropTypes.object,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  startIcon: PropTypes.node,
};

export default CustomButton;
