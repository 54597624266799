import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchMaxPsdPerFrequency as fetchMaxPsdPerFrequencyApi } from "./maxPsdPerFrequencyAPI";
import { error } from "../notification/notificationSlice";
import { frequencyBands } from "../../components/DeviceInformation/MaxPsdPerFrequency/frequencyBands";
import { deepEqual } from "fast-equals";

export const initialState = {
  resultList: [],
  isFetchingMaxPsdPerFrequency: false,
  selectedBand: undefined,
  bandList: [],
  currentFilter: undefined,
};

export const getDistinctBandIds = (resultList) => {
  const bandList = [];
  resultList
    .filter((row) => row.average != null)
    .forEach((row) => {
      if (frequencyBands.UNII5.isInBand(row.frequency)) {
        if (!bandList.includes(frequencyBands.UNII5.id)) {
          bandList.push(frequencyBands.UNII5.id);
        }
      } else if (frequencyBands.UNII7.isInBand(row.frequency)) {
        if (!bandList.includes(frequencyBands.UNII7.id)) {
          bandList.push(frequencyBands.UNII7.id);
        }
      }
    });
  bandList.sort((a, b) => a - b);
  return bandList;
};

export const fetchMaxPsdPerFrequency = createAsyncThunk(
  "maxPsdPerFrequency/fetch",
  async (deviceFilter, thunkApi) => {
    try {
      thunkApi.dispatch(updateCurrentFilter(deviceFilter));
      return await fetchMaxPsdPerFrequencyApi(deviceFilter);
    } catch (err) {
      thunkApi.dispatch(error(err?.message, "Loading PSD per frequency data"));
      return thunkApi.rejectWithValue(err?.message);
    }
  },
  {
    condition: (newFilter, { getState }) => {
      const {
        maxPsdPerFrequency: { currentFilter },
      } = getState();
      if (deepEqual(currentFilter, newFilter)) {
        return false;
      }
      return true;
    },
  }
);

export const maxPsdPerFrequencySlice = createSlice({
  name: "maxPsdPerFrequency",
  initialState,
  reducers: {
    setSelectedBand: {
      reducer: (state, action) => {
        state.selectedBand = action.payload.band;
      },
      prepare: (band) => {
        return { payload: { band: band.id } };
      },
    },
    updateCurrentFilter(state, action) {
      state.currentFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMaxPsdPerFrequency.pending, (state) => {
        state.isFetchingMaxPsdPerFrequency = true;
      })
      .addCase(fetchMaxPsdPerFrequency.fulfilled, (state, action) => {
        state.isFetchingMaxPsdPerFrequency = false;
        state.resultList = action.payload;
        // Get a list of distinct bands in the result,
        // so we can check the selected band is still valid
        state.bandList = getDistinctBandIds(state.resultList);
        if (!state.bandList.includes(state.selectedBand)) {
          state.selectedBand = undefined;
        }
        // If there is no band selected default to the first
        if (!state.selectedBand && state.bandList.length > 0) {
          state.selectedBand = state.bandList[0];
        }
      })
      .addCase(fetchMaxPsdPerFrequency.rejected, (state, action) => {
        state.isFetchingMaxPsdPerFrequency = false;
        state.bandList = undefined;
        state.currentFilter = undefined;
      });
  },
});

export const getBandForId = (bandId) => {
  switch (bandId) {
    case frequencyBands.UNII5.id:
      return frequencyBands.UNII5;
    case frequencyBands.UNII7.id:
      return frequencyBands.UNII7;
    default:
      return undefined;
  }
};

export const { setSelectedBand, updateCurrentFilter } =
  maxPsdPerFrequencySlice.actions;

export const selectMaxPsdPerFrequency = (state) =>
  state.maxPsdPerFrequency.resultList;
export const selectIsFetchingMaxPsdPerFrequency = (state) =>
  state.maxPsdPerFrequency.isFetchingMaxPsdPerFrequency;
export const selectedBand = (state) =>
  getBandForId(state.maxPsdPerFrequency.selectedBand);
export const selectBandList = (state) =>
  state.maxPsdPerFrequency.bandList?.map((bandId) => getBandForId(bandId)) ??
  [];

export default maxPsdPerFrequencySlice.reducer;
