import PropType from "prop-types";
import { Chip } from "@mui/material";

const DeviceLocationChip = ({ deviceFilter, onDelete }) => {
  // Do not render the chip if the filter is not set
  if (deviceFilter.indoor === undefined) {
    return null;
  }

  const location = deviceFilter.indoor ? "Indoor" : "Outdoor";

  return (
    <Chip
      onDelete={onDelete}
      label={`Device location [${location}]`}
      data-testid="device-location-chip"
    />
  );
};

DeviceLocationChip.propTypes = {
  deviceFilter: PropType.shape({
    indoor: PropType.bool,
  }),
  onDelete: PropType.func,
};

export default DeviceLocationChip;
