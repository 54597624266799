import {
  CircularProgress,
  Divider,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import SpeedIcon from "@mui/icons-material/Speed";
import { Box } from "@mui/system";
import LatencyChart from "./LatencyChart";
import FilterDrawer from "./FilterDrawer/FilterDrawer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSystemKpiFilter } from "../../../redux/systemKpiFilter/systemKpiFilterSlice";
import {
  selectAggregateKpi,
  selectAggregateLatency,
  selectIsFetchingAggregateKpi,
  selectIsFetchingAggregateLatency,
  selectIsFetchingKpiGrid,
  selectKpiGrid,
  updateAllAggregateKpi,
} from "../../../redux/systemKpi/systemKpiSlice";
import { selectUserConfig } from "../../../redux/userConfig/ConfigSlice";
import ErrorChart from "./ErrorChart";
import KpiGridTable from "./KpiGridTable";
import ErrorBoundary from "../../ErrorBoundary";

const KpiSystem = () => {
  const filter = useSelector(selectSystemKpiFilter);
  // TODO: Remove if not needed
  const {
    primaryGlobalVendorExtensionName,
    primaryRequestVendorExtensionName,
  } = useSelector(selectUserConfig);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateAllAggregateKpi(filter));
  }, [filter]);

  const aggregateKpi = useSelector(selectAggregateKpi);
  const aggregateLatency = useSelector(selectAggregateLatency);
  const kpiGrid = useSelector(selectKpiGrid);

  const isFetchingLatency = useSelector(selectIsFetchingAggregateLatency);
  const isFetchinKpi = useSelector(selectIsFetchingAggregateKpi);
  const isFetchingKpiGrid = useSelector(selectIsFetchingKpiGrid);

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [displayType, setDisplayType] = useState("latency");
  const handleDisplayTypeChange = (newValue) => {
    if (newValue) {
      setDisplayType(newValue);
    }
  };

  return (
    <Box
      data-testid="container-kpi-system"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 1,
        height: 1,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          minHeight: "40px",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" sx={{ fontSize: "1.5rem", padding: "10px" }}>
            Aggregate Device KPI
          </Typography>
        </Box>
        <IconButton
          onClick={() => setFilterDrawerOpen(true)}
          data-testid="kpi-filter-btn"
        >
          <FilterListIcon />
        </IconButton>
        <FilterDrawer
          show={filterDrawerOpen}
          onClose={() => setFilterDrawerOpen(false)}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: 1,
            height: 0.5,
            padding: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: 1,
              display: "flex",
              flexDirection: "row-reverse",
              padding: "10px",
            }}
          >
            <ToggleButtonGroup
              color="primary"
              exclusive
              value={displayType}
              onChange={(_, newValue) => handleDisplayTypeChange(newValue)}
            >
              <ToggleButton value="latency">
                <Tooltip title="Show Latency" placement="bottom">
                  <SpeedIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="error">
                <Tooltip title="Show Status" placement="bottom">
                  <WarningAmberIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {displayType === "latency" &&
            !isFetchingLatency &&
            aggregateLatency &&
            aggregateLatency.length > 0 && (
              <ErrorBoundary>
                <LatencyChart
                  data={aggregateLatency}
                  hasGlobalExtension={!!primaryGlobalVendorExtensionName}
                  hasRequestExtension={!!primaryRequestVendorExtensionName}
                />
              </ErrorBoundary>
            )}
          {displayType === "latency" &&
            !isFetchingLatency &&
            aggregateLatency &&
            aggregateLatency.length === 0 && (
              <Box
                sx={{
                  display: "flex",
                  width: 1,
                  height: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>No data to display</Typography>
              </Box>
            )}
          {displayType === "latency" && isFetchingLatency && (
            <Box
              sx={{
                display: "flex",
                width: 1,
                height: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}

          {displayType === "error" &&
            !isFetchinKpi &&
            aggregateKpi &&
            aggregateKpi.length > 0 && (
              <ErrorBoundary>
                <ErrorChart data={aggregateKpi} />
              </ErrorBoundary>
            )}
          {displayType === "error" &&
            !isFetchinKpi &&
            aggregateLatency &&
            aggregateLatency.length === 0 && (
              <Box
                sx={{
                  display: "flex",
                  width: 1,
                  height: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>No data to display</Typography>
              </Box>
            )}
          {displayType === "error" && isFetchinKpi && (
            <Box
              sx={{
                display: "flex",
                width: 1,
                height: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: 1,
            height: 0.5,
            padding: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isFetchingKpiGrid && (
            <Box
              sx={{
                display: "flex",
                width: 1,
                height: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {kpiGrid && !isFetchingKpiGrid && (
            <ErrorBoundary>
              <KpiGridTable
                rows={kpiGrid}
                globalExtensionName={primaryGlobalVendorExtensionName}
                requestExtensionName={primaryRequestVendorExtensionName}
              />
            </ErrorBoundary>
          )}
          {!kpiGrid && !isFetchingKpiGrid && (
            <Box
              sx={{
                display: "flex",
                width: 1,
                height: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>No data to display</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default KpiSystem;
