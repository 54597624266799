import PropType from "prop-types";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SquareIcon from "@mui/icons-material/Square";
import { Box } from "@mui/material";

const DeviceChannelConfigLegendItem = ({ item }) => (
  <ListItem>
    <ListItemIcon sx={{ minWidth: "30px" }}>
      <Box
        sx={{
          height: "15px",
          width: "15px",
          border: "1px solid black",
          backgroundColor: item.color,
        }}
      ></Box>
    </ListItemIcon>
    <ListItemText primary={item.description} />
  </ListItem>
);

DeviceChannelConfigLegendItem.propTypes = {
  item: PropType.shape({
    color: PropType.string.isRequired,
    description: PropType.string.isRequired,
  }).isRequired,
};

export default DeviceChannelConfigLegendItem;
