import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deepEqual } from "fast-equals";
import { error } from "../notification/notificationSlice";
import { fetchIncumbentClusters } from "./incumbentClusterAPI";

export const initialState = {
  isFetchingIncumbentClusterList: false,
};

export const fetchIncumbentClusterList = createAsyncThunk(
  "incumbentCluster/fetchIncumbentCluster",
  async (incumbentFilter, thunkApi) => {
    try {
      thunkApi.dispatch(updateCurrentFilter(incumbentFilter));
      return await fetchIncumbentClusters(incumbentFilter);
    } catch (err) {
      thunkApi.dispatch(error(err.message, "Loading incumbent locations"));
      return thunkApi.rejectWithValue(err.message);
    }
  },
  {
    condition: (newFilter, { getState }) => {
      // Only fetch if the filters have changed, prevents multiple fetches for the same data
      const {
        incumbentCluster: { currentFilter },
      } = getState();
      if (deepEqual(newFilter, currentFilter)) {
        return false;
      }
    },
  }
);

export const incumbentClusterSlice = createSlice({
  name: "incumbentCluster",
  initialState,
  reducers: {
    updateCurrentFilter(state, action) {
      state.currentFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncumbentClusterList.pending, (state) => {
        state.isFetchingIncumbentClusterList = true;
      })
      .addCase(fetchIncumbentClusterList.fulfilled, (state, action) => {
        state.isFetchingIncumbentClusterList = false;
        state.incumbentClusterList = action.payload;
      })
      .addCase(fetchIncumbentClusterList.rejected, (state, action) => {
        state.isFetchingIncumbentClusterList = false;
        delete state.incumbentClusterList;
        delete state.currentFilter;
      });
  },
});

export const { updateCurrentFilter } = incumbentClusterSlice.actions;

export const selectIncumbentClusterList = (state) =>
  state.incumbentCluster.incumbentClusterList;
export const selectIsFetchingIncumbentClusterList = (state) =>
  state.incumbentCluster.isFetchingIncumbentClusterList;

export default incumbentClusterSlice.reducer;
