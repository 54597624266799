import React, { useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "../CustomButton/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  height: "400px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const buttonStyle = {
  position: "sticky",
  bottom: 0,
  background: "white",
  zIndex: 1,
  paddingTop: "16px",
  borderTop: "1px solid #e0e0e0",
};

const ManageTokensModal = ({
  open,
  tokens,
  handleClose,
  handleDeleteToken,
  handleCreateToken,
  isLoading,
  mode,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [tokenToDelete, setTokenToDelete] = useState(null);
  const [newTokenName, setNewTokenName] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const handleDeleteClick = (token) => {
    setIsDeleteDialogOpen(true);
    setTokenToDelete(token);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const handleDeleteCloseDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (tokenToDelete) {
      handleDeleteToken(tokenToDelete);
    }
  };

  const handleTokenNameChange = (event) => {
    setNewTokenName(event.target.value);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box sx={style}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CloseIcon
                onClick={handleClose}
                style={{ cursor: "pointer" }}
                data-testid="close-icon"
              />
              <Typography
                variant="h2"
                sx={{ fontSize: "1.5rem", padding: "10px" }}
              >
                {mode === "manage" ? "Manage Tokens" : "View Tokens"}
              </Typography>
            </div>
            <Autocomplete
              value={filterValue}
              onChange={(event, newValue) => {
                setFilterValue(newValue === null ? "" : newValue);
              }}
              options={tokens.map((token) => token.tokenName)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter Tokens"
                  variant="outlined"
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option === value || (!value && value === "") || option === ""
              }
            />
            <List data-testid="token-list">
              {Array.isArray(tokens) &&
                !isLoading &&
                tokens
                  .filter((token) =>
                    token.tokenName
                      .toLowerCase()
                      .includes(filterValue.toLowerCase())
                  )
                  .map((token) => (
                    <ListItem
                      key={token.tokenName}
                      secondaryAction={
                        mode === "manage" && (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDeleteClick(token)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )
                      }
                    >
                      <ListItemText primary={token.tokenName} />
                    </ListItem>
                  ))}
            </List>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
              data-testid="busy-backdrop"
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCloseDialog}>
              <DialogTitle>{"Confirm Deletion"}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {`Are you sure you want to delete the token: ${tokenToDelete?.tokenName}?`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeleteCloseDialog} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={isDialogOpen}
              onClose={handleCloseDialog}
              fullWidth
              maxWidth="xs"
            >
              <DialogTitle>Enter Token Name</DialogTitle>
              <DialogContent>
                <TextField
                  label="Token Name"
                  variant="outlined"
                  fullWidth
                  value={newTokenName}
                  onChange={handleTokenNameChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    handleCreateToken(newTokenName);
                    handleCloseDialog();
                  }}
                  color="primary"
                  variant="contained"
                >
                  Create
                </Button>
              </DialogActions>
            </Dialog>
            <div style={buttonStyle}>
              {mode === "manage" && (
                <CustomButton
                  text="Create Token"
                  onClick={() => setIsDialogOpen(true)}
                  sx={{
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#2196f3",
                      opacity: "70%",
                    },
                    backgroundColor: "#2196f3",
                    opacity: "100%",
                    width: "120px",
                    maxWidth: "120px",
                    borderRadius: 1,
                    marginRight: "10%",
                  }}
                />
              )}
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

ManageTokensModal.propTypes = {
  open: PropTypes.bool.isRequired,
  tokens: PropTypes.arrayOf(
    PropTypes.shape({
      tokenName: PropTypes.string,
      org: PropTypes.number,
      createTime: PropTypes.string,
      expireTime: PropTypes.string,
    })
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDeleteToken: PropTypes.func.isRequired,
  handleCreateToken: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

export default ManageTokensModal;
