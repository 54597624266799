import axios from "axios";

export const fetchIncumbentDetailByBoundingBox = async (boundingBox) => {
  const response = await axios.post(
    `${window.config.API_ROOT}/incumbentDetail`,
    boundingBox,
    {
      headers: {
        accept: "application/json",
        Authorization: "auth-token",
      },
    }
  );

  if (response.status !== 200) {
    throw Error(`An error response code was returned: ${response.status}`);
  }
  return response.data;
};
