import { Box, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import PropType from "prop-types";
import {
  frequencyBandPropType,
  frequencyBands as bands,
} from "./frequencyBands";

const BandSelector = ({ frequencyBands, selectedBand, onChange }) => {
  const handleChange = (_, band) => {
    // If the selected band is clicked null will be passed
    if (band && onChange) {
      onChange(band);
    }
  };

  const haveUNII5 = !!(
    frequencyBands && frequencyBands.find((band) => band.id == 5)
  );
  const haveUNII7 = !!(
    frequencyBands && frequencyBands.find((band) => band.id == 7)
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "10px",
      }}
    >
      <Tooltip title="Global Operating Class">
        <ToggleButtonGroup
          size="small"
          exclusive
          onChange={handleChange}
          value={selectedBand}
        >
          <ToggleButton value={bands.UNII5} disabled={!haveUNII5}>
            UNII-5
          </ToggleButton>
          <ToggleButton value={bands.UNII7} disabled={!haveUNII7}>
            UNII-7
          </ToggleButton>
        </ToggleButtonGroup>
      </Tooltip>
    </Box>
  );
};

BandSelector.propTypes = {
  frequencyBands: PropType.arrayOf(frequencyBandPropType).isRequired,
  selectedBand: frequencyBandPropType.isRequired,
  onChange: PropType.func.isRequired,
};

export default BandSelector;
