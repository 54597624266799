import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadUsersCsv, uploadOrganizationsCsv } from "./uploadCsvAPI";

export const initiateUploadUsersCsvFile = createAsyncThunk(
  "usersCsvUpload/initiate",
  async ({ file }, thunkApi) => {
    try {
      const response = await uploadUsersCsv(file);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error.toString());
    }
  }
);

const usersCsvUploadSlice = createSlice({
  name: "usersCsvUpload",
  initialState: {
    loading: false,
    error: null,
    success: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateUploadUsersCsvFile.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(initiateUploadUsersCsvFile.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(initiateUploadUsersCsvFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

export const initiateUploadOrganizationsCsvFile = createAsyncThunk(
  "organiationsCsvUpload/initiate",
  async ({ file }, thunkApi) => {
    try {
      const response = await uploadOrganizationsCsv(file);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error.toString());
    }
  }
);

const organizationsCsvUploadSlice = createSlice({
  name: "organiationsCsvUpload",
  initialState: {
    loading: false,
    error: null,
    success: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateUploadOrganizationsCsvFile.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(initiateUploadOrganizationsCsvFile.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(initiateUploadOrganizationsCsvFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

export const usersCsvUploadReducer = usersCsvUploadSlice.reducer;
export const organizationsCsvUploadReducer =
  organizationsCsvUploadSlice.reducer;
