import {
  Box,
  Drawer,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropType from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserConfig } from "../../../../redux/userConfig/ConfigSlice";
import {
  selectSystemKpiFilter,
  updateSystemKpiFilter,
} from "../../../../redux/systemKpiFilter/systemKpiFilterSlice";
import { deepEqual } from "fast-equals";
import {
  selectFccCertificationIdValues,
  selectPrimaryGlobalVendorExtensionValues,
  selectPrimaryRequestVendorExtensionValues,
  updateExtensionFilters,
} from "../../../../redux/systemKpi/systemKpiSlice";
import DateRangeFilter from "../../Filters/DateRangeFilter";
import { intervalTypes } from "../../Filters/intervalTypes";
import PrimaryExtensionFilter from "../../Filters/PrimaryExtensionFilter";
import { selectFccCertificationIds } from "../../../../redux/lookup/lookupSlice";
import FccCertificationIdFilter from "../../Filters/FccCertificationIdFilter";

function removeUndefinedProperties(obj) {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    } else if (Array.isArray(obj[key]) && obj[key].length == 0) {
      delete obj[key];
    }
  }
}

const FilterDrawer = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const {
    primaryGlobalVendorExtensionName,
    primaryRequestVendorExtensionName,
  } = useSelector(selectUserConfig);
  const globalExtensionValues = useSelector(
    selectPrimaryGlobalVendorExtensionValues
  );
  const requestExtensionValues = useSelector(
    selectPrimaryRequestVendorExtensionValues
  );
  const fccCertificationIdValues = useSelector(selectFccCertificationIdValues);
  const currentFilter = useSelector(selectSystemKpiFilter);

  const [interval, setInterval] = useState(
    currentFilter.interval?.id
      ? intervalTypes[currentFilter.interval.id]
      : intervalTypes.DAILY
  );
  const [dateRange, setDateRange] = useState(
    currentFilter.dateRange ?? {
      startDate: interval.defaultStartDate(),
      endDate: interval.defaultEndDate(),
    }
  );

  const [primaryGlobalExensionList, setPrimaryGlobalExtensionList] = useState(
    currentFilter.primaryGlobalExensionList ?? []
  );
  const [primaryRequestExensionList, setPrimaryRequestExtensionList] = useState(
    currentFilter.primaryRequestExensionList ?? []
  );
  const [fccCertificationIdList, setFccCertificationIdList] = useState(
    currentFilter.fccCertificationIdList ?? []
  );

  useEffect(() => {
    dispatch(updateExtensionFilters(getNewFilter()));
  }, [interval, dateRange]);

  const getNewFilter = () => ({
    intervalType: interval.id,
    startDate: dateRange?.startDate,
    endDate: dateRange?.endDate,
    primaryGlobalVendorExtensions: primaryGlobalExensionList,
    primaryRequestVendorExtensions: primaryRequestExensionList,
    fccCertificationIds: fccCertificationIdList,
  });

  const handleClose = () => {
    const newFilter = getNewFilter();
    if (!deepEqual(newFilter, currentFilter)) {
      removeUndefinedProperties(newFilter);
      dispatch(updateSystemKpiFilter(newFilter));
    }
    onClose();
  };

  const handleDateRangeChange = (newValue) => {
    setDateRange(newValue);
  };

  const handleIntervalChange = (interval) => {
    setInterval(interval);
    setDateRange({
      startDate: interval.defaultStartDate(),
      endDate: interval.defaultEndDate(),
    });
  };

  const handlePrimaryGlobalExtensionChange = (extensionList) => {
    setPrimaryGlobalExtensionList(extensionList);
  };

  const handlePrimaryRequestExtensionChange = (extensionList) => {
    setPrimaryRequestExtensionList(extensionList);
  };

  const handleFccCertificationIdChange = (fccCertificationIdList) => {
    setFccCertificationIdList(fccCertificationIdList);
  };

  return (
    <Drawer
      anchor="right"
      open={show}
      onClose={handleClose}
      data-testid="kpi-filter-drawer"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          border: "30px",
        }}
      >
        <IconButton onClick={handleClose} data-testid="filter-close">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          paddingLeft: "32px",
          paddingRight: "32px",
          minWidth: "80ch",
          maxWidth: "80ch",
        }}
      >
        <Stack spacing={4}>
          <Typography align="center" variant="h4">
            Device Filters
          </Typography>
          <TextField
            name="interval"
            label="Interval"
            select
            onChange={(event) => handleIntervalChange(event.target.value)}
            value={interval}
          >
            <MenuItem value={intervalTypes.DAILY}>Daily</MenuItem>
            <MenuItem value={intervalTypes.MONTHLY}>Monthly</MenuItem>
          </TextField>
          <DateRangeFilter
            interval={interval}
            onChange={handleDateRangeChange}
            start={dateRange.startDate}
            end={dateRange.endDate}
          />
          {fccCertificationIdValues && fccCertificationIdValues.length > 0 && (
            <FccCertificationIdFilter
              extensionListOptions={fccCertificationIdValues}
              extensionName={"FCC ID"}
              onChange={handleFccCertificationIdChange}
              selectedValues={fccCertificationIdList}
            />
          )}
          {primaryGlobalVendorExtensionName &&
            globalExtensionValues &&
            globalExtensionValues.length > 0 && (
              <PrimaryExtensionFilter
                extensionListOptions={globalExtensionValues}
                extensionName={primaryGlobalVendorExtensionName}
                onChange={handlePrimaryGlobalExtensionChange}
                selectedValues={primaryGlobalExensionList}
              />
            )}
          {primaryRequestVendorExtensionName &&
            requestExtensionValues &&
            requestExtensionValues.length > 0 && (
              <PrimaryExtensionFilter
                extensionListOptions={requestExtensionValues}
                extensionName={primaryRequestVendorExtensionName}
                onChange={handlePrimaryRequestExtensionChange}
                selectedValues={primaryRequestExensionList}
              />
            )}
        </Stack>
      </Box>
    </Drawer>
  );
};

FilterDrawer.propTypes = {
  show: PropType.bool.isRequired,
  onClose: PropType.func.isRequired,
};

export default FilterDrawer;
