import PropType from "prop-types";
import { Chip } from "@mui/material";

const IncumbentFrequencyChip = ({ incumbentFilter, onDelete }) => {
  const { frequencyRange } = incumbentFilter;
  // Do not render the chip if the filter is not set
  if (frequencyRange === undefined) {
    return null;
  }

  return (
    <Chip
      color="primary"
      onDelete={onDelete}
      label={`Incumbent [${frequencyRange.minimum} - ${frequencyRange.maximum} MHz]`}
      data-testid="incumbent-frequency-chip"
    />
  );
};

IncumbentFrequencyChip.propTypes = {
  incumbentFilter: PropType.shape({
    frequencyRange: PropType.shape({
      maximum: PropType.number,
      minimum: PropType.number,
    }),
  }),
  onDelete: PropType.func,
};

export default IncumbentFrequencyChip;
