import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { resetUserAuthentication } from "./ResetPasswordAPI";

const initialState = {
  loading: false,
  error: null,
  success: false,
};

export const initiateResetUserAuthentication = createAsyncThunk(
  "resetPassword/initiate",
  async (userEmail, thunkApi) => {
    try {
      return await resetUserAuthentication(userEmail);
    } catch (error) {
      return thunkApi.rejectWithValue(error.toString());
    }
  }
);

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initiateResetUserAuthentication.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(initiateResetUserAuthentication.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(initiateResetUserAuthentication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

export default resetPasswordSlice.reducer;
