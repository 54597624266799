import intervalTypes from "../../../../util/intervalTypes";
import { MAX_EIRP_DBM, MIN_EIRP_DBM } from "./util";
import moment from "moment";

const validate = (state) => {
  const errors = {};

  if (state.maxEIRP == null) {
    errors.maxEIRP = "Required";
  } else if (state.maxEIRP < MIN_EIRP_DBM) {
    errors.maxEIRP = `Must be greater than or equal to ${MIN_EIRP_DBM} dBm`;
  } else if (state.maxEIRP > MAX_EIRP_DBM) {
    errors.maxEIRP = `Must be less than or equal to ${MAX_EIRP_DBM} dBm`;
  }

  const maxStartDateMoment = moment(state.maxStartDate);

  if (!state.intervalType.startDateIgnored) {
    const startDateMoment = moment(state.startDate);

    if (state.intervalType.id === intervalTypes.MONTHLY.id) {
      const firstOfTheMonth = maxStartDateMoment.startOf("month");
      if (startDateMoment < firstOfTheMonth.subtract(365, "day")) {
        errors.startDate = "Must be within 365 of the first of this month";
      }
    } else {
      if (startDateMoment < maxStartDateMoment.subtract(365, "day")) {
        errors.startDate = "Must be within the last 365 days";
      }
    }
  }

  state.errors = errors;
  return state;
};

export default validate;
