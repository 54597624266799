import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { selectDeviceFilter } from "../deviceFilter/deviceFilterSlice";

import { error } from "../notification/notificationSlice";
import {
  fetchFrequencyBand,
  fetchGlobalOperatingClass,
  fetchMaxHeight,
  fetchPrimaryGlobalVendorExtension,
  fetchPrimaryRequestVendorExtension,
  fetchDeviceSerialNumbers,
  fetchCertificationFccIds,
} from "./lookupAPI";

export const initialState = {
  primaryGlobalVendorExtension: undefined,
  primaryRequestVendorExtension: undefined,
  globalOperatingClass: undefined,
  frequencyBand: undefined,
  maxHeight: undefined,
  serialNumbers: [],
};

export const fetchPrimaryGlobalVendorExtensionValues = createAsyncThunk(
  "lookup/fetchPrimaryGlobalVendorExtension",
  async (lookupFilter, thunkApi) => {
    try {
      return await fetchPrimaryGlobalVendorExtension(lookupFilter);
    } catch (err) {
      thunkApi.dispatch(error(err.message, "Loading extension lookup values"));
      return thunkApi.rejectWithValue(err.message);
    }
  }
);

export const fetchPrimaryRequestVendorExtensionValues = createAsyncThunk(
  "lookup/fetchPrimaryRequestVendorExtension",
  async (lookupFilter, thunkApi) => {
    try {
      return await fetchPrimaryRequestVendorExtension(lookupFilter);
    } catch (err) {
      thunkApi.dispatch(error(err.message, "Loading extension lookup values"));
      return thunkApi.rejectWithValue(err.message);
    }
  }
);

export const fetchGlobalOperatingClassValues = createAsyncThunk(
  "lookup/fetchGlobalOperatingClass",
  async (_, thunkApi) => {
    try {
      return await fetchGlobalOperatingClass();
    } catch (err) {
      thunkApi.dispatch(
        error(err.message, "Loading global operating class lookup values")
      );
      return thunkApi.rejectWithValue(err.message);
    }
  }
);

export const fetchFrequencyBandValues = createAsyncThunk(
  "lookup/fetchFrequencyBand",
  async (_, thunkApi) => {
    try {
      return await fetchFrequencyBand();
    } catch (err) {
      thunkApi.dispatch(
        error(err.message, "Loading frequency band lookup values")
      );
      return thunkApi.rejectWithValue(err.message);
    }
  }
);

export const fetchMaxHeightValues = createAsyncThunk(
  "lookup/fetchMaxHeight",
  async (_, thunkApi) => {
    try {
      return await fetchMaxHeight();
    } catch (err) {
      thunkApi.dispatch(error(err.message, "Loading max height lookup values"));
      return thunkApi.rejectWithValue(err.message);
    }
  }
);

export const fetchDeviceSerialNumberValues = createAsyncThunk(
  "lookup/fetchSerialNumbers",
  async (serialNumberFilter, thunkApi) => {
    try {
      const deviceFilter = selectDeviceFilter(thunkApi.getState());
      const lookupFilter = {
        boundingBox: deviceFilter.boundingBox,
      };
      if (serialNumberFilter) {
        lookupFilter.serialNumber = serialNumberFilter;
      }
      return await fetchDeviceSerialNumbers(lookupFilter);
    } catch (err) {
      // Do not display an error, just clear the options
      return thunkApi.rejectWithValue(err.message);
    }
  }
);

export const fetchFccCertificationIdValues = createAsyncThunk(
  "lookup/fetchFccCertificationIds",
  async (fccCertificationIdFilter, thunkApi) => {
    try {
      const deviceFilter = selectDeviceFilter(thunkApi.getState());
      const lookupFilter = {
        boundingBox: deviceFilter.boundingBox,
      };
      if (fccCertificationIdFilter) {
        lookupFilter.fccCertificationId = fccCertificationIdFilter;
      }
      return await fetchCertificationFccIds(lookupFilter);
    } catch (err) {
      // Do not display an error, just clear the options
      return thunkApi.rejectWithValue(err.message);
    }
  }
);

export const fetchLookupValues = createAsyncThunk(
  "lookup/fetchAllValues",
  async (_, thunkApi) => {
    thunkApi.dispatch(fetchPrimaryGlobalVendorExtensionValues());
    thunkApi.dispatch(fetchPrimaryRequestVendorExtensionValues());
    thunkApi.dispatch(fetchGlobalOperatingClassValues());
    thunkApi.dispatch(fetchFrequencyBandValues());
    thunkApi.dispatch(fetchMaxHeightValues());
  }
);

export const lookupSlice = createSlice({
  name: "lookup",
  initialState,
  reducers: {
    clearSerialNumber: (state) => {
      state.serialNumbers = [];
    },
    clearFccCertificationId: (state) => {
      state.fccCertificationIds = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchPrimaryGlobalVendorExtensionValues.fulfilled,
        (state, action) => {
          state.primaryGlobalVendorExtension = action.payload;
        }
      )
      .addCase(
        fetchPrimaryRequestVendorExtensionValues.fulfilled,
        (state, action) => {
          state.primaryRequestVendorExtension = action.payload;
        }
      )
      .addCase(fetchGlobalOperatingClassValues.fulfilled, (state, action) => {
        state.globalOperatingClass = action.payload;
      })
      .addCase(fetchFrequencyBandValues.fulfilled, (state, action) => {
        state.frequencyBand = action.payload;
      })
      .addCase(fetchMaxHeightValues.fulfilled, (state, action) => {
        state.maxHeight = action.payload;
      })
      .addCase(fetchDeviceSerialNumberValues.fulfilled, (state, action) => {
        state.serialNumbers = action.payload;
      })
      .addCase(fetchDeviceSerialNumberValues.rejected, (state, action) => {
        state.serialNumbers = [];
      })
      .addCase(fetchFccCertificationIdValues.fulfilled, (state, action) => {
        state.fccCertificationIds = action.payload;
      })
      .addCase(fetchFccCertificationIdValues.rejected, (state, action) => {
        state.fccCertificationIds = [];
      });
  },
});

export const { clearSerialNumber, clearFccCertificationId } =
  lookupSlice.actions;

export const selectPrimaryGlobalVendorExtensionValues = (state) =>
  state.lookup.primaryGlobalVendorExtension;
export const selectPrimaryRequestVendorExtensionValues = (state) =>
  state.lookup.primaryRequestVendorExtension;
export const selectGlobalOperatingClassValues = (state) =>
  state.lookup.globalOperatingClass;
export const selectFrequencyBandValues = (state) => state.lookup.frequencyBand;
export const selectMaxHeightValues = (state) => state.lookup.maxHeight;
export const selectDeviceSerialNumbers = (state) => state.lookup.serialNumbers;

export const selectFccCertificationIds = (state) =>
  state.lookup.fccCertificationIds;

export default lookupSlice.reducer;
