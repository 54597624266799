import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { error } from "../notification/notificationSlice";
import { fetchSystemPerformance } from "./systemPerformanceAPI";

export const initialState = {
  isFetchingPerformance: false,
  performance: undefined,
};

export const updateSystemPerformance = createAsyncThunk(
  "systemPerformance/fetchSystemPerformance",
  async (filter, thunkApi) => {
    try {
      const { startDate, endDate, intervalType } = filter;
      return await fetchSystemPerformance(startDate, endDate, intervalType);
    } catch (err) {
      thunkApi.dispatch(error(err?.message, "Loading system performance"));
      return thunkApi.rejectWithValue(err?.message);
    }
  }
);

export const systemPerformanceSlice = createSlice({
  name: "systemPerformance",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(updateSystemPerformance.pending, (state) => {
        state.isFetchingPerformance = true;
      })
      .addCase(updateSystemPerformance.fulfilled, (state, action) => {
        state.isFetchingPerformance = false;
        state.performance = action.payload;
      })
      .addCase(updateSystemPerformance.rejected, (state, action) => {
        state.isFetchingPerformance = false;
        state.performance = undefined;
      });
  },
});

export const selectSystemPerformance = (state) =>
  state.systemPerformance.performance;

export const selectIsFetchingPerformance = (state) =>
  state.systemPerformance.isFetchingPerformance;
export default systemPerformanceSlice.reducer;
