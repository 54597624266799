import axios from "axios";

export const fetchDeviceChannelConfigById = async (deviceId, profile) => {
  const params = {};
  if (profile) {
    params.profile = profile;
  }
  const response = await axios.get(
    `${window.config.API_ROOT}/deviceChannelConfig/${deviceId}`,
    {
      headers: {
        accept: "application/json",
      },
      params,
    }
  );
  return response.data;
};
