import PropType from "prop-types";
import { Chip } from "@mui/material";

const DeviceSerialNumberChip = ({ deviceFilter, onDelete }) => {
  // Do not render the chip if the filter is not set
  if (deviceFilter.serialNumber === undefined) {
    return null;
  }

  return (
    <Chip
      onDelete={onDelete}
      label={`Serial Number [${deviceFilter.serialNumber?.length} selected]`}
      data-testid="device-serial-number-chip"
    />
  );
};

DeviceSerialNumberChip.propTypes = {
  deviceFilter: PropType.shape({
    serialNumber: PropType.arrayOf(PropType.string),
  }),
  onDelete: PropType.func,
};

export default DeviceSerialNumberChip;
