import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deepEqual } from "fast-equals";
import {
  fetchPrimaryGlobalVendorExtensionValues,
  fetchPrimaryRequestVendorExtensionValues,
} from "../lookup/lookupSlice";
import { error } from "../notification/notificationSlice";
import { fetchDeviceClusters } from "./deviceClusterAPI";

export const initialState = {
  isFetchingDeviceClusterList: false,
};

export const fetchDeviceClusterList = createAsyncThunk(
  "deviceCluster/fetchDeviceCluster",
  async (deviceFilter, thunkApi) => {
    try {
      thunkApi.dispatch(updateCurrentFilter(deviceFilter));
      // Also update the global and request vendor extensions on every update
      const lookupFilter = {
        boundingBox: deviceFilter.boundingBox,
      };
      thunkApi.dispatch(fetchPrimaryGlobalVendorExtensionValues(lookupFilter));
      thunkApi.dispatch(fetchPrimaryRequestVendorExtensionValues(lookupFilter));
      return await fetchDeviceClusters(deviceFilter);
    } catch (err) {
      thunkApi.dispatch(error(err.message, "Loading device locations"));
      return thunkApi.rejectWithValue(err.message);
    }
  },
  {
    condition: (newFilter, { getState }) => {
      // Only fetch if the filters have changed, prevents multiple fetches for the same data
      const {
        deviceCluster: { currentFilter },
      } = getState();
      if (deepEqual(newFilter, currentFilter)) {
        return false;
      }
    },
  }
);

export const deviceClusterSlice = createSlice({
  name: "deviceCluster",
  initialState,
  reducers: {
    updateCurrentFilter(state, action) {
      state.currentFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeviceClusterList.pending, (state) => {
        state.isFetchingDeviceClusterList = true;
      })
      .addCase(fetchDeviceClusterList.fulfilled, (state, action) => {
        state.isFetchingDeviceClusterList = false;
        state.deviceClusterList = action.payload;
      })
      .addCase(fetchDeviceClusterList.rejected, (state, action) => {
        state.isFetchingDeviceClusterList = false;
        state.deviceClusterList = undefined;
        state.currentFilter = undefined;
      });
  },
});

export const { updateCurrentFilter } = deviceClusterSlice.actions;

export const selectDeviceClusterList = (state) =>
  state.deviceCluster.deviceClusterList;
export const selectIsFetchingDeviceClusterList = (state) =>
  state.deviceCluster.isFetchingDeviceClusterList;

export default deviceClusterSlice.reducer;
