import { createSlice } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import axios from "axios";

const { NO_AUTH } = window;
const USE_ACCESS_TOKEN = [window?.config?.TOKEN_API_ROOT || "v1.0"];

const initialState = NO_AUTH
  ? {
      attributes: {
        "custom:reporting_org_ids": "1",
        sub: "e2bf68e5-c837-4b9f-95f3-df1b2a56a61e",
        email_verified: true,
        email: "example@aruba.com",
      },
    }
  : {};

const getJwtToken = async (useAccessToken = false) => {
  const session = await Auth.currentSession();
  if (NO_AUTH) {
    return "TOKEN";
  } else if (useAccessToken) {
    return session.getAccessToken().getJwtToken();
  } else {
    return session.getIdToken().getJwtToken();
  }
};

axios.interceptors.request.use(async (config) => {
  config.headers.Authorization = `Bearer ${await getJwtToken(
    USE_ACCESS_TOKEN.reduce(
      (result, baseUrl) => result || config.url?.startsWith(baseUrl),
      false
    )
  )}`;
  return config;
});

const amplifySlice = createSlice({
  name: "amplify",
  initialState,
  reducers: {
    signIn: (_, action) => ({ ...action.payload }),
  },
});

export const { signIn } = amplifySlice.actions;

export const selectUsername = (state) => state.user?.attributes?.email;

export default amplifySlice.reducer;
