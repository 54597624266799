import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { error } from "../notification/notificationSlice";
import { fetchUserConfig as fetchUserConfigAPI } from "./ConfigAPI";

const initialState = {
  config: undefined,
  fetchingUserConfig: false,
};

export const fetchUserConfig = createAsyncThunk(
  "userConfig/fetchUserConfig",
  async (_, thunkApi) => {
    try {
      return await fetchUserConfigAPI();
    } catch (err) {
      thunkApi.dispatch(
        error(err.message, "Unable to load user's configuration settings")
      );
      return thunkApi.rejectWithValue(err.message);
    }
  }
);

export const userConfigSlice = createSlice({
  name: "userConfig",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserConfig.pending, (state) => {
        state.fetchingUserConfig = true;
      })
      .addCase(fetchUserConfig.fulfilled, (state, action) => {
        state.fetchingUserConfig = false;
        state.config = action.payload;
      })
      .addCase(fetchUserConfig.rejected, (state, action) => {
        state.fetchingUserConfig = false;
        state.config = undefined;
      });
  },
});

export const selectIsFetchingUserConfig = (state) =>
  state.userConfig.fetchingUserConfig;
export const selectUserConfig = (state) => state.userConfig.config;

export default userConfigSlice.reducer;
