import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropType from "prop-types";

import Grid from "@mui/material/Grid";

import {
  fetchDeviceDetail,
  selectDeviceDetail,
} from "../../../../redux/deviceDetail/deviceDetailSlice";

import DeviceDetailSkeleton from "./DeviceDetailSkeleton";
import DeviceDetailTable from "./DeviceDetailTable";
import { selectUserConfig } from "../../../../redux/userConfig/ConfigSlice";

const DeviceDetail = ({ device, show }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (device?.id != null) {
      dispatch(fetchDeviceDetail(device?.id));
    }
  }, [device]);

  const { loading, current, error } = useSelector(selectDeviceDetail);
  const userConfig = useSelector(selectUserConfig);

  const loaded = !loading && !!current;

  return (
    <Grid container sx={{ display: { xs: !show ? "none" : "" } }}>
      <Grid item xs={12}>
        {loaded && (
          <DeviceDetailTable device={current} userConfig={userConfig} />
        )}
        {!loaded && <DeviceDetailSkeleton error={error} />}
      </Grid>
    </Grid>
  );
};

DeviceDetail.propTypes = {
  device: PropType.shape({
    id: PropType.number,
    serialNumber: PropType.string,
  }),
  show: PropType.bool.isRequired,
};

export default DeviceDetail;
