import PropType from "prop-types";
import { Autocomplete, FormControl, TextField } from "@mui/material";

const GlobalExtensionFilter = ({
  labelName = "Instance ID",
  value = [],
  options,
  onChange,
}) => {
  const fireOnChange = (value) => {
    if (value && value.length === 0) {
      onChange(undefined);
    } else {
      onChange(value);
    }
  };

  const handleChange = (event, newValue) => {
    fireOnChange(newValue);
  };

  return (
    <>
      <FormControl sx={{ width: "500px" }}>
        <Autocomplete
          multiple
          filterSelectedOptions
          disableCloseOnSelect
          data-testid="global-extension"
          value={value || []}
          onChange={handleChange}
          disabled={!options}
          renderInput={(params) => (
            <TextField {...params} label={labelName} placeholder={labelName} />
          )}
          options={options || []}
        />
      </FormControl>
    </>
  );
};

GlobalExtensionFilter.propTypes = {
  labelName: PropType.string,
  value: PropType.arrayOf(PropType.string),
  options: PropType.arrayOf(PropType.string),
  onChange: PropType.func.isRequired,
};

export default GlobalExtensionFilter;
