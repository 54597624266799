import { Box, Paper, Popper, Typography } from "@mui/material";
import PropType from "prop-types";
import { useState } from "react";
import { toChartDescription } from "./chartColor";
import SmallLegend from "./SmallLegend";

const ChannelChart = ({
  channelBandwidth,
  chartWidth,
  chartData,
  testId,
  minEirp,
}) => {
  return (
    <Box
      sx={{
        width: 1,
        height: "100px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        overflow: "hidden",
      }}
      data-testid={testId}
    >
      <Box sx={{ minWidth: "51px", textAlign: "center" }}>
        <Typography sx={{ fontSize: "0.75rem" }}>
          {channelBandwidth} MHz
        </Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {chartData.map((row, index) => (
          <ChannelBar
            key={row.cfi}
            channelBandwidth={channelBandwidth}
            chartWidth={chartWidth}
            row={row}
            minEirp={minEirp}
          />
        ))}
      </Box>
    </Box>
  );
};

const maxEirp = 36;
const ChannelBar = ({ channelBandwidth, chartWidth, row, minEirp }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const barHeight =
    0.25 + 0.75 * (((row.eirp ?? minEirp) - minEirp) / (maxEirp - minEirp));
  return (
    <>
      <Box
        sx={{
          height: "100px",
          minWidth: `${(channelBandwidth / chartWidth) * 100}%`,
          borderLeft: "1px solid #e0e0e0",
          display: "flex",
          flexDirection: "column-reverse",
          boxSizing: "border-box",
        }}
        aria-haspopup="true"
        data-testid={`eirp${row.cfi}`}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Box sx={{ minHeight: "18px" }}></Box>
        <Box
          sx={{
            height: `${64 * barHeight}px`,
            backgroundColor: row.color,
            width: 1,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ fontSize: "0.5rem" }}>{row.cfi}</Typography>
        </Box>
      </Box>
      <Popper
        sx={{
          zIndex: 2000,
        }}
        placement={"right"}
        open={open}
        anchorEl={anchorEl}
      >
        <Paper sx={{ padding: "5px" }}>
          <Typography sx={{ fontSize: "0.75rem" }}>
            Channel: {row.cfi}
          </Typography>
          <Typography sx={{ fontSize: "0.75rem" }}>
            {row.centerFreq} MHz
          </Typography>
          {row.eirp && (
            <Typography sx={{ fontSize: "0.75rem" }}>
              EIRP: {row.eirp}
            </Typography>
          )}
          <SmallLegend color={row.color} description={row.description} />
        </Paper>
      </Popper>
    </>
  );
};

ChannelBar.propTypes = {
  channelBandwidth: PropType.number, // The channel bandwisth in MHz
  chartWidth: PropType.number, // The width of the chart in MHz
  row: PropType.shape({
    eirp: PropType.number,
    cfi: PropType.number,
    centerFreq: PropType.number,
    color: PropType.string,
    description: PropType.string,
  }),
  minEirp: PropType.number,
};

ChannelChart.propTypes = {
  channelBandwidth: PropType.number, // The channel bandwisth in MHz
  chartWidth: PropType.number, // The width of the chart in MHz
  chartData: PropType.arrayOf(
    PropType.shape({
      eirp: PropType.number,
      color: PropType.string,
    })
  ),
  testId: PropType.string,
  minEirp: PropType.number,
};

export default ChannelChart;
