import PropType from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedDevices,
  fetchAllDeviceDetail,
  selectDeviceDetailList,
} from "../../../redux/deviceDetail/deviceDetailSlice";
import { displayIncumbentDeviceOverlap } from "../../../redux/incumbentContour/incumbentContourSlice";
import { selectIncumbentFilter } from "../../../redux/incumbentFilter/incumbentFilterSlice";
import { selectUserConfig } from "../../../redux/userConfig/ConfigSlice";
import DeviceDialog from "./DeviceDialog";

const DeviceDialogContainer = ({ deviceIdList, onClose, onDeviceClick }) => {
  const userConfig = useSelector(selectUserConfig);

  const dispatch = useDispatch();
  useEffect(() => {
    if (deviceIdList.length > 0) {
      dispatch(fetchAllDeviceDetail(deviceIdList));
    }
  }, [deviceIdList]);

  const deviceDetailList = useSelector(selectDeviceDetailList);
  const incumbentFilter = useSelector(selectIncumbentFilter);

  // Only render if we are given a list of devices
  if (deviceIdList.length === 0) {
    return null;
  }

  const handleClose = () => {
    dispatch(clearSelectedDevices());
    onClose();
  };

  const handleDeviceClick = (deviceDetail) => {
    handleClose();
    onDeviceClick(deviceDetail);
  };

  const handleFindIncumbents = (deviceDetail) => {
    handleClose();
    dispatch(
      displayIncumbentDeviceOverlap({
        deviceId: deviceDetail.id,
        incumbentFilter,
      })
    );
  };

  return (
    <DeviceDialog
      onClose={handleClose}
      numberOfDevices={deviceIdList.length}
      deviceDetailList={deviceDetailList}
      onShowDeviceDetail={handleDeviceClick}
      onFindIncumbents={handleFindIncumbents}
      userConfig={userConfig}
    />
  );
};

DeviceDialogContainer.propTypes = {
  deviceIdList: PropType.array.isRequired,
  onClose: PropType.func.isRequired,
  onDeviceClick: PropType.func.isRequired,
};

export default DeviceDialogContainer;
