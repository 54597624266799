import { Box, Skeleton, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropType from "prop-types";
import {
  fetchMaxPsdPerBand,
  selectIsFetchingMaxPsdPerBand,
  selectMaxPsdPerBand,
} from "../../../redux/maxPsdPerBand/maxPsdPerBandSlice";
import MaxPsdPerBandChart from "./MaxPsdPerBandChart";
import { selectDeviceFilter } from "../../../redux/deviceFilter/deviceFilterSlice";

export function getUniqueBands(psdRows) {
  const bandList = [];
  psdRows.forEach((row) => {
    if (!bandList.includes(row.band)) {
      bandList.push(row.band);
    }
  });
  bandList.sort((a, b) => a - b);
  return bandList;
}

const MaxEirpPerClassContainer = ({ chartKey }) => {
  // chartKey is passed to the chart component and changing its value rerenders the chart
  const dispatch = useDispatch();
  const deviceListFilter = useSelector(selectDeviceFilter);
  useEffect(() => {
    dispatch(fetchMaxPsdPerBand(deviceListFilter));
  }, [dispatch, deviceListFilter]);
  const maxPsdData = useSelector(selectMaxPsdPerBand);
  const isUpdatePending = useSelector(selectIsFetchingMaxPsdPerBand);

  const bands = useMemo(() => {
    return getUniqueBands(maxPsdData);
  }, [maxPsdData]);

  const hasData =
    maxPsdData &&
    maxPsdData.filter((row) => row.countDevices !== null).length > 0;

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
      }}
    >
      {!isUpdatePending && !hasData && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 1,
            height: 1,
          }}
        >
          <Typography align="center">No Data to Display</Typography>
        </Box>
      )}
      {isUpdatePending && <Skeleton sx={{ width: 1, height: 1 }} />}
      {!isUpdatePending && hasData && (
        <MaxPsdPerBandChart
          key={chartKey}
          maxPsdPerBandRows={maxPsdData}
          bands={bands}
        />
      )}
    </Box>
  );
};

MaxEirpPerClassContainer.propTypes = {
  chartKey: PropType.any,
};

export default MaxEirpPerClassContainer;
