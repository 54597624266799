import axios from "axios";
import Papa from "papaparse";

export const downloadUsersCSV = async () => {
  try {
    const response = await axios.get(
      `${window.config.MANAGEMENT_API_ROOT}/users`,
      {
        headers: {
          Accept: "text/csv",
        },
      }
    );
    const transformedData = response.data.map((userData) => ({
      ...userData,
      administrator: userData.administrator.join("|"),
      reporting: userData.reporting.join("|"),
    }));
    return { data: Papa.unparse(transformedData), filename: "users.csv" };
  } catch (error) {
    throw error;
  }
};

export const downloadOrgsCSV = async () => {
  try {
    const response = await axios.get(
      `${window.config.MANAGEMENT_API_ROOT}/organizations`,
      {
        headers: {
          Accept: "text/csv",
        },
      }
    );
    const formattedData = response.data.map((org) => ({
      name: org.name,
      contact_email: org.contactEmail,
      contact_address: org.contactAddress,
      contact_phone: org.contactPhone,
      enabled: org.enabled,
      organization_id: org.organizationId,
      parent_organization_id: org.parentOrganizationId,
      my_role: org.myRole,
    }));

    const csvData = Papa.unparse(formattedData, {
      header: true,
      columns: [
        "name",
        "contact_email",
        "contact_address",
        "contact_phone",
        "enabled",
        "organization_id",
        "parent_organization_id",
        "my_role",
      ],
    });

    return { data: csvData, filename: "organizations.csv" };
  } catch (error) {
    throw error;
  }
};
