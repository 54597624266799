import axios from "axios";

export async function fetchDeviceInformation(
  deviceFilter,
  pageSize,
  pageNumber
) {
  const response = await axios.post(
    `${window.config.API_ROOT}/deviceInformation`,
    deviceFilter,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        pageNumber,
        pageSize,
      },
    }
  );
  if (response.status !== 200) {
    throw Error(`An error response code was returned: ${response.status}`);
  }
  if (typeof response.data === "string" || response.data instanceof String) {
    throw Error("Invalid JSON");
  }
  return response.data;
}
