import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  InputLabel,
  CircularProgress,
  Typography,
} from "@mui/material";
import PropType from "prop-types";
import CustomButton from "../CustomButton/CustomButton";
import "./EditProfile.css";

const EditProfile = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const { email, name, address, phone_number } = user.attributes;
        setEmail(email || "");
        setFullname(name || "");
        setAddress(address || "");
        setPhone(phone_number || "");
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getUserData();
  }, []);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        name: fullname,
        address: address,
        phone_number: phone,
      });
      onClose();
    } catch (error) {
      console.error("Error updating user attributes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    switch (id) {
      case "fullname":
        setFullname(value);
        break;
      case "address":
        setAddress(value);
        break;
      case "phone":
        setPhone(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="container">
      <div className="header">
        <CloseIcon
          onClick={onClose}
          style={{ cursor: "pointer" }}
          data-testid="close-icon"
        />
        <Typography variant="h2" sx={{ fontSize: "1.5rem", padding: "10px" }}>
          Edit Profile
        </Typography>
      </div>
      <form className="form">
        <InputLabel id="user email">Email</InputLabel>
        <input
          type="email"
          value={email}
          id="email"
          className="input"
          disabled
        />
        <InputLabel id="username" htmlFor="fullname">
          Full Name
        </InputLabel>
        <input
          type="text"
          value={fullname}
          id="fullname"
          className="input"
          onChange={handleChange}
        />
        <InputLabel id="useraddress" htmlFor="address">
          Address
        </InputLabel>
        <input
          type="text"
          value={address}
          id="address"
          className="input"
          onChange={handleChange}
        />
        <InputLabel id="userphone" htmlFor="phone">
          Phone
        </InputLabel>
        <input
          type="tel"
          value={phone}
          id="phone"
          className="input"
          onChange={handleChange}
        />
        <div className="buttonContainer">
          <CustomButton
            text="Cancel"
            onClick={onClose}
            sx={{
              color: "white",
              "&:hover": {
                backgroundColor: "#f86903",
                opacity: "70%",
              },
              backgroundColor: "#f86903",
              opacity: "100%",
              width: "120px",
              maxWidth: "120px",
              borderRadius: 10,
            }}
          />
          <CustomButton
            text="Save"
            onClick={handleSave}
            sx={{
              color: "white",
              "&:hover": {
                backgroundColor: "#2196f3",
                opacity: "70%",
              },
              backgroundColor: "#2196f3",
              opacity: "100%",
              width: "120px",
              maxWidth: "120px",
              borderRadius: 10,
            }}
            isLoading={isLoading}
          />
        </div>
      </form>
    </div>
  );
};

EditProfile.propTypes = {
  onClose: PropType.func,
};

export default EditProfile;
