import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import PropType from "prop-types";

const IndoorFilter = ({ value, onChange }) => {
  let groupValue = undefined;
  if (value != undefined) {
    if (value) {
      groupValue = "indoor";
    } else {
      groupValue = "outdoor";
    }
  }

  const handleButton = (_, newValue) => {
    if (newValue === "indoor") {
      onChange(true);
    } else if (newValue === "outdoor") {
      onChange(false);
    } else {
      onChange(undefined);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Typography sx={{ paddingRight: "30px" }}>Device Location</Typography>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ToggleButtonGroup
          value={groupValue}
          exclusive
          onChange={handleButton}
          aria-label="indoor or outdoor toggle"
        >
          <ToggleButton value="indoor">Indoor</ToggleButton>
          <ToggleButton value="outdoor">Outdoor</ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

IndoorFilter.propTypes = {
  value: PropType.bool,
  onChange: PropType.func.isRequired,
};

export default IndoorFilter;
