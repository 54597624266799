import { createSlice } from "@reduxjs/toolkit";

export const DEFAULT_LOCATION = {
  center: {
    lat: 37.0902,
    lng: -95.7129,
  },
  zoom: 4,
};

export const initialState = {
  location: DEFAULT_LOCATION,
};

export const deviceMapParamsSlice = createSlice({
  name: "deviceMap",
  initialState,
  reducers: {
    updateLocation: {
      reducer: (state, action) => {
        // Using a single reducer to keep for doing two map updates on every map zoom/center change
        if (action.payload.zoom) {
          state.location.zoom = action.payload.zoom;
        }
        if (action.payload.center) {
          state.location.center = action.payload.center;
        }
      },
      prepare: (zoom, center) => {
        return { payload: { zoom, center } };
      },
    },
    resetLocation: {
      reducer: (state, action) => {
        state.location = DEFAULT_LOCATION;
      },
    },
  },
});

export const { updateLocation, resetLocation } = deviceMapParamsSlice.actions;

export const selectDeviceMapParams = (state) => state.deviceMapParams;

export default deviceMapParamsSlice.reducer;
