import PropType from "prop-types";
import { Chip } from "@mui/material";

const RequestExtensionChip = ({
  displayName = "Device Type",
  deviceFilter,
  onDelete,
}) => {
  const { primaryRequestVendorExtension } = deviceFilter;
  // Do not render the chip if the filter is not set
  if (primaryRequestVendorExtension === undefined) {
    return null;
  }

  return (
    <Chip
      onDelete={onDelete}
      label={`${displayName}  [${primaryRequestVendorExtension.length} selected]`}
      data-testid="request-extension-chip"
    />
  );
};

RequestExtensionChip.propTypes = {
  displayName: PropType.string,
  deviceFilter: PropType.shape({
    primaryRequestVendorExtension: PropType.arrayOf(PropType.string),
  }),
  onDelete: PropType.func,
};

export default RequestExtensionChip;
