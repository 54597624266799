import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchIncumbentDetailByBoundingBox } from "./incumbentDetailAPI";

import { error } from "../notification/notificationSlice";

export const initialState = {
  resultList: [],
  isFetchingIncumbentDetail: false,
};

const errorMessage = (err) => {
  if (err?.response?.status != null) {
    return `An error response code was returned: ${err.response.status}`;
  }
  return err?.message;
};

export const fetchIncumbentDetail = createAsyncThunk(
  "incumbentDetail/fetch",
  async (boundingBox, thunkApi) => {
    try {
      return await fetchIncumbentDetailByBoundingBox(boundingBox);
    } catch (err) {
      const message = errorMessage(err);

      thunkApi.dispatch(error(message, "Loading incumbent data"));
      return thunkApi.rejectWithValue(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { incumbentDetail } = getState();
      return !incumbentDetail.isFetchingIncumbentDetail;
    },
  }
);

export const incumbentDetailSlice = createSlice({
  name: "incumbentDetail",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncumbentDetail.pending, (state) => {
        state.isFetchingIncumbentDetail = true;
      })
      .addCase(fetchIncumbentDetail.fulfilled, (state, { payload }) => {
        state.isFetchingIncumbentDetail = false;
        state.resultList = payload ?? [];
      })
      .addCase(fetchIncumbentDetail.rejected, (state) => {
        state.isFetchingIncumbentDetail = false;
      });
  },
});

export const selectIncumbents = (state) => state.incumbentDetail.resultList;

export const selectIsFetchingIncumbentDetail = (state) =>
  state.incumbentDetail.isFetchingIncumbentDetail;

export default incumbentDetailSlice.reducer;
