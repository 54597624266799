import { useEffect, useState } from "react";

import PropType from "prop-types";

import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CloseIcon from "@mui/icons-material/Close";

import DeviceChannelConfig from "./DeviceChannelConfig";
import HistoricalDeviceSummaryEirp from "./HistoricalDeviceSummaryEirp";
import HistoricalDeviceSummaryPsd from "./HistoricalDeviceSummaryPsd";
import DeviceDetail from "./DeviceDetail";
import DeviceIncumbentTable from "./IncumbentsOverlap";
import { Box, IconButton } from "@mui/material";
import RequestsPerDay from "./RequestsPerDay";
import ErrorBoundary from "../../ErrorBoundary";

const DeviceInformationDialog = ({ device, onClose }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabSelection = (_, newTab) => {
    setSelectedTab(newTab);
  };

  useEffect(() => {
    setSelectedTab(0);
  }, [device?.id, device?.serialNumber]);

  return (
    <Dialog
      open={!!device}
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      PaperProps={{
        style: {
          minHeight: "75%",
          maxHeight: "75%",
          width: "100%",
          height: "100%",
          minWidth: "90%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "32px",
          overflow: "hidden",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Tabs value={selectedTab} onChange={handleTabSelection}>
            <Tab label="Device Spectrum Availability" />
            <Tab label="Historical Device EIRP Summary" />
            <Tab label="Historical Device PSD Summary" />
            <Tab label="Device Details" />
            <Tab label="KPI" />
            <Tab label="Incumbents Overlap" />
          </Tabs>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ width: 1, flexGrow: 1, overflow: "auto" }}>
          <ErrorBoundary>
            <DeviceChannelConfig
              deviceId={device?.id}
              show={selectedTab === 0}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <HistoricalDeviceSummaryEirp
              serialNumber={device?.serialNumber}
              show={selectedTab === 1}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <HistoricalDeviceSummaryPsd
              serialNumber={device?.serialNumber}
              show={selectedTab === 2}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <DeviceDetail device={device} show={selectedTab === 3} />
          </ErrorBoundary>
          <ErrorBoundary>
            <RequestsPerDay device={device} show={selectedTab === 4} />
          </ErrorBoundary>
          <ErrorBoundary>
            <DeviceIncumbentTable
              deviceId={device?.id}
              show={selectedTab === 5}
            />
          </ErrorBoundary>
        </Box>
      </Box>
    </Dialog>
  );
};

DeviceInformationDialog.propTypes = {
  device: PropType.shape({
    id: PropType.number,
    serialNumber: PropType.string,
  }),
  onClose: PropType.func.isRequired,
};

DeviceInformationDialog.defaultProps = {
  device: null,
};

export default DeviceInformationDialog;
