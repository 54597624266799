import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropType from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectDeviceFilter } from "../../../redux/deviceFilter/deviceFilterSlice";
import {
  fetchMaxPsdPerFrequency,
  selectBandList,
  selectedBand as selectedBandSelector,
  selectIsFetchingMaxPsdPerFrequency,
  selectMaxPsdPerFrequency,
  setSelectedBand,
} from "../../../redux/maxPsdPerFrequency/maxPsdPerFrequencySlice";
import BandSelector from "./BandSelector";
import MaxPsdPerFrequencyChart from "./MaxPsdPerFrequencyChart";

const MaxPsdPerFrequencyContainer = ({ chartKey, showLegend }) => {
  // chartKey is passed to the chart component and changing its value rerenders the chart
  const dispatch = useDispatch();
  const deviceListFilter = useSelector(selectDeviceFilter);
  useEffect(() => {
    dispatch(fetchMaxPsdPerFrequency(deviceListFilter));
  }, [dispatch, deviceListFilter]);
  const maxPsdData = useSelector(selectMaxPsdPerFrequency);
  const isUpdatePending = useSelector(selectIsFetchingMaxPsdPerFrequency);
  const selectedBand = useSelector(selectedBandSelector);
  const bandList = useSelector(selectBandList);

  const handleBandChange = (selectedBand) => {
    dispatch(setSelectedBand(selectedBand));
  };

  const hasData =
    maxPsdData && maxPsdData.filter((row) => row.average !== null).length > 0;

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
      }}
    >
      {isUpdatePending && <Skeleton sx={{ width: 1, height: 1 }} />}
      {!isUpdatePending && hasData && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <BandSelector
                frequencyBands={bandList}
                selectedBand={selectedBand}
                onChange={handleBandChange}
              />
            </Box>
            <Box sx={{ flexGrow: 1, paddingBottom: "10px" }}>
              <MaxPsdPerFrequencyChart
                key={chartKey}
                maxPsdPerFrequencyRows={maxPsdData}
                band={selectedBand}
                showLegend={showLegend}
              />
            </Box>
          </Box>
        </>
      )}
      {!isUpdatePending && !hasData && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 1,
            height: 1,
          }}
        >
          <Typography align="center">No Data to Display</Typography>
        </Box>
      )}
    </Box>
  );
};

MaxPsdPerFrequencyContainer.propTypes = {
  chartKey: PropType.any,
  showLegend: PropType.bool,
};

export default MaxPsdPerFrequencyContainer;
