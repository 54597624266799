import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Button,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box } from "@mui/system";
import { deepEqual } from "fast-equals";
import { useMemo } from "react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDeviceFilter,
  updateBoundingBox,
} from "../../redux/deviceFilter/deviceFilterSlice";
import {
  selectDeviceList,
  fetchDeviceList,
  selectDeviceRowCount,
  selectCurrentPage,
  selectRowsPerPage,
  updateCurrentPage,
  updateRowsPerPage,
} from "../../redux/deviceInformation/deviceInformationSlice";
import DeviceInformationDialog from "./DeviceInformationDialog";
import DeviceInformationTable from "./DeviceInformationTable";
import DeviceClusterMap from "./DeviceMap";
import Incumbents from "./Incumbents";
import FilterDrawer, { FilterChipList } from "./FilterDrawer";
import MaxEirpPerChannelContainer from "./MaxEirpPerChannel/MaxEirpPerChannelContainer";
import MaxEirpPerClassContainer from "./MaxEirpPerClass/MaxEirpPerClassContainer";
import MaxPsdPerBandContainer from "./MaxPsdPerBand/MaxPsdPerBandContainer";
import MaxPsdPerFrequencyContainer from "./MaxPsdPerFrequency/MaxPsdPerFrequencyContainer";
import { fetchLookupValues } from "../../redux/lookup/lookupSlice";
import {
  fetchDeviceClusterList,
  selectDeviceClusterList,
} from "../../redux/deviceCluster/deviceClusterSlice";
import {
  fetchIncumbentClusterList,
  selectIncumbentClusterList,
} from "../../redux/incumbentCluster/incumbentClusterSlice";
import {
  clearIncumbentContours,
  clearIncumbentMap,
  fetchIncumbentContourByBoundingBox,
  selectIncumbentContoursList,
} from "../../redux/incumbentContour/incumbentContourSlice";
import IncumbentDialog from "./IncumbentDialog";
import DeviceDialog from "./DeviceDialog";
import { selectUserConfig } from "../../redux/userConfig/ConfigSlice";
import { selectIncumbentFilter } from "../../redux/incumbentFilter/incumbentFilterSlice";
import IncumbentLegend from "./IncumbentLegend";
import ErrorBoundary from "../ErrorBoundary";
import MapLegend from "./MapLegend";
import {
  resetLocation,
  selectDeviceMapParams,
  updateLocation,
} from "../../redux/deviceMapParams/deviceMapParamsSlice";

const getMapBounds = (map) => {
  const boundary = map.getBounds();
  return {
    northEast: {
      lat: boundary.getNorthEast().lat(),
      lng: boundary.getNorthEast().lng(),
    },
    southWest: {
      lat: boundary.getSouthWest().lat(),
      lng: boundary.getSouthWest().lng(),
    },
  };
};

const DeviceInformation = () => {
  const dispatch = useDispatch();

  const deviceListFilter = useSelector(selectDeviceFilter);
  const haveBoundary = useMemo(
    () => deviceListFilter?.boundingBox != undefined,
    [deviceListFilter]
  );

  const [showMapLegend, setShowMapLegend] = useState(false);

  useEffect(() => {
    dispatch(fetchLookupValues());
  }, [dispatch]);

  useEffect(() => {
    if (haveBoundary) {
      dispatch(fetchDeviceClusterList(deviceListFilter));
      dispatch(fetchDeviceList(deviceListFilter));
      // Also reset the pagination back to the first page
      dispatch(updateCurrentPage(0));
    }
  }, [dispatch, haveBoundary, deviceListFilter]);

  const incumbentFilter = useSelector(selectIncumbentFilter);
  useEffect(() => {
    if (haveBoundary) {
      dispatch(
        fetchIncumbentClusterList({
          ...incumbentFilter,
          boundingBox: deviceListFilter.boundingBox,
        })
      );
    }
  }, [dispatch, haveBoundary, deviceListFilter.boundingBox, incumbentFilter]);

  const userConfig = useSelector(selectUserConfig);

  const deviceList = useSelector(selectDeviceList);
  const totalDevices = useSelector(selectDeviceRowCount);
  const currentPage = useSelector(selectCurrentPage);
  const rowsPerPage = useSelector(selectRowsPerPage);

  const deviceLocationList = useSelector(selectDeviceClusterList);
  const incumbentClusterList = useSelector(selectIncumbentClusterList);
  const incumbentContourList = useSelector(selectIncumbentContoursList);

  const mapBoundary = useRef();
  const { zoom: mapZoom, center: mapCenter } = useSelector(
    selectDeviceMapParams
  ).location;

  const [selected, setSelected] = useState(null);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const [selectedIncumbentIds, setSelectedIncumbentIds] = useState([]);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);

  const handleBoundaryChange = (map) => {
    const boundary = getMapBounds(map);
    if (!deepEqual(mapBoundary.current, boundary)) {
      mapBoundary.current = boundary;
      // Convert the NE and SW to NW and SE
      dispatch(
        updateBoundingBox(
          boundary.northEast.lat, // nwLat
          boundary.southWest.lng, // nwLong
          boundary.southWest.lat, // seLat
          boundary.northEast.lng // seLong
        )
      );
      // Also reset the pagination back to the first page
      dispatch(updateCurrentPage(0));

      // Only update the map zoom and center if they have changed,
      // Updating every boundary will force an extra map rerender even if unchanged
      if (
        map.getZoom() !== mapZoom ||
        map.getCenter().lat() !== mapCenter.lat ||
        map.getCenter().lng() !== mapCenter.lng
      ) {
        dispatch(
          updateLocation(map.getZoom(), {
            lat: map.getCenter().lat(),
            lng: map.getCenter().lng(),
          })
        );
      }
    }
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabSelection = (_, newTab) => {
    setSelectedTab(newTab);
  };

  const [displayWidths, setDisplayWidths] = useState([1 / 2, 1 / 2]);
  // When the key changes it will force react to rerender the charts
  const [chartKey, setChartKey] = useState(0);
  const mapHidden = () => displayWidths[0] == 0;
  const chartHidden = () => displayWidths[1] == 0;
  const handleLeftClick = () => {
    if (chartHidden()) {
      setDisplayWidths([1 / 2, 1 / 2]);
    } else {
      setDisplayWidths([0, 1]);
    }
    setChartKey(chartKey + 1);
  };
  const handleRightClick = () => {
    if (mapHidden()) {
      setDisplayWidths([1 / 2, 1 / 2]);
    } else {
      setDisplayWidths([1, 0]);
    }
    setChartKey(chartKey + 1);
  };

  const handleContoursToggle = () => {
    // Need to dispatch a contours update with the current device filter if the new state is to show the contours
    if (incumbentContourList && incumbentContourList.length > 0) {
      dispatch(clearIncumbentContours());
    } else {
      dispatch(fetchIncumbentContourByBoundingBox());
    }
  };

  const showContours = incumbentContourList && incumbentContourList.length > 0;

  return (
    <>
      <Box
        data-testid="container-device-information"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 1,
          height: 1,
          maxWidth: "calc(100vw - 60px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "40px",
            maxHeight: "40px",
            overflow: "auto",
          }}
        >
          <FilterChipList
            onMapHome={() => {
              dispatch(resetLocation());
            }}
          />
          <IconButton
            onClick={() => setFilterDrawerOpen(true)}
            data-testid="filter-btn"
          >
            <FilterListIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box
          sx={{
            height: "calc(75% - 40px)",
            width: 1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              height: 1,
              width: displayWidths[0],
              display: mapHidden() ? "none" : "flex",
              flexDirection: "row",
            }}
            data-testid="map-panel"
          >
            <Box sx={{ flexGrow: 1, position: "relative" }}>
              <ErrorBoundary>
                {showContours && (
                  <Box
                    sx={{
                      height: "2rem",
                      width: 1,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 10,
                    }}
                  >
                    <IncumbentLegend />
                  </Box>
                )}
                <Box sx={{ width: 1, height: 1 }}>
                  <DeviceClusterMap
                    deviceList={deviceLocationList}
                    incumbentList={incumbentClusterList}
                    zoom={mapZoom}
                    center={mapCenter}
                    onBoundaryChange={handleBoundaryChange}
                    showContours={showContours}
                    onToggleContoursClick={handleContoursToggle}
                    incumbentContourList={incumbentContourList}
                    onIncumbentClick={(ids) => setSelectedIncumbentIds(ids)}
                    onDeviceClick={(ids) => setSelectedDeviceIds(ids)}
                    showMapLegend={() => setShowMapLegend(true)}
                  />
                </Box>
              </ErrorBoundary>
            </Box>
            <Button
              sx={{
                height: 1,
                padding: 0,
                margin: 0,
                width: "25px",
                minWidth: "25px",
                maxWidth: "25px",
                borderRadius: 0,
              }}
              variant="text"
              onClick={handleLeftClick}
              data-testid="map-panel-btn"
            >
              <ChevronLeft sx={{ minWidth: 0 }} />
            </Button>
          </Box>
          <Divider orientation="vertical" />
          <Box
            sx={{
              height: 1,
              width: displayWidths[1],
              display: chartHidden() ? "none" : "flex",
              flexDirection: "row",
            }}
            data-testid="chart-panel"
          >
            <Button
              sx={{
                height: 1,
                padding: 0,
                margin: 0,
                width: "25px",
                minWidth: "25px",
                maxWidth: "25px",
                borderRadius: 0,
              }}
              variant="text"
              onClick={handleRightClick}
              data-testid="chart-panel-btn"
            >
              <ChevronRight sx={{ minWidth: 0 }} />
            </Button>
            <Divider orientation="vertical" />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: 1,
                flexGrow: 1,
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={handleTabSelection}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="maxEIRP" sx={{ textTransform: "none" }} wrapped />
                <Tab label="maxPSD" sx={{ textTransform: "none" }} wrapped />
                <Tab
                  label="maxEIRP per Channel"
                  sx={{ textTransform: "none" }}
                  wrapped
                />
                <Tab
                  label="maxPSD per Frequency"
                  sx={{ textTransform: "none" }}
                  wrapped
                />
                <Tab
                  label="Incumbents"
                  sx={{ textTransform: "none" }}
                  wrapped
                />
              </Tabs>
              <Divider />
              <Box sx={{ flexGrow: 1, overflow: "auto" }}>
                {selectedTab === 0 && (
                  <ErrorBoundary>
                    <MaxEirpPerClassContainer chartKey={chartKey} />
                  </ErrorBoundary>
                )}
                {selectedTab === 1 && (
                  <ErrorBoundary>
                    <MaxPsdPerBandContainer chartKey={chartKey} />
                  </ErrorBoundary>
                )}
                {selectedTab === 2 && (
                  <ErrorBoundary>
                    <MaxEirpPerChannelContainer
                      chartKey={chartKey}
                      showLegend={mapHidden()}
                    />
                  </ErrorBoundary>
                )}
                {selectedTab === 3 && (
                  <ErrorBoundary>
                    <MaxPsdPerFrequencyContainer
                      chartKey={chartKey}
                      showLegend={mapHidden()}
                    />
                  </ErrorBoundary>
                )}
                {selectedTab === 4 && <Incumbents />}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: 1 }}>
          <Divider />
        </Box>
        {deviceList && deviceList.length > 0 && (
          <Box
            sx={{
              width: 1,
              height: 1 / 4,
            }}
          >
            <ErrorBoundary>
              <DeviceInformationTable
                userConfig={userConfig}
                deviceList={deviceList}
                totalDeviceCount={totalDevices}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                onPageChange={(num) => dispatch(updateCurrentPage(num))}
                onSelectDevice={setSelected}
                onRowsPerPageChange={(num) => dispatch(updateRowsPerPage(num))}
                onDeviceZoom={(device) => {
                  dispatch(
                    updateLocation(device.zoom, {
                      lat: device.location.latitude,
                      lng: device.location.longitude,
                    })
                  );
                }}
              />
            </ErrorBoundary>
          </Box>
        )}
        {(!deviceList || !deviceList.length > 0) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 1,
              height: 1 / 4,
            }}
          >
            <Typography>No devices to display</Typography>
          </Box>
        )}
      </Box>
      <DeviceInformationDialog
        device={selected}
        onClose={() => {
          setSelected(null);
          dispatch(clearIncumbentMap());
        }}
      />
      <IncumbentDialog
        incumbentIdList={selectedIncumbentIds}
        onClose={() => setSelectedIncumbentIds([])}
      />
      <MapLegend
        isOpen={showMapLegend}
        onClose={() => setShowMapLegend(false)}
      />
      <DeviceDialog
        deviceIdList={selectedDeviceIds}
        onClose={() => setSelectedDeviceIds([])}
        onDeviceClick={(deviceDetail) => setSelected(deviceDetail)}
      />
      <FilterDrawer
        show={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
      />
    </>
  );
};

export default DeviceInformation;
