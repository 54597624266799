import PropType from "prop-types";

export const frequencyBands = {
  UNII5: {
    id: 5,
    lower: 5945,
    upper: 6425,
    isInBand: function (frequency) {
      return frequency >= this.lower && frequency <= this.upper;
    },
    displayName: "UNII-5",
  },
  UNII7: {
    id: 7,
    lower: 6525,
    upper: 6875,
    isInBand: function (frequency) {
      return frequency >= this.lower && frequency <= this.upper;
    },
    displayName: "UNII-7",
  },
};

export const frequencyBandPropType = PropType.shape({
  id: PropType.number,
  lower: PropType.number,
  upper: PropType.number,
  isInBand: PropType.func,
  displayName: PropType.string,
});
