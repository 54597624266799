import { useMemo } from "react";
import PropType from "prop-types";
import Chart from "react-google-charts";
import ChartError, { ChartLoading } from "../../ChartError";

const hAxisTitle = "maxEirp (dBm)";
const vAxisTitle = "Number of Occurrences";

export function transformData(eirpRows) {
  const operatingClasses = [];
  eirpRows.forEach((row) => {
    if (!operatingClasses.includes(row.globalOperatingClass)) {
      operatingClasses.push(row.globalOperatingClass);
    }
  });
  operatingClasses.sort();

  // Need to convert the operating classes to strings
  const descriptions = [hAxisTitle];
  operatingClasses.forEach((opClass) => {
    descriptions.push(`${opClass}`);
  });

  // change the data into a map of maps.
  const mappedData = {};
  const eirpValues = [];
  eirpRows.forEach((row) => {
    if (!eirpValues.includes(row.maxEIRP)) {
      eirpValues.push(row.maxEIRP);
    }

    let eirpValue = mappedData[row.maxEIRP];
    if (!eirpValue) {
      eirpValue = mappedData[row.maxEIRP] = { maxEIRP: row.maxEIRP };
    }
    eirpValue[row.globalOperatingClass] = row.countDevices;
  });

  // Sort the eirp values into ascending order
  eirpValues.sort((a, b) => a - b);

  const chartData = [];
  chartData.push(descriptions);
  eirpValues.forEach((eirp) => {
    const row = [`${eirp}`];
    operatingClasses.forEach((opClass) => {
      row.push(mappedData[eirp][opClass]);
    });
    chartData.push(row);
  });
  return chartData;
}

const MaxEirpPerClassChart = ({ maxEirpPerClassRows }) => {
  // Need to format the data for the chart,
  // need a single entry for each maxEIRP value with individual values for each globalOperatingClass present
  const data = useMemo(() => {
    return transformData(maxEirpPerClassRows);
  }, [maxEirpPerClassRows]);

  const options = {
    hAxis: {
      title: hAxisTitle,
    },
    vAxis: {
      title: vAxisTitle,
    },
    focusTarget: "category",
    theme: "material",
  };

  return (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="100%"
      data={data}
      options={options}
      rootProps={{ "data-testid": "max-eirp-chart" }}
      errorElement={<ChartError />}
      loader={<ChartLoading />}
    />
  );
};

MaxEirpPerClassChart.propTypes = {
  maxEirpPerClassRows: PropType.arrayOf(PropType.shape()).isRequired,
};

export default MaxEirpPerClassChart;
