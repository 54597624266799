import {
  DEFAULT_CHART_OPTIONS,
  dateToFormattedDate,
  toHistoricalDeviceSummaryChartTicks,
} from "../HistoricalDeviceSummary/util";

export const MAX_PSD_DBM_HZ = 99;
export const MIN_PSD_DBM_HZ = -99;

const sortAndGroupByDate = (data) =>
  Object.entries(
    data.reduce((acc, eirp) => {
      if (!acc[eirp.date]) {
        acc[eirp.date] = {};
      }
      acc[eirp.date][eirp.frequencyBand] = eirp.percentageAboveTarget;
      return acc;
    }, {})
  ).sort(([d1, oc1], [d2, oc2]) => d1.localeCompare(d2));

const compareBands = (b1, b2) => b1.localeCompare(b2);

export const toDataTable = (data) => {
  const dataSortedAndGroupedByDate = sortAndGroupByDate(data);

  if (dataSortedAndGroupedByDate.length > 0) {
    const [[, firstBands]] = dataSortedAndGroupedByDate;

    return [["Date"].concat(Object.keys(firstBands).sort(compareBands))].concat(
      dataSortedAndGroupedByDate.map(([datetime, bands]) =>
        [dateToFormattedDate(datetime)].concat(
          Object.entries(bands)
            .sort(([b1], [b2]) => compareBands(b1, b2))
            .map(([, percentageAboveTarget]) => percentageAboveTarget * 100)
        )
      )
    );
  }
  return [["Date"]];
};

export const toTicks = (data) =>
  toHistoricalDeviceSummaryChartTicks(sortAndGroupByDate(data));

export const toChartOptions = (data) => ({
  ...DEFAULT_CHART_OPTIONS,
  hAxis: {
    title: "Date",
    ticks: toTicks(data),
  },
  vAxis: {
    title: "% Above Target",
    minValue: 0,
    maxValue: 100,
  },
  height: "100%",
  width: "100%",
});
