import axios from "axios";

export async function fetchMaxEirpPerClass(deviceFilter) {
  const response = await axios.post(
    `${window.config.API_ROOT}/maxEIRPPerGlobalOperatingClass`,
    deviceFilter,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  if (response.status !== 200) {
    throw Error(`An error response code was returned: ${response.status}`);
  }
  // Axios will return the data as a string for invalid json
  // We are expecting an Array so need to check it is an Array
  if (!Array.isArray(response.data)) {
    throw Error("Response is not a valid JSON array");
  }
  return response.data;
}
