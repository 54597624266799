import axios from "axios";

export async function fetchSystemPerformance(startDate, endDate, intervalType) {
  const response = await axios.get(
    `${window.config.API_ROOT}/systemPerformance`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        intervalType,
        startDate,
        endDate,
      },
    }
  );

  return response.data;
}
