import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropType from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDeviceFilter,
  updateIndoor,
  updateMaxHeight,
  updateGlobalOperatingClass,
  updateFrequencyBand,
  updatePrimaryGlobalExtension,
  updatePrimaryRequestExtension,
  updateSerialNumber,
  updateFccCertificationId,
} from "../../../redux/deviceFilter/deviceFilterSlice";
import IndoorFilter from "./IndoorFilter";
import HeightFilter from "./HeightFilter";
import GlobalOperatingClassFilter from "./GlobalOperationClassFilter";
import FrequencyBandFilter from "./FrequencyBandFilter";
import {
  clearFccCertificationId,
  clearSerialNumber,
  fetchDeviceSerialNumberValues,
  fetchFccCertificationIdValues,
  selectDeviceSerialNumbers,
  selectFccCertificationIds,
  selectFrequencyBandValues,
  selectGlobalOperatingClassValues,
  selectMaxHeightValues,
  selectPrimaryGlobalVendorExtensionValues,
  selectPrimaryRequestVendorExtensionValues,
} from "../../../redux/lookup/lookupSlice";
import GlobalExtensionFilter from "./GlobalExtensionFilter";
import RequestExtensionFilter from "./RequestExtensionFilter";
import { deepEqual } from "fast-equals";
import { selectUserConfig } from "../../../redux/userConfig/ConfigSlice";
import IncumbentFrequencyFilter from "./IncumbentFrequencyFilter";
import {
  selectIncumbentFilter,
  updateIncumbentFilter,
} from "../../../redux/incumbentFilter/incumbentFilterSlice";
import DeviceSerialNumberFilter from "./DeviceSerialNumberFilter/DeviceSerialNumberFilter";
import { useDebouncedCallback } from "use-debounce";
import FccIdFilter from "./FccIdFilter/FccIdFilter";

const FilterDrawer = ({ show, onClose }) => {
  const dispatch = useDispatch();

  const originalDeviceFilter = useSelector(selectDeviceFilter);
  const originalIncumbentFilter = useSelector(selectIncumbentFilter);
  const {
    primaryGlobalVendorExtensionName,
    primaryRequestVendorExtensionName,
  } = useSelector(selectUserConfig);

  const serialNumberOptions = useSelector(selectDeviceSerialNumbers);

  const fccCertificationIdOptions = useSelector(selectFccCertificationIds);

  const [deviceFilter, setDeviceFilter] = useState({});
  const [incumbentFilter, setIncumbentFilter] = useState({});

  useEffect(() => {
    setDeviceFilter(originalDeviceFilter);
  }, [originalDeviceFilter]);
  useEffect(() => {
    setIncumbentFilter(originalIncumbentFilter);
  }, [originalIncumbentFilter]);

  const {
    maxDeviceHeight,
    indoor,
    globalOperatingClass,
    frequencyBand,
    primaryGlobalVendorExtension,
    primaryRequestVendorExtension,
    serialNumber,
    fccCertificationId,
  } = deviceFilter;

  const { frequencyRange } = incumbentFilter;

  const heightLookupValues = useSelector(selectMaxHeightValues);
  const globalOperatingClassLookupValues = useSelector(
    selectGlobalOperatingClassValues
  );
  const frequencyBandValues = useSelector(selectFrequencyBandValues);
  const globalExtensionValues = useSelector(
    selectPrimaryGlobalVendorExtensionValues
  );
  const requestExtensionValues = useSelector(
    selectPrimaryRequestVendorExtensionValues
  );

  const serialNumberUpdate = useDebouncedCallback((startsWith) => {
    if (startsWith) {
      dispatch(fetchDeviceSerialNumberValues(startsWith));
    }
  }, 500);

  const fccCertificationIdUpdate = useDebouncedCallback((startsWith) => {
    if (startsWith) {
      dispatch(fetchFccCertificationIdValues(startsWith));
    }
  }, 500);

  function removeUndefinedProperties(obj) {
    for (const key in obj) {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    }
  }

  const handleClose = () => {
    const newDeviceFilter = {
      ...originalDeviceFilter,
      indoor,
      maxDeviceHeight,
      globalOperatingClass,
      frequencyBand,
      primaryGlobalVendorExtension,
      primaryRequestVendorExtension,
      serialNumber,
      fccCertificationId,
    };
    removeUndefinedProperties(newDeviceFilter);
    if (!deepEqual(originalDeviceFilter, newDeviceFilter)) {
      dispatch(updateIndoor(indoor));
      dispatch(updateMaxHeight(maxDeviceHeight));
      dispatch(updateGlobalOperatingClass(globalOperatingClass));
      dispatch(updateFrequencyBand(frequencyBand));
      dispatch(updatePrimaryGlobalExtension(primaryGlobalVendorExtension));
      dispatch(updatePrimaryRequestExtension(primaryRequestVendorExtension));
      dispatch(updateSerialNumber(serialNumber));
      dispatch(updateFccCertificationId(fccCertificationId));
    }

    const newIncumbentFilter = {
      ...originalIncumbentFilter,
      frequencyRange,
    };
    removeUndefinedProperties(newIncumbentFilter);
    if (!deepEqual(originalIncumbentFilter, newIncumbentFilter)) {
      dispatch(updateIncumbentFilter(frequencyRange));
    }
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      open={show}
      onClose={handleClose}
      data-testid="filter-drawer"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          border: "30px",
        }}
      >
        <IconButton onClick={handleClose} data-testid="filter-close">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ margin: "40px", display: "flex", flexDirection: "row" }}>
        <Box sx={{ paddingRight: "32px", minWidth: "500px" }}>
          <Stack spacing={4}>
            <Typography align="center" variant="h4">
              Incumbent Filter
            </Typography>
            <IncumbentFrequencyFilter
              value={frequencyRange}
              onChange={(newValue) =>
                setIncumbentFilter((prev) => ({
                  ...prev,
                  frequencyRange: newValue,
                }))
              }
            />
          </Stack>
        </Box>
        <Divider orientation="vertical" />
        <Box sx={{ paddingLeft: "32px", minWidth: "500px" }}>
          <Stack spacing={4}>
            <Typography align="center" variant="h4">
              Device Filters
            </Typography>
            <IndoorFilter
              value={indoor}
              onChange={(newValue) =>
                setDeviceFilter((prev) => ({ ...prev, indoor: newValue }))
              }
            />
            <HeightFilter
              value={maxDeviceHeight}
              maxHeight={heightLookupValues?.maxAvailableHeight}
              minHeight={heightLookupValues?.minAvailableHeight}
              onChange={(newValue) =>
                setDeviceFilter((prev) => ({
                  ...prev,
                  maxDeviceHeight: newValue,
                }))
              }
            />
            <GlobalOperatingClassFilter
              options={globalOperatingClassLookupValues}
              value={globalOperatingClass}
              onChange={(newValue) =>
                setDeviceFilter((prev) => ({
                  ...prev,
                  globalOperatingClass: newValue,
                }))
              }
            />
            <FrequencyBandFilter
              options={frequencyBandValues}
              value={frequencyBand}
              onChange={(newValue) =>
                setDeviceFilter((prev) => ({
                  ...prev,
                  frequencyBand: newValue,
                }))
              }
            />
            <DeviceSerialNumberFilter
              value={serialNumber}
              onChange={(newValue) => {
                setDeviceFilter((prev) => ({
                  ...prev,
                  serialNumber: newValue,
                }));
              }}
              onInputChange={(newValue) => {
                serialNumberUpdate(newValue);
              }}
              onClose={() => {
                dispatch(clearSerialNumber());
              }}
              options={serialNumberOptions}
            />
            <FccIdFilter
              value={fccCertificationId}
              onChange={(newValue) => {
                setDeviceFilter((prev) => ({
                  ...prev,
                  fccCertificationId: newValue,
                }));
              }}
              onInputChange={(newValue) => {
                fccCertificationIdUpdate(newValue);
              }}
              onClose={() => {
                dispatch(clearFccCertificationId());
              }}
              options={fccCertificationIdOptions}
            />
            {primaryGlobalVendorExtensionName && (
              <GlobalExtensionFilter
                labelName={primaryGlobalVendorExtensionName}
                options={globalExtensionValues}
                value={primaryGlobalVendorExtension}
                onChange={(newValue) =>
                  setDeviceFilter((prev) => ({
                    ...prev,
                    primaryGlobalVendorExtension: newValue,
                  }))
                }
              />
            )}
            {primaryRequestVendorExtensionName && (
              <RequestExtensionFilter
                labelName={primaryRequestVendorExtensionName}
                options={requestExtensionValues}
                value={primaryRequestVendorExtension}
                onChange={(newValue) =>
                  setDeviceFilter((prev) => ({
                    ...prev,
                    primaryRequestVendorExtension: newValue,
                  }))
                }
              />
            )}
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
};

FilterDrawer.propTypes = {
  show: PropType.bool.isRequired,
  onClose: PropType.func.isRequired,
};

export default FilterDrawer;
