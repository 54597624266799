import PropType from "prop-types";
import { Box, TextField } from "@mui/material";
import moment from "moment";
import { momentFormat } from "./intervalTypes";

const DateRangeFilter = ({
  interval = intervalTypes.DAILY,
  disabled = false,
  onChange,
  start,
  end,
}) => {
  const fireOnChange = (startDate, endDate) => {
    const newValue = {
      startDate,
      endDate,
    };
    onChange(newValue);
  };

  const handleStartDateChange = (event) => {
    const dateString = event.target.value;
    if (!dateString || !moment(dateString).isValid()) {
      setStartDate(interval.defaultStartDate());
    } else {
      const startMoment = moment.utc(dateString).startOf("day");
      const endMoment = moment.utc(end).endOf("day");

      if (startMoment.isAfter(endMoment)) {
        fireOnChange(endMoment.format(momentFormat), end);
      } else {
        fireOnChange(startMoment.format(momentFormat), end);
      }
    }
  };

  const handleEndDateChange = (event) => {
    const dateString = event.target.value;
    if (!dateString || !moment(dateString).isValid()) {
      setEndDate(interval.defaultEndDate());
    } else {
      const startMoment = moment.utc(start).startOf("day");
      const endMoment = moment.utc(dateString).endOf("day");
      const maxMoment = moment.utc(interval.defaultEndDate()).endOf("day");

      if (endMoment.isAfter(maxMoment)) {
        fireOnChange(start, interval.defaultEndDate());
      } else if (endMoment.isBefore(startMoment)) {
        fireOnChange(start, startMoment.format(momentFormat));
      } else {
        fireOnChange(start, endMoment.format(momentFormat));
      }
    }
  };

  // Should this be dependant on the interval?
  const maxStartDate = moment.utc(end).subtract(1, "day").format(momentFormat);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <TextField
        type="date"
        label="Start Date"
        disabled={disabled}
        onChange={handleStartDateChange}
        value={start}
        inputProps={{
          max: maxStartDate,
        }}
        sx={{ minWidth: "15ch", flexGrow: 1 }}
      />
      <span style={{ paddingLeft: "1ch", paddingRight: "1ch" }}>to</span>
      <TextField
        type="date"
        label="End Date"
        disabled={disabled}
        onChange={handleEndDateChange}
        value={end}
        inputProps={{
          min: start,
          max: interval.defaultEndDate(),
        }}
        sx={{ minWidth: "15ch", flexGrow: 1 }}
      />
    </Box>
  );
};

DateRangeFilter.propTypes = {
  interval: PropType.shape({
    id: PropType.string,
    description: PropType.string,
    defaultStartDate: PropType.func,
    defaultEndDate: PropType.func,
  }),
  disabled: PropType.bool,
  onChange: PropType.func,
  start: PropType.string,
  end: PropType.string,
};

export default DateRangeFilter;
