import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { getOrganizations } from "../../redux/userManagement/getOrganizationsSlice";
import PropType from "prop-types";

const UserPermissionView = ({ onClose }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.getOrganizations.data);

  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <CloseIcon
          onClick={onClose}
          style={{ cursor: "pointer" }}
          data-testid="close-icon"
        />
        <Typography variant="h2" sx={{ fontSize: "1.5rem", padding: "10px" }}>
          Permissions
        </Typography>
      </div>
      <TableContainer
        component={Paper}
        elevation={3}
        sx={{
          maxHeight: "400px",
          overflowY: "auto",
          overflowX: "hidden",
          width: "100%",
        }}
      >
        <Table
          sx={{
            width: "100%",
            maxHeight: 1,
            height: "50%",
            overflow: "auto",
          }}
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>Organization ID</TableCell>
              <TableCell>Organization Name</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((org) => (
              <TableRow key={org.organizationId}>
                <TableCell>{org.organizationId}</TableCell>
                <TableCell>{org.name}</TableCell>
                <TableCell>{org.myRole}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

UserPermissionView.propTypes = {
  onClose: PropType.func,
};

export default UserPermissionView;
