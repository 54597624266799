import { Box, Skeleton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropType from "prop-types";
import {
  fetchMaxEirpPerClass,
  selectIsFetchingMaxEirpPerClass,
  selectMaxEirpPerClass,
} from "../../../redux/maxEirpPerClass/maxEirpPerClassSlice";
import MaxEirpPerClassChart from "./MaxEirpPerClassChart";
import { selectDeviceFilter } from "../../../redux/deviceFilter/deviceFilterSlice";

const MaxEirpPerClassContainer = ({ chartKey }) => {
  // chartKey is passed to the chart component and changing its value rerenders the chart
  const dispatch = useDispatch();
  const deviceListFilter = useSelector(selectDeviceFilter);
  useEffect(() => {
    if (Object.keys(deviceListFilter).length !== 0) {
      dispatch(fetchMaxEirpPerClass(deviceListFilter));
    }
  }, [dispatch, deviceListFilter]);
  const maxEirpData = useSelector(selectMaxEirpPerClass);
  const isUpdatePending = useSelector(selectIsFetchingMaxEirpPerClass);

  const hasData =
    maxEirpData &&
    maxEirpData.filter((row) => row.countDevices !== null).length > 0;

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
      }}
    >
      {!isUpdatePending && !hasData && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 1,
            height: 1,
          }}
        >
          <Typography align="center">No Data to Display</Typography>
        </Box>
      )}
      {isUpdatePending && <Skeleton sx={{ width: 1, height: 1 }} />}
      {!isUpdatePending && hasData && (
        <MaxEirpPerClassChart
          key={chartKey}
          maxEirpPerClassRows={maxEirpData}
        />
      )}
    </Box>
  );
};

MaxEirpPerClassContainer.propTypes = {
  chartKey: PropType.any,
};

export default MaxEirpPerClassContainer;
