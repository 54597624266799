import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUsers } from "./getUsersAPI";

export const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getUsers = createAsyncThunk("users/fetch", async (_, thunkApi) => {
  try {
    return await fetchUsers();
  } catch (err) {
    return thunkApi.rejectWithValue(err.toString());
  }
});

const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(getUsers.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export default usersSlice.reducer;
