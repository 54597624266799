import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { HIST_DATE_FORMAT } from "../../util/constants";

import { fetchHistoricalDeviceSummaryPSDById } from "./historicalDeviceSummaryPsdAPI";

export const intervalType = {
  HOURLY: "HOURLY",
  DAILY: "DAILY",
  MONTHLY: "MONTHLY",
  MTD: "MTD",
  YTD: "YTD",
};

export const initialState = {
  loading: false,
};

const errorMessage = (err) => {
  if (err?.response?.status === 404) {
    return "The historical PSD data could not be found";
  }
  return err?.message;
};

export const fetchHistoricalDeviceSummaryPSD = createAsyncThunk(
  "fetchHistoricalDeviceSummaryPSD/fetch",
  async ({ serialNumber, intervalType, startDate, maxPSD }, thunkApi) => {
    try {
      const localDate = moment(startDate).utc(true).format(HIST_DATE_FORMAT);
      const summary = await fetchHistoricalDeviceSummaryPSDById(
        serialNumber,
        intervalType,
        localDate,
        maxPSD
      );
      return summary;
    } catch (err) {
      // Not dispatching notification - errors will be shown in dialog
      if (err.response?.status == 404) {
        return [];
      }
      return thunkApi.rejectWithValue(errorMessage(err));
    }
  }
);

export const historicalDeviceSummaryPSDSlice = createSlice({
  name: "historicalDeviceSummaryPSD",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchHistoricalDeviceSummaryPSD.pending, (state) => {
        state.loading = true;
        state.current = null;
        state.error = null;
        state.empty = false;
      })
      .addCase(
        fetchHistoricalDeviceSummaryPSD.fulfilled,
        (state, { payload }) => {
          if (!payload?.length) {
            state.empty = true;
          } else {
            state.current = payload;
          }
          state.loading = false;
        }
      )
      .addCase(
        fetchHistoricalDeviceSummaryPSD.rejected,
        (state, { payload, error }) => {
          state.loading = false;
          state.error = payload || error.message;
        }
      );
  },
});

export const selectHistoricalDeviceSummaryPSD = (state) =>
  state.historicalDeviceSummaryPSD;

export default historicalDeviceSummaryPSDSlice.reducer;
