import { useMemo } from "react";
import PropType from "prop-types";
import Chart from "react-google-charts";
import ChartError, { ChartLoading } from "../../ChartError";
import { Typography } from "@mui/material";

const hAxisTitle = "maxPSD (dBm/MHz)";
const vAxisTitle = "Number of Occurrences";

export function transformData(psdRows) {
  const bands = [];
  psdRows.forEach((row) => {
    if (!bands.includes(row.frequencyBand)) {
      bands.push(row.frequencyBand);
    }
  });
  bands.sort((a, b) => a - b);

  const descriptions = [hAxisTitle];
  bands.forEach((band) => {
    descriptions.push(`UNII-${band}`);
  });

  const mappedData = {};
  const psdValues = [];
  psdRows.forEach((row) => {
    if (!psdValues.includes(row.maxPSD)) {
      psdValues.push(row.maxPSD);
    }

    let psdValue = mappedData[row.maxPSD];
    if (!psdValue) {
      psdValue = mappedData[row.maxPSD] = { maxPSD: row.maxPSD };
    }
    psdValue[row.frequencyBand] = row.countDevices;
  });

  psdValues.sort((a, b) => a - b);

  const chartData = [];
  chartData.push(descriptions);
  psdValues.forEach((psd) => {
    const row = [`${psd}`];
    bands.forEach((band) => {
      row.push(mappedData[psd][band]);
    });
    chartData.push(row);
  });
  return chartData;
}

const MaxPsdPerBandChart = ({ maxPsdPerBandRows }) => {
  const data = useMemo(() => {
    return transformData(maxPsdPerBandRows);
  }, [maxPsdPerBandRows]);

  const options = {
    hAxis: {
      title: hAxisTitle,
    },
    vAxis: {
      title: vAxisTitle,
    },
    focusTarget: "category",
    theme: "material",
  };

  return (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="100%"
      data={data}
      options={options}
      rootProps={{ "data-testid": "max-psd-chart" }}
      errorElement={<ChartError />}
      loader={<ChartLoading />}
    />
  );
};

MaxPsdPerBandChart.propTypes = {
  maxPsdPerBandRows: PropType.arrayOf(PropType.shape()).isRequired,
};

export default MaxPsdPerBandChart;
