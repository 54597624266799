import { IconButton, Tooltip } from "@mui/material";
import PropType from "prop-types";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

const DeviceZoomButton = ({ location, desiredZoom, onClick }) => {
  const handleClick = (event) => {
    event.stopPropagation();
    onClick({
      location,
      zoom: desiredZoom,
    });
  };

  return (
    <Tooltip title="Zoom to device on map">
      <span>
        <IconButton onClick={handleClick} data-testid="device-zoom">
          <ZoomInIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

DeviceZoomButton.propTypes = {
  location: PropType.shape(),
  desiredZoom: PropType.number,
  onClick: PropType.func.isRequired,
};

export default DeviceZoomButton;
