import {
  Box,
  Divider,
  IconButton,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import FilterDrawer from "./FilterDrawer/FilterDrawer";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSystemKpiFilter } from "../../../redux/systemKpiFilter/systemKpiFilterSlice";
import {
  selectIsFetchingErrors,
  selectSystemErrors,
  updateSystemErrors,
} from "../../../redux/systemErrors/systemErrorsSlice";
import ErrorsTable from "./ErrorsTable";
import ErrorsChart from "./ErrorsChart";
import {
  selectIsFetchingPerformance,
  selectSystemPerformance,
  updateSystemPerformance,
} from "../../../redux/systemPerformance/systemPerformanceSlice";
import ErrorBoundary from "../../ErrorBoundary";

const SystemErrors = () => {
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const dispatch = useDispatch();
  const filter = useSelector(selectSystemKpiFilter);
  const systemErrors = useSelector(selectSystemErrors);
  const systemPerformance = useSelector(selectSystemPerformance);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const isLoadingErrors = useSelector(selectIsFetchingErrors);
  const isLoadingPerformance = useSelector(selectIsFetchingPerformance);

  useEffect(() => {
    dispatch(updateSystemErrors({ ...filter, pageSize, pageNumber }));
  }, [filter, pageSize, pageNumber]);

  useEffect(() => {
    dispatch(updateSystemPerformance(filter));
  }, [filter]);

  const handlePageChange = (newPage) => {
    setPageNumber(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPageNumber(0);
  };

  return (
    <Box
      data-testid="container-kpi-system"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 1,
        height: 1,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          minHeight: "40px",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" sx={{ fontSize: "1.5rem", padding: "10px" }}>
            System Health
          </Typography>
        </Box>
        <IconButton
          onClick={() => setFilterDrawerOpen(true)}
          data-testid="kpi-filter-btn"
        >
          <FilterListIcon />
        </IconButton>
        <FilterDrawer
          show={filterDrawerOpen}
          onClose={() => setFilterDrawerOpen(false)}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: 1,
            height: 0.5,
            padding: "10px",
          }}
        >
          <Paper
            elevation={3}
            sx={{
              height: 1,
              width: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ flexGrow: 1, marginBottom: "10px" }}>
              {isLoadingPerformance && (
                <Skeleton sx={{ width: 1, height: 1, margin: "32px" }} />
              )}
              {!isLoadingPerformance &&
                (!systemPerformance || systemPerformance.length == 0) && (
                  <Box
                    sx={{
                      height: 1,
                      width: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography>No data to display</Typography>
                  </Box>
                )}
              {!isLoadingPerformance &&
                systemPerformance &&
                systemPerformance.length > 0 && (
                  <ErrorBoundary>
                    <ErrorsChart performanceData={systemPerformance} />
                  </ErrorBoundary>
                )}
            </Box>
          </Paper>
        </Box>
        <Box
          sx={{
            width: 1,
            height: 0.5,
            padding: "10px",
          }}
        >
          <ErrorBoundary>
            <ErrorsTable
              pageNumber={pageNumber}
              pageSize={pageSize}
              systemErrors={systemErrors}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handlePageSizeChange}
              isLoading={isLoadingErrors}
            />
          </ErrorBoundary>
        </Box>
      </Box>
    </Box>
  );
};

export default SystemErrors;
