import axios from "axios";

export const fetchHistoricalDeviceDailyRequests = async (serialNumber) => {
  const response = await axios.get(
    `${window.config.API_ROOT}/historicalDeviceDailyRequests`,
    {
      headers: {
        accept: "application/json",
      },
      params: {
        serialNumber,
      },
    }
  );
  return response.data;
};
