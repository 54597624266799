import { combineReducers, configureStore } from "@reduxjs/toolkit";

import deviceChannelConfigReducer from "./deviceChannelConfig/deviceChannelConfigSlice";
import deviceClusterReducer, {
  selectIsFetchingDeviceClusterList,
} from "./deviceCluster/deviceClusterSlice";
import deviceDetailReducer from "./deviceDetail/deviceDetailSlice";
import deviceFilterReducer from "./deviceFilter/deviceFilterSlice";
import deviceMapParamsReducer from "./deviceMapParams/deviceMapParamsSlice";
import systemKpiReducer from "./systemKpi/systemKpiSlice";
import systemKpiFilterReducer from "./systemKpiFilter/systemKpiFilterSlice";
import deviceReducer, {
  selectIsFetchingDeviceList,
} from "./deviceInformation/deviceInformationSlice";
import historicalDeviceDailyRequestsReducer from "./historicalDeviceDailyRequests/historicalDeviceDailyRequestsSlice";
import historicalDeviceSummaryEIRPReducer from "./historicalDeviceSummaryEIRP/historicalDeviceSummaryEirpSlice";
import historicalDeviceSummaryPSDReducer from "./historicalDeviceSummaryPSD/historicalDeviceSummaryPsdSlice";
import incumbentClusterReducer, {
  selectIsFetchingIncumbentClusterList,
} from "./incumbentCluster/incumbentClusterSlice";
import incumbentContourReducer, {
  selectIsFetchingIncumbentContours,
} from "./incumbentContour/incumbentContourSlice";
import incumbentDetailReducer from "./incumbentDetail/incumbentDetailSlice";
import incumbentFilterReducer from "./incumbentFilter/incumbentFilterSlice";
import notificationReducer from "./notification/notificationSlice";
import amplifyReducer from "./user/AmplifySlice";
import lookupReducer from "./lookup/lookupSlice";
import maxPsdPerBandReducer, {
  selectIsFetchingMaxPsdPerBand,
} from "./maxPsdPerBand/maxPsdPerBandSlice";
import maxPsdPerFrequencyReducer, {
  selectIsFetchingMaxPsdPerFrequency,
} from "./maxPsdPerFrequency/maxPsdPerFrequencySlice";
import maxEirpPerClassReducer, {
  selectIsFetchingMaxEirpPerClass,
} from "./maxEirpPerClass/maxEirpPerClassSlice";
import maxEirpPerChannelReducer, {
  selectIsFetchingMaxEirpPerChannel,
} from "./maxEirpPerChannel/maxEirpPerChannelSlice";
import userConfigReducer, {
  selectIsFetchingUserConfig,
} from "./userConfig/ConfigSlice";
import systemErrorsReducer from "./systemErrors/systemErrorsSlice";
import systemPerformanceReducer from "./systemPerformance/systemPerformanceSlice";
import deviceKpiReducer from "./deviceKpi/deviceKpiSlice";
import deviceLatencyReportReducer from "./deviceLatencyReport/deviceLatencyReportSlice";
import deviceMap from "../components/DeviceInformation/DeviceMap";
import getOrganizationsReducer from "./userManagement/getOrganizationsSlice";
import getUsersReducer from "./userManagement/getUsersSlice";
import {
  usersCsvDownloadReducer,
  orgsCsvDownloadReducer,
} from "./userManagement/downloadCsvSlice";
import {
  usersCsvUploadReducer,
  organizationsCsvUploadReducer,
} from "./userManagement/uploadCsvSlice";
import resetPasswordReducer from "./userManagement/ResetPasswordSlice";
import {
  getTokensReducer,
  getTokensByOrgIdReducer,
  createTokensReducer,
  deleteTokenReducer,
} from "./userManagement/getTokensSlice";

export const appReducer = combineReducers({
  deviceFilter: deviceFilterReducer,
  deviceMapParams: deviceMapParamsReducer,
  deviceChannelConfig: deviceChannelConfigReducer,
  deviceCluster: deviceClusterReducer,
  deviceDetail: deviceDetailReducer,
  deviceInformation: deviceReducer,
  deviceKpi: deviceKpiReducer,
  deviceLatencyReport: deviceLatencyReportReducer,
  systemKpi: systemKpiReducer,
  systemKpiFilter: systemKpiFilterReducer,
  historicalDeviceDailyRequests: historicalDeviceDailyRequestsReducer,
  historicalDeviceSummaryEIRP: historicalDeviceSummaryEIRPReducer,
  historicalDeviceSummaryPSD: historicalDeviceSummaryPSDReducer,
  incumbentCluster: incumbentClusterReducer,
  incumbentContour: incumbentContourReducer,
  incumbentDetail: incumbentDetailReducer,
  incumbentFilter: incumbentFilterReducer,
  lookup: lookupReducer,
  maxEirpPerChannel: maxEirpPerChannelReducer,
  maxEirpPerClass: maxEirpPerClassReducer,
  maxPsdPerBand: maxPsdPerBandReducer,
  maxPsdPerFrequency: maxPsdPerFrequencyReducer,
  notification: notificationReducer,
  systemErrors: systemErrorsReducer,
  systemPerformance: systemPerformanceReducer,
  user: amplifyReducer,
  userConfig: userConfigReducer,
  getOrganizations: getOrganizationsReducer,
  getUsers: getUsersReducer,
  downloadUsersCSV: usersCsvDownloadReducer,
  downloadOrgsCSV: orgsCsvDownloadReducer,
  uploadUsersCSV: usersCsvUploadReducer,
  uploadOrganizationsCSV: organizationsCsvUploadReducer,
  resetUserPassword: resetPasswordReducer,
  getTokens: getTokensReducer,
  getTokensByOrgId: getTokensByOrgIdReducer,
  createTokens: createTokensReducer,
  deleteToken: deleteTokenReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export function createReduxStore(preloadedState = {}) {
  return configureStore({
    preloadedState,
    reducer: rootReducer,
  });
}

export const store = createReduxStore();

export const selectIsUpdatePending = (state) => {
  return (
    selectIsFetchingMaxPsdPerBand(state) ||
    selectIsFetchingMaxPsdPerFrequency(state) ||
    selectIsFetchingMaxEirpPerChannel(state) ||
    selectIsFetchingMaxEirpPerClass(state) ||
    selectIsFetchingDeviceList(state) ||
    selectIsFetchingDeviceClusterList(state) ||
    selectIsFetchingIncumbentClusterList(state) ||
    selectIsFetchingIncumbentContours(state) ||
    selectIsFetchingUserConfig(state)
  );
};
