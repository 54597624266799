import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deepEqual } from "fast-equals";
import { error } from "../notification/notificationSlice";
import {
  fetchDeviceLatencyReportData,
  fetchDeviceLatencyReportQueryId,
} from "./deviceLatencyReportAPI";

export const initialState = {
  queryId: undefined,
  queryDetails: undefined,
  startMillis: undefined,
  isFetchingReport: false,
  report: undefined,
  reportName: undefined,
};

export const updateDeviceLatencyReport = createAsyncThunk(
  "deviceLatencyReport/fetchDeviceLatencyReport",
  async (queryDetails, thunkApi) => {
    try {
      if (!deepEqual(queryDetails, selectQueryDetails(thunkApi.getState()))) {
        const queryId = await fetchDeviceLatencyReportQueryId(queryDetails);
        thunkApi.dispatch(updateQueryDetails({ queryId, queryDetails }));
        thunkApi.dispatch(pollForDeviceLatencyReport());
      }
    } catch (err) {
      thunkApi.dispatch(error(err.message, "Loading extension lookup values"));
      return thunkApi.rejectWithValue(err.message);
    }
  }
);

export const pollForDeviceLatencyReport = createAsyncThunk(
  "deviceLatencyReport/pollForDeviceLatencyReport",
  async (_, thunkApi) => {
    const queryId = selectQueryId(thunkApi.getState());
    try {
      const report = await fetchDeviceLatencyReportData({ queryId });
      if (report || report == "") {
        return report;
      }
      const now = Date.now();
      const startedAt = selectStartMillis(thunkApi.getState());
      if (now - startedAt > 30000) {
        thunkApi.dispatch(
          error("", "Timeout generating device latency report.")
        );
        return thunkApi.rejectWithValue(undefined);
      }
      setTimeout(() => thunkApi.dispatch(pollForDeviceLatencyReport()), 750);
    } catch (err) {
      thunkApi.dispatch(error(err.message, "Generating latency report."));
      return thunkApi.rejectWithValue(err.message);
    }
  }
);

export const deviceLatencyReportSlice = createSlice({
  name: "deviceLatencyReport",
  initialState,
  reducers: {
    updateQueryDetails: {
      reducer: (state, action) => {
        state.queryDetails = action.payload.queryDetails;
        state.queryId = action.payload.queryId;
        state.isFetchingReport = true;
        state.startMillis = Date.now();
      },
      prepare: (serialNumber) => {
        return { payload: serialNumber };
      },
    },
    clear: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(pollForDeviceLatencyReport.fulfilled, (state, action) => {
        state.isFetchingReport = action.payload === undefined;
        state.report = action.payload;
      })
      .addCase(pollForDeviceLatencyReport.rejected, (state, action) => {
        state.isFetchingReport = false;
        state.report = undefined;
        state.queryDetails = undefined;
      });
  },
});

export const { updateQueryDetails, updateStartMillis, clear } =
  deviceLatencyReportSlice.actions;

export const selectQueryDetails = (state) =>
  state.deviceLatencyReport.queryDetails;
export const selectQueryId = (state) => state.deviceLatencyReport.queryId;
export const selectStartMillis = (state) =>
  state.deviceLatencyReport.startMillis;
export const selectIsFetchingLatencyReport = (state) =>
  state.deviceLatencyReport.isFetchingReport;
export const selectHasReport = (state) =>
  state.deviceLatencyReport.isFetchingReport == false &&
  state.deviceLatencyReport.report != null &&
  state.deviceLatencyReport.report != undefined;
export const selectReport = (state) => state.deviceLatencyReport.report;
export const selectReportName = (state) =>
  `device_latency_${state.deviceLatencyReport.queryDetails?.serialNumber}.csv`;

export default deviceLatencyReportSlice.reducer;
