import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  fetchIncumbentContour as fetchIncumbentContourAPI,
  fetchIncumbentDeviceOverlap as fetchIncumbentDeviceOverlapAPI,
} from "./incumbentContourAPI";
import { error, warn } from "../notification/notificationSlice";
import { selectDeviceFilter } from "../deviceFilter/deviceFilterSlice";
import { GLOBAL_OPERATING_CLASS_FILTERED_CHANNELS } from "../../util/constants";
import { interpolateHslLong } from "d3-interpolate";
import { selectIncumbentFilter } from "../incumbentFilter/incumbentFilterSlice";

export const initialState = {
  incumbentMap: {},
  contourMap: {},
  isFetchingIncumbentContours: false,
  fetchingDeviceOverlap: false,
};

const errorMessage = (err) => {
  if (err?.response?.status != null) {
    return `An error response code was returned: ${err.response.status}`;
  }
  return err?.message;
};

export function toGooglePolygon(contour) {
  return contour.contours.coordinates[0].map((coord) => {
    return {
      lng: coord[0],
      lat: coord[1],
    };
  });
}

export const fetchIncumbentContourByBoundingBox = createAsyncThunk(
  "incumbentContour/fetch",
  async (_, thunkApi) => {
    const deviceFilter = selectDeviceFilter(thunkApi.getState());
    const incumbentFilter = selectIncumbentFilter(thunkApi.getState());
    try {
      const contourList = await fetchIncumbentContourAPI({
        boundingBox: deviceFilter.boundingBox,
        frequencyRange: incumbentFilter.frequencyRange,
      });
      if (contourList.state === "TOO_MANY_CONTOURS") {
        thunkApi.dispatch(
          warn(
            "Too many contours to display in map area!",
            "Loading incumbent contours"
          )
        );
      } else {
        (contourList?.incumbents || []).forEach((contour) =>
          thunkApi.dispatch(addOrReplaceContour(contour))
        );
      }
    } catch (err) {
      const message = errorMessage(err);

      thunkApi.dispatch(error(message, "Loading incumbent contours"));
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const fetchIncumbentContourById = createAsyncThunk(
  "incumbentContour/fetchById",
  async (incumbentIdList, thunkApi) => {
    try {
      await Promise.all(
        incumbentIdList.map(async (id) => {
          const contour = await fetchIncumbentContourAPI({ id });
          thunkApi.dispatch(addOrReplaceIncumbent(contour?.incumbents[0]));
        })
      );
    } catch (err) {
      const message = errorMessage(err);

      thunkApi.dispatch(error(message, "Loading incumbent contour detail"));
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const fetchIncumbentDeviceOverlap = createAsyncThunk(
  "incumbentContour/fetchOverlap",
  async ({ deviceId, incumbentFilter }, thunkApi) => {
    try {
      const incumbentList = await fetchIncumbentDeviceOverlapAPI(
        deviceId,
        incumbentFilter
      );
      incumbentList.forEach((incumbent) => {
        thunkApi.dispatch(addOrReplaceIncumbent(incumbent));
      });
    } catch (err) {
      const message = errorMessage(err);

      thunkApi.dispatch(error(message, "Loading incumbent contour overlap"));
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const displayIncumbentDeviceOverlap = createAsyncThunk(
  "incumbentContour/displayOverlap",
  async ({ deviceId, incumbentFilter }, thunkApi) => {
    try {
      thunkApi.dispatch(clearIncumbentContours());
      const incumbentList = await fetchIncumbentDeviceOverlapAPI(
        deviceId,
        incumbentFilter
      );
      incumbentList.forEach((incumbent) => {
        thunkApi.dispatch(addOrReplaceContour(incumbent));
      });
    } catch (err) {
      const message = errorMessage(err);

      thunkApi.dispatch(error(message, "Loading incumbent contour overlap"));
      return thunkApi.rejectWithValue(message);
    }
  }
);

// Will interpolate by the channel the center frequency
// of the incumbent falls into for the 131 global operating class
// this will produce a range of 41 values scaled between 0 and 1
const numChannels = GLOBAL_OPERATING_CLASS_FILTERED_CHANNELS[131].length;
export function channelInterpolate(freq) {
  const channels = GLOBAL_OPERATING_CLASS_FILTERED_CHANNELS[131];
  if (freq < channels[0].lowerFreq) {
    return "rgb(80,80,80)";
  }
  const match = channels.find(
    (chan) => chan.lowerFreq <= freq && chan.upperFreq >= freq
  );
  if (match) {
    return interpolateHslLong("blue", "yellow")(match.index / numChannels);
  }
  return "rgb(80,80,80)";
}

export const incumbentContourSlice = createSlice({
  name: "incumbentContour",
  initialState,
  reducers: {
    clearIncumbentContours(state) {
      state.contourMap = {};
    },
    clearIncumbentMap(state) {
      state.incumbentMap = {};
    },
    addOrReplaceContour(state, action) {
      const contour = action.payload;
      state.contourMap[contour.properties.id] = {
        path: toGooglePolygon(contour),
        color: channelInterpolate(contour.properties.frequency),
        detail: contour,
      };
    },
    removeContour(state, action) {
      const incumbentId = action.payload;
      delete state.contourMap[incumbentId];
    },
    displayContour(state, action) {
      const incumbentId = action.payload;
      const detail = state.incumbentMap[incumbentId];
      state.contourMap[incumbentId] = {
        path: toGooglePolygon(detail),
        color: channelInterpolate(detail.properties.frequency),
        detail,
      };
    },
    addOrReplaceIncumbent(state, action) {
      const {
        payload: {
          properties: { id },
        },
      } = action;
      state.incumbentMap[id] = action.payload;
    },
    toggleContourHighlight(state, action) {
      const incumbentId = action.payload;
      state.contourMap[incumbentId].isHighlighted =
        !state.contourMap[incumbentId].isHighlighted;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncumbentContourByBoundingBox.pending, (state) => {
        state.isFetchingIncumbentContours = true;
      })
      .addCase(fetchIncumbentContourByBoundingBox.fulfilled, (state) => {
        state.isFetchingIncumbentContours = false;
      })
      .addCase(fetchIncumbentContourByBoundingBox.rejected, (state) => {
        state.isFetchingIncumbentContours = false;
        state.contourMap = {};
      })

      .addCase(fetchIncumbentContourById.pending, (state) => {
        state.isFetchingIncumbentContours = true;
      })
      .addCase(fetchIncumbentContourById.fulfilled, (state) => {
        state.isFetchingIncumbentContours = false;
      })
      .addCase(fetchIncumbentContourById.rejected, (state) => {
        state.isFetchingIncumbentContours = false;
        state.incumbentMap = {};
      })

      .addCase(displayIncumbentDeviceOverlap.pending, (state) => {
        state.isFetchingIncumbentContours = true;
      })
      .addCase(displayIncumbentDeviceOverlap.fulfilled, (state) => {
        state.isFetchingIncumbentContours = false;
      })
      .addCase(displayIncumbentDeviceOverlap.rejected, (state) => {
        state.isFetchingIncumbentContours = false;
        state.incumbentMap = {};
      })

      .addCase(fetchIncumbentDeviceOverlap.pending, (state) => {
        state.fetchingDeviceOverlap = true;
      })
      .addCase(fetchIncumbentDeviceOverlap.fulfilled, (state) => {
        state.fetchingDeviceOverlap = false;
      })
      .addCase(fetchIncumbentDeviceOverlap.rejected, (state) => {
        state.fetchingDeviceOverlap = false;
        state.incumbentMap = {};
      });
  },
});

const selectIncumbentContour = (state) => state.incumbentContour;

export const selectIncumbentContoursList = createSelector(
  selectIncumbentContour,
  (incumbentContour) => {
    const { contourMap } = incumbentContour;
    const keyNames = Object.getOwnPropertyNames(contourMap);
    const contourList = [];
    keyNames.map((key) => {
      contourList.push(contourMap[key]);
    });
    return contourList.sort(
      (a, b) => a.detail.properties.frequency - b.detail.properties.frequency
    );
  }
);

export const selectIsFetchingIncumbentContours = (state) =>
  state.incumbentContour.isFetchingIncumbentContours;
export const selectIsFetchingDeviceOverlap = (state) =>
  state.incumbentContour.fetchingDeviceOverlap;

// adds an isVisible property to each incumbent, is true if the incumbent is present in the contourMap
// and copies the is highlighted from the contours
export const selectIncumbentDetailsList = createSelector(
  selectIncumbentContour,
  (incumbentContour) => {
    const { incumbentMap, contourMap } = incumbentContour;
    const keyNames = Object.getOwnPropertyNames(incumbentMap);
    const detailsList = [];
    keyNames.map((key) => {
      const incumbent = incumbentMap[key];
      const contour = contourMap[key];
      const isVisible = !!contour;
      const isHighlighted = contour?.isHighlighted ?? false;
      const color = channelInterpolate(incumbent.properties.frequency);
      detailsList.push({ ...incumbent, isVisible, isHighlighted, color });
    });
    return detailsList.sort(
      (a, b) => a.properties.frequency - b.properties.frequency
    );
  }
);

export const {
  clearIncumbentContours,
  clearIncumbentMap,
  addOrReplaceIncumbent,
  addOrReplaceContour,
  removeContour,
  displayContour,
  toggleContourHighlight,
} = incumbentContourSlice.actions;
export default incumbentContourSlice.reducer;
