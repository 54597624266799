import PropType from "prop-types";
import { Chip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const ResetChip = ({ onClick }) => {
  return (
    <Chip
      label="Reset All"
      onClick={onClick}
      color="warning"
      icon={<DeleteIcon />}
      data-testid="reset-chip"
    />
  );
};

ResetChip.propTypes = {
  onClick: PropType.func.isRequired,
};

export default ResetChip;
