import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TablePagination,
  Alert,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import BusinessIcon from "@mui/icons-material/Business";
import CustomButton from "../CustomButton/CustomButton";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ResetPasswordModal from "./ResetPasswordModal";
import ManageTokensModal from "./ManageTokensModal";
import ViewToken from "./ViewToken";
import { getOrganizations } from "../../redux/userManagement/getOrganizationsSlice";
import { getUsers } from "../../redux/userManagement/getUsersSlice";
import {
  createToken,
  removeToken,
  getTokensByOrgId,
} from "../../redux/userManagement/getTokensSlice";
import {
  initiateUsersCSVDownload,
  initiateOrgsCSVDownload,
} from "../../redux/userManagement/downloadCsvSlice";
import {
  initiateUploadUsersCsvFile,
  initiateUploadOrganizationsCsvFile,
} from "../../redux/userManagement/uploadCsvSlice";

import { Auth } from "aws-amplify";

const UserAndOrgs = () => {
  const [view, setView] = useState("users");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [selectedOrganizationId, setSelectedOrganizationId] = useState("");
  const [selectedOrganizationName, setSelectedOrganizationName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [manageTokensModalOpen, setManageTokensModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [adminOrgIds, setAdminOrgIds] = useState("");
  const [manageTokensMode, setManageTokensMode] = useState("view");
  const [tokens, setTokens] = useState([]);
  const [excludeTokens, setExcludeTokens] = useState(false);

  const [showTokenModal, setShowTokenModal] = useState(false);
  const [createdToken, setCreatedToken] = useState("");

  const dispatch = useDispatch();
  let orgData = useSelector((state) => state.getOrganizations.data);
  let userData = useSelector((state) => state.getUsers.data);
  userData = [...userData];
  orgData = [...orgData];
  userData.sort((a, b) => a.email.localeCompare(b.email));
  orgData.sort((a, b) => {
    const organizationIdA = String(a.organizationId);
    const organizationIdB = String(b.organizationId);
    return organizationIdA.localeCompare(organizationIdB);
  });

  const fileInputRef = useRef(null);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(getUsers());
      await dispatch(getOrganizations());
      setIsLoading(false);
      await getUserAdminstratorIDs();
    };

    fetchData();
  }, [dispatch]);

  const getUserAdminstratorIDs = async () => {
    const session = await Auth.currentSession();
    const exclude_tokens =
      session.getIdToken().payload.exclude_tokens === "true" ? true : false;
    setExcludeTokens(exclude_tokens);
    setAdminOrgIds(session.getIdToken().payload.administrator_org_ids);
  };

  const handleViewChange = (event, nextView) => {
    if (nextView !== null) {
      if (nextView == "orgs") {
        setSelectedEmail("");
        setSelectedOrganization("");
        setSelectedStatus("");
      } else if (nextView == "users") {
        setSelectedOrganizationName("");
        setSelectedOrganizationId("");
      }
      setView(nextView);
    }
  };

  const handleOrganizationChange = (event) => {
    setSelectedOrganizationId(event.target.value);
  };

  const orgIdToNameMap = new Map();
  orgData.forEach((org) => {
    orgIdToNameMap.set(org.organizationId, org.name);
  });

  const uniqueOrganizations = Array.from(
    new Set(userData.map((user) => user.administrator[0]))
  )
    .filter((orgId) => orgIdToNameMap.has(orgId))
    .map((orgId) => ({
      id: orgId,
      name: orgIdToNameMap.get(orgId) || "Unknown",
    }))
    .filter((org) => org.name !== "Unknown");

  const handleOrganizationNameChange = (event) => {
    setSelectedOrganizationName(event.target.value);
  };
  const uniqueOrgNames = Array.from(new Set(orgData.map((org) => org.name)));
  const filteredOrgData = orgData.filter((org) => {
    if (
      !selectedOrganizationName ||
      selectedOrganizationName === "All" ||
      selectedOrganizationName === ""
    ) {
      return true;
    }
    return org.name === selectedOrganizationName;
  });

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const uniqueStatuses = Array.from(
    new Set(userData.map((user) => user.status))
  );

  const filteredUserData = userData.filter((user) => {
    const matchesEmail = !selectedEmail || user.email === selectedEmail;
    const matchesStatus =
      !selectedStatus ||
      selectedStatus === "All" ||
      user.status === selectedStatus;
    const matchesOrganizationId =
      !selectedOrganizationId ||
      selectedOrganizationId === "All" ||
      user.administrator[0] === selectedOrganizationId;

    return matchesEmail && matchesStatus && matchesOrganizationId;
  });

  const handleResetPassword = () => {
    setResetPasswordModalOpen(true);
  };

  const handleCloseModal = () => {
    setResetPasswordModalOpen(false);
  };

  const handleUploadCsv = (event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    if (file) {
      const uploadAction =
        view === "users"
          ? initiateUploadUsersCsvFile({ file })
          : initiateUploadOrganizationsCsvFile({ file });

      dispatch(uploadAction).then(() => {
        if (view === "users") {
          dispatch(getUsers());
        } else if (view === "orgs") {
          dispatch(getOrganizations());
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleDownloadCSV = () => {
    if (view === "users") {
      dispatch(initiateUsersCSVDownload());
    } else if (view === "orgs") {
      dispatch(initiateOrgsCSVDownload());
    }
  };

  const getOrganizationNameById = (orgId) => {
    const org = orgData.find((o) => o.organizationId === orgId);
    return org ? org.name : "Unknown";
  };

  //enabling pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedUserData = filteredUserData.slice(startIndex, endIndex);
  const paginatedOrgData = filteredOrgData.slice(startIndex, endIndex);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const handleManageTokens = (orgId) => {
    setIsLoading(true);
    setSelectedOrganizationId(orgId);
    const canManageTokens = adminOrgIds.split("|").includes(orgId.toString());
    if (canManageTokens) {
      setManageTokensMode("manage");
      dispatch(getTokensByOrgId(orgId)).then((response) => {
        if (getTokensByOrgId.fulfilled.match(response)) {
          setTokens(response.payload);
        }
        setIsLoading(false);
        setManageTokensModalOpen(true);
      });
    } else {
      setManageTokensMode("view");
      setManageTokensModalOpen(true);
      setIsLoading(false);
    }
  };

  const handleDeleteToken = (token) => {
    setIsLoading(true);
    dispatch(removeToken({ orgId: token.org, tokenName: token.tokenName }))
      .then(() => {
        return dispatch(getTokensByOrgId(token.org)).then((response) => {
          if (getTokensByOrgId.fulfilled.match(response)) {
            setTokens(response.payload);
          }
        });
      })
      .then(() => {
        setSnackbarMessage(`Token ${token.tokenName} deleted successfully`);
        setShowSnackbar(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error during token deletion:", error);
        setIsLoading(false);
      });
  };

  const handleCreateToken = async (orgId, tokenName) => {
    setIsLoading(true);
    try {
      const createResponse = await dispatch(createToken({ tokenName, orgId }));
      if (createResponse.meta.requestStatus === "fulfilled") {
        const createdTokenValue = createResponse.payload.token;
        const getTokensResponse = await dispatch(getTokensByOrgId(orgId));
        if (getTokensByOrgId.fulfilled.match(getTokensResponse)) {
          setTokens(getTokensResponse.payload);
          setCreatedToken(createdTokenValue);
          setShowTokenModal(true);
          setSnackbarMessage(`Token ${tokenName} created successfully`);
          setShowSnackbar(true);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error creating token:", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={2000}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <div>
        <Typography variant="h2" sx={{ fontSize: "1.5rem", padding: "10px" }}>
          Manage Users and Organizations
        </Typography>
        <Divider />
      </div>
      <div style={{ alignItems: "center", marginTop: "20px" }}>
        <ToggleButtonGroup
          color="primary"
          value={view}
          exclusive
          onChange={handleViewChange}
        >
          <ToggleButton value="users">
            <PeopleIcon sx={{ mr: 1 }} />
            Users
          </ToggleButton>
          <ToggleButton value="orgs">
            <BusinessIcon sx={{ mr: 1 }} />
            Organizations
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <br />
      <div>
        {view === "users" && (
          <>
            <Typography
              variant="h2"
              sx={{ fontSize: "1.5rem", padding: "10px", marginLeft: "5px" }}
            >
              Users
            </Typography>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{ padding: "10px" }}
            >
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="email-autocomplete"
                      options={userData.map((user) => user.email)}
                      getOptionLabel={(option) => option}
                      style={{ width: 210 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Filter by email" />
                      )}
                      value={selectedEmail || ""}
                      onChange={(event, newValue) => {
                        setSelectedEmail(newValue);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option === value ||
                        (!value && value === "") ||
                        option === ""
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="org-filter-label">
                      Filter by organization
                    </InputLabel>
                    <Select
                      labelId="org-filter-label"
                      id="org-filter"
                      label="Filter by organization"
                      value={selectedOrganizationId}
                      onChange={handleOrganizationChange}
                    >
                      <MenuItem key="all-orgs" value="">
                        <em>All</em>
                      </MenuItem>
                      {uniqueOrganizations.map((org) => (
                        <MenuItem key={`org-${org.id}`} value={org.id}>
                          {`${org.name} (orgID: ${org.id})`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="status-filter-label">
                      Filter by status
                    </InputLabel>
                    <Select
                      labelId="status-filter-label"
                      id="status-filter"
                      label="Filter by status"
                      value={selectedStatus}
                      onChange={handleStatusChange}
                    >
                      <MenuItem key="all-statuses" value="">
                        <em>All</em>
                      </MenuItem>
                      {uniqueStatuses.map((status, index) => (
                        <MenuItem key={`status-${index}`} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleUploadCsv}
                style={{ display: "none" }}
                accept=".csv"
              />
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
              >
                <CustomButton
                  text="Reset Password/MFA"
                  onClick={handleResetPassword}
                  sx={{
                    color: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      opacity: "70%",
                    },
                    backgroundColor: "white",
                    opacity: "100%",
                    width: "180px",
                    maxWidth: "180px",
                    borderRadius: 10,
                    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                  }}
                />
                <ResetPasswordModal
                  open={resetPasswordModalOpen}
                  handleClose={handleCloseModal}
                  userEmails={userData.map((user) => user.email)}
                  setShowSnackbar={setShowSnackbar}
                  setSnackbarMessage={setSnackbarMessage}
                />
                <CustomButton
                  text="Upload CSV"
                  onClick={triggerFileInput}
                  sx={{
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#FAA300",
                      opacity: "70%",
                    },
                    backgroundColor: "#FAA300",
                    opacity: "100%",
                    width: "150px",
                    maxWidth: "150px",
                    height: "50px",
                    borderRadius: 10,
                    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                  }}
                  startIcon={<CloudUploadOutlinedIcon />}
                />
                <CustomButton
                  text="Download CSV"
                  onClick={handleDownloadCSV}
                  sx={{
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#001EFA",
                      opacity: "70%",
                    },
                    backgroundColor: "#001EFA",
                    opacity: "100%",
                    width: "160px",
                    maxWidth: "160px",
                    height: "50px",
                    borderRadius: 10,
                    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                  }}
                  startIcon={<CloudDownloadOutlinedIcon />}
                />
              </Grid>
            </Grid>
          </>
        )}
      </div>
      <div>
        {view === "orgs" && (
          <>
            <Typography
              variant="h2"
              sx={{ fontSize: "1.5rem", padding: "10px", marginLeft: "5px" }}
            >
              Organizations
            </Typography>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{ padding: "10px" }}
            >
              <Grid item xs={12} md={2}>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="name-filter-autocomplete"
                      options={uniqueOrgNames}
                      value={selectedOrganizationName || ""}
                      onChange={(event, newValue) => {
                        setSelectedOrganizationName(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Filter by name"
                          fullWidth
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option === value ||
                        (!value && value === "") ||
                        option === ""
                      }
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleUploadCsv}
                  style={{ display: "none" }}
                  accept=".csv"
                />
                <ManageTokensModal
                  open={manageTokensModalOpen}
                  handleClose={() => {
                    setManageTokensModalOpen(false);
                    setManageTokensMode("view");
                  }}
                  handleDeleteToken={handleDeleteToken}
                  handleCreateToken={(tokenName) =>
                    handleCreateToken(selectedOrganizationId, tokenName)
                  }
                  isLoading={isLoading}
                  tokens={tokens}
                  mode={manageTokensMode}
                />
                <ViewToken
                  open={showTokenModal}
                  token={createdToken}
                  onClose={() => setShowTokenModal(false)}
                />
                <CustomButton
                  text="Upload CSV"
                  onClick={triggerFileInput}
                  sx={{
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#FAA300",
                      opacity: "70%",
                    },
                    backgroundColor: "#FAA300",
                    opacity: "100%",
                    width: "150px",
                    maxWidth: "150px",
                    height: "50px",
                    borderRadius: 10,
                    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                  }}
                  startIcon={<CloudUploadOutlinedIcon />}
                />
                <CustomButton
                  text="Download CSV"
                  onClick={handleDownloadCSV}
                  sx={{
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#001EFA",
                      opacity: "70%",
                    },
                    backgroundColor: "#001EFA",
                    opacity: "100%",
                    width: "160px",
                    maxWidth: "160px",
                    height: "50px",
                    borderRadius: 10,
                    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                  }}
                  startIcon={<CloudDownloadOutlinedIcon />}
                />
              </Grid>
            </Grid>
          </>
        )}
      </div>
      {view === "orgs" && (
        <TableContainer
          sx={{ maxHeight: "600px", overflowY: "auto", flexGrow: 1 }}
        >
          <Table
            sx={{
              maxHeight: 1,
              overflow: "auto",
            }}
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell>Organization ID</TableCell>
                <TableCell>Parent Organization</TableCell>
                <TableCell>Organization Name</TableCell>
                <TableCell>Contact Email</TableCell>
                <TableCell>Contact Address</TableCell>
                <TableCell>Contact Phone</TableCell>
                <TableCell>Enabled</TableCell>
                <TableCell>My Role</TableCell>
                <TableCell>User Tokens</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedOrgData.map((orgData, index) => (
                <TableRow key={orgData.organizationId || `org-${index}`}>
                  <TableCell>{orgData.organizationId}</TableCell>
                  <TableCell>{orgData.parentOrganizationId}</TableCell>
                  <TableCell>{orgData.name}</TableCell>
                  <TableCell>{orgData.contactEmail}</TableCell>
                  <TableCell>{orgData.contactAddress}</TableCell>
                  <TableCell>{orgData.contactPhone}</TableCell>
                  <TableCell>{String(orgData.enabled)}</TableCell>
                  <TableCell>{orgData.myRole}</TableCell>
                  <TableCell>
                    {excludeTokens ? (
                      <div>No Access</div>
                    ) : (
                      <CustomButton
                        text={
                          adminOrgIds
                            .split("|")
                            .includes(orgData.organizationId.toString())
                            ? "Manage Tokens"
                            : "View Tokens"
                        }
                        sx={{
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#00A2FF",
                            opacity: "70%",
                          },
                          backgroundColor: "#00A2FF",
                          opacity: "100%",
                          width: "160px",
                          maxWidth: "160px",
                          height: "50px",
                          borderRadius: 10,
                          boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                        }}
                        onClick={() =>
                          handleManageTokens(orgData.organizationId)
                        }
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredOrgData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
      {view === "users" && (
        <TableContainer
          sx={{ maxHeight: "600px", overflowY: "auto", flexGrow: 1 }}
        >
          <Table
            sx={{
              maxHeight: 1,
              overflow: "auto",
            }}
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Enabled</TableCell>
                <TableCell>Administrator</TableCell>
                <TableCell>Reporting</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedUserData.map((userData, index) => (
                <TableRow key={userData.organizationId || `user-${index}`}>
                  <TableCell>{userData.email}</TableCell>
                  <TableCell>{userData.name}</TableCell>
                  <TableCell>{userData.address}</TableCell>
                  <TableCell>{userData.phone}</TableCell>
                  <TableCell>{String(userData.enabled)}</TableCell>
                  <TableCell>
                    <Tooltip
                      title={getOrganizationNameById(userData.administrator[0])}
                    >
                      <span>{userData.administrator.join(" | ")}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{userData.reporting.join(" | ")}</TableCell>
                  <TableCell>{userData.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredUserData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
    </>
  );
};

export default UserAndOrgs;
