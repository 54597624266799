import { Box, Paper, Popper, Typography } from "@mui/material";
import PropType from "prop-types";
import { useState } from "react";
import SmallLegend from "./SmallLegend";

const PSDChart = ({ minChartValue, maxChartValue, chartData, testId }) => {
  const upperScale = [];
  const lowerScale = [];
  const chartWidth = maxChartValue - minChartValue;
  for (let freq = minChartValue; freq <= maxChartValue; freq += 40) {
    upperScale.push(`${freq}`);
  }
  for (let freq = minChartValue + 20; freq <= maxChartValue; freq += 40) {
    lowerScale.push(`${freq}`);
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "visible",
      }}
      data-testid={testId}
    >
      <Box
        sx={{
          width: 1,
          height: "82px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <Box sx={{ minWidth: "51px", textAlign: "center" }}>
          <Box sx={{ minHeight: "18px" }}></Box>
          <Typography sx={{ fontSize: "0.75rem" }}>PSD</Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {chartData.map((row, index) => (
            <Bar key={row.frequency} chartWidth={chartWidth} row={row} />
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          width: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          overflow: "visible",
        }}
      >
        <Box sx={{ minWidth: "51px" }}></Box>
        <Box
          sx={{
            flexGrow: 1,
            overflow: "visible",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {upperScale.map((scaleValue, index) => (
            <Box
              key={scaleValue}
              sx={{
                minWidth: `${(40 / chartWidth) * 100}%`,
                textAlign: "center",
                position: "relative",
                left: `-${(40 / chartWidth) * 50}%`,
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.75rem",
                }}
              >
                {scaleValue}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          width: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          overflow: "visible",
        }}
      >
        <Box sx={{ minWidth: "51px" }}></Box>
        <Box
          sx={{
            flexGrow: 1,
            overflow: "visible",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box sx={{ minWidth: `${(20 / chartWidth) * 100}%` }}></Box>
          {lowerScale.map((scaleValue, index) => (
            <Box
              key={scaleValue}
              sx={{
                minWidth: `${(40 / chartWidth) * 100}%`,
                textAlign: "center",
                position: "relative",
                left: `-${(40 / chartWidth) * 50}%`,
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.75rem",
                }}
              >
                {scaleValue}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const minPsd = -15;
const maxPsd = 23;
const Bar = ({ chartWidth, row }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const barHeight =
    0.25 + 0.75 * (((row.value ?? minPsd) - minPsd) / (maxPsd - minPsd));
  return (
    <>
      <Box
        sx={{
          height: "82px",
          minWidth: `${(1 / chartWidth) * 100}%`,
          display: "flex",
          flexDirection: "column-reverse",
          boxSizing: "border-box",
        }}
        aria-haspopup="true"
        data-testid={`psd${row.frequency}`}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Box
          sx={{
            height: `${64 * barHeight}px`,
            backgroundColor: row.color,
            width: 1,
          }}
        ></Box>
        <Box sx={{ minHeight: "18px" }}></Box>
      </Box>
      <Popper
        sx={{
          zIndex: 2000,
        }}
        placement={"right"}
        open={open}
        anchorEl={anchorEl}
      >
        <Paper sx={{ padding: "5px" }}>
          <Typography sx={{ fontSize: "0.75rem" }}>
            Frequency: {row.frequency}
          </Typography>
          {row.value && (
            <Typography sx={{ fontSize: "0.75rem" }}>
              PSD: {row.value} dBm/MHz
            </Typography>
          )}
          <SmallLegend color={row.color} description={row.description} />
        </Paper>
      </Popper>
    </>
  );
};

Bar.propTypes = {
  chartWidth: PropType.number, // The width of the chart in MHz
  row: PropType.shape({
    frequency: PropType.number,
    color: PropType.string,
    value: PropType.number,
    description: PropType.string,
  }),
};

PSDChart.propTypes = {
  minChartValue: PropType.number, // in MHz
  maxChartValue: PropType.number, // in MHz
  chartData: PropType.arrayOf(
    PropType.shape({
      frequency: PropType.number,
      color: PropType.string,
      value: PropType.number,
    })
  ),
  testId: PropType.string,
};

export default PSDChart;
