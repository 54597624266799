import PropType from "prop-types";
import { Box, Paper } from "@mui/material";
import Chart from "react-google-charts";
import { useEffect, useMemo, useState } from "react";
import moment from "moment/moment";

const ErrorsChart = ({ performanceData }) => {
  // The google chart does not correctly resize on window resize
  // this will force a rerender of the chart using a key attribute.
  const [chartKey, setChartKey] = useState(0);
  const handleResize = () => {
    setChartKey((prev) => prev + 1);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const errorTypes = {
    operational: {
      id: "operational",
      color: "green",
      label: "Operational",
    },
    partialOutage: {
      id: "partialOutage",
      color: "red",
      label: "Partial Outage",
    },
    majorOutage: {
      id: "majorOutage",
      color: "darkkhaki",
      label: "Major Outage",
    },
    degradedPerformance: {
      id: "degradedPerformance",
      color: "blue",
      label: "Degraded Performance",
    },
    maintenance: {
      id: "maintenance",
      color: "orange",
      label: "Maintenance",
    },
    probeDegraded: {
      id: "probeDegraded",
      color: "pink",
      label: "Probe Degraded",
    },
  };

  const convertData = (data) => {
    const colors = [];
    const chartData = [];
    const chartTypes = [
      {
        type: "date",
        label: "Date",
      },
    ];

    Object.keys(errorTypes).forEach((key) => {
      const errorType = errorTypes[key];
      chartTypes.push({
        type: "number",
        label: errorType.label,
      });
      chartTypes.push({ role: "tooltip", type: "string", p: { html: true } });
      colors.push(errorType.color);
    });
    chartData.push(chartTypes);

    // Google charts has a bug, is misses the first column for continuous axis.
    // Will add an artificial row for the minimum
    const minimumDate = data.reduce((min, row) => {
      const receivedMoment = moment.utc(row.intervalStart);
      const minMoment = moment.utc(min.intervalStart);
      const newMin = receivedMoment.isBefore(minMoment) ? row : min;
      return newMin;
    });

    const firstRow = [
      moment.utc(minimumDate.intervalStart).subtract(1, "day").toDate(),
    ];
    for (let x = 0; x < 6; x++) {
      firstRow.push(0);
      firstRow.push("");
    }
    chartData.push(firstRow);

    data.forEach((row) => {
      const rowData = [moment.utc(row.intervalStart).set("hour", 1).toDate()];
      Object.keys(errorTypes).forEach((key) => {
        const errorType = errorTypes[key];
        rowData.push(row[errorType.id].percentage);
        rowData.push(
          `<div style="padding: 10px;"><div><b>${moment
            .utc(row.intervalStart)
            .format("MMM DD YYYY")}</b></div><div>${errorType.label}: <b>${
            row[errorType.id].count
          }</b> (${(row[errorType.id].percentage * 100).toFixed(
            2
          )}%)</div></div>`
        );
      });
      chartData.push(rowData);
    });
    return {
      chartData,
      colors,
    };
  };

  const { chartData, colors } = useMemo(
    () => convertData(performanceData),
    [performanceData]
  );

  const options = {
    isStacked: true,
    legend: {
      position: "bottom",
    },
    vAxis: {
      minValue: 0,
      format: "percent",
    },
    hAxis: {
      gridlines: {
        color: "transparent",
      },
      format: "MM/dd/yy",
    },
    colors: colors,
    tooltip: { isHtml: true },
    areaOpacity: 1,
  };

  return (
    <span data-testid="errors-chart">
      <Chart
        key={chartKey}
        chartType="SteppedAreaChart"
        width="100%"
        height="100%"
        data={chartData}
        options={options}
      />
    </span>
  );
};

ErrorsChart.propTypes = {
  performanceData: PropType.arrayOf(PropType.shape({})),
};
export default ErrorsChart;
