export const errorTypes = {
  success: {
    id: "success",
    color: "green",
    label: "Success",
  },
  deviceDisallowed: {
    id: "deviceDisallowed",
    color: "red",
    label: "Device Disallowed",
  },
  generalFailure: {
    id: "generalFailure",
    color: "darkkhaki",
    label: "General Failure",
  },
  invalidValue: {
    id: "invalidValue",
    color: "blue",
    label: "Invalid Value",
  },
  missingParam: {
    id: "missingParam",
    color: "orange",
    label: "Missing Param",
  },
  unexpectedParam: {
    id: "unexpectedParam",
    color: "pink",
    label: "Unexpected Param",
  },
  unspecifiedClientError: {
    id: "unspecifiedClientError",
    color: "purple",
    label: "Unspecified Client Error",
  },
  unspecifiedServerError: {
    id: "unspecifiedServerError",
    color: "brown",
    label: "Unspecified Server Error",
  },
  unsupportedSpectrum: {
    id: "unsupportedSpectrum",
    color: "skyblue",
    label: "Unsupported Spectrum",
  },
  unsupportedBasis: {
    id: "unsupportedBasis",
    color: "#C2B280",
    label: "Unsupported Basis",
  },
  versionNotSupported: {
    id: "versionNotSupported",
    color: "#E55451",
    label: "Version Not Supported",
  },
};
