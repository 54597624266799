import axios from "axios";

export const fetchHistoricalDeviceSummaryEIRPById = async (
  serialNumber,
  intervalType,
  startDate,
  maxEIRP
) => {
  const response = await axios.get(
    `${window.config.API_ROOT}/historicalDeviceSummaryEIRP`,
    {
      headers: {
        accept: "application/json",
      },
      params: {
        serialNumber,
        intervalType,
        startDate,
        maxEIRP,
      },
    }
  );
  return response.data;
};
