import axios from "axios";

export const resetUserAuthentication = async (userEmail) => {
  try {
    const response = await axios.put(
      `${window.config.MANAGEMENT_API_ROOT}/reset-user-authenication`,
      {
        userEmail,
      }
    );
    return response.data;
  } catch (error) {
    throw error.response || error;
  }
};
