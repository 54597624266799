import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { error } from "../notification/notificationSlice";
import { fetchSystemErrors } from "./systemErrorsAPI";

export const initialState = {
  isFetchingErrors: false,
  errors: undefined,
};

export const updateSystemErrors = createAsyncThunk(
  "systemErrors/fetchSystemErrors",
  async (filter, thunkApi) => {
    try {
      const { startDate, endDate, pageNumber, pageSize } = filter;
      return await fetchSystemErrors(startDate, endDate, pageNumber, pageSize);
    } catch (err) {
      thunkApi.dispatch(error(err?.message, "Loading system errors"));
      return thunkApi.rejectWithValue(err?.message);
    }
  }
);

export const systemErrorsSlice = createSlice({
  name: "systemErrors",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(updateSystemErrors.pending, (state) => {
        state.isFetchingErrors = true;
      })
      .addCase(updateSystemErrors.fulfilled, (state, action) => {
        state.isFetchingErrors = false;
        state.errors = action.payload;
      })
      .addCase(updateSystemErrors.rejected, (state, action) => {
        state.isFetchingErrors = false;
        state.errors = undefined;
      });
  },
});

export const selectSystemErrors = (state) => state.systemErrors.errors;

export const selectIsFetchingErrors = (state) =>
  state.systemErrors.isFetchingErrors;
export default systemErrorsSlice.reducer;
