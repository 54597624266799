import PropType from "prop-types";
import { Chip } from "@mui/material";

const FccCertificationIdChip = ({ deviceFilter, onDelete }) => {
  // Do not render the chip if the filter is not set
  if (deviceFilter.fccCertificationId === undefined) {
    return null;
  }

  return (
    <Chip
      onDelete={onDelete}
      label={`FCC ID [${deviceFilter.fccCertificationId?.length} selected]`}
      data-testid="fcc-certification-id-chip"
    />
  );
};

FccCertificationIdChip.propTypes = {
  deviceFilter: PropType.shape({
    fccCertificationId: PropType.arrayOf(PropType.string),
  }),
  onDelete: PropType.func,
};

export default FccCertificationIdChip;
