import PropType from "prop-types";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import Skeleton from "@mui/material/Skeleton";

const HistoricalDeviceSummaryEirpSkeleton = ({ error, empty }) => {
  return (
    <>
      {error && !empty && (
        <Alert
          severity="error"
          style={{ width: "90%", marginLeft: "5%", marginTop: "1rem" }}
        >
          <AlertTitle>
            Error - Loading Historical Device EIRP Summary
          </AlertTitle>
          {error}
        </Alert>
      )}
      {!error && empty && (
        <Alert
          severity="warning"
          style={{ width: "90%", marginLeft: "5%", marginTop: "1rem" }}
        >
          <AlertTitle>No Data</AlertTitle>
          No data for given filter conditions.
        </Alert>
      )}
      {!error && !empty && (
        <Skeleton
          variant="rectangular"
          height={650}
          sx={{ my: "1rem", mx: "5%" }}
          animation={!error ? "pulse" : false}
          data-testid="skeletonChartDeviceEirp"
        />
      )}
    </>
  );
};

HistoricalDeviceSummaryEirpSkeleton.propTypes = {
  error: PropType.string,
  empty: PropType.bool,
};

HistoricalDeviceSummaryEirpSkeleton.defaultProps = {
  error: null,
  empty: false,
};

export default HistoricalDeviceSummaryEirpSkeleton;
