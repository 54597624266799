import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, AlertTitle, Snackbar } from "@mui/material";

import {
  NotificationType,
  selectNotification,
  clear,
} from "../../redux/notification/notificationSlice";

const DEFAULT_AUTO_CLOSE_DELAY = 5000;
const WARN_AUTO_CLOSE_DELAY = 10000;
const ERROR_AUTO_CLOSE_DELAY = 20000;

const notificationAutoCloseDelay = (notificationType) => {
  if (notificationType === NotificationType.ERROR) {
    return ERROR_AUTO_CLOSE_DELAY;
  }

  if (notificationType === NotificationType.WARNING) {
    return WARN_AUTO_CLOSE_DELAY;
  }
  return DEFAULT_AUTO_CLOSE_DELAY;
};

const Notification = () => {
  const dispatch = useDispatch();

  const clearNotification = useCallback(() => dispatch(clear()), [dispatch]);

  useEffect(() => {
    clearNotification();
  }, [location, clearNotification]);

  const { type, message, context } = useSelector(selectNotification);

  if (type === NotificationType.NONE) {
    return <></>;
  }
  return (
    <Snackbar
      open
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={notificationAutoCloseDelay(type)}
      onClose={clearNotification}
    >
      <Alert
        severity={type}
        style={{ width: "100%" }}
        onClose={clearNotification}
      >
        <AlertTitle>
          {type[0].toUpperCase() + type.substring(1)}
          {context && ` - ${context}`}
        </AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
