import { useEffect, useState } from "react";
import PropType from "prop-types";
import { Box, CircularProgress } from "@mui/material";

const LoadingElement = ({ timeoutSeconds = 5 }) => {
  const [hasTimeout, setHasTimeout] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setHasTimeout(true), timeoutSeconds * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <>
      {hasTimeout && (
        <Box
          sx={{
            width: 1,
            height: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

LoadingElement.propTypes = {
  timeoutSeconds: PropType.number,
};

export default LoadingElement;
