import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const deviceFilterSlice = createSlice({
  name: "deviceFilter",
  initialState,
  reducers: {
    updateBoundingBox: {
      reducer: (state, action) => {
        state.boundingBox = action.payload;
      },
      prepare: (nwLat, nwLong, seLat, seLong) => {
        return { payload: { nwLat, nwLong, seLat, seLong } };
      },
    },
    updateIndoor: {
      reducer: (state, action) => {
        if (action.payload == undefined) {
          delete state.indoor;
        } else {
          state.indoor = action.payload;
        }
      },
      prepare: (indoor) => {
        return { payload: indoor };
      },
    },
    updateMaxHeight: (state, action) => {
      if (action.payload) {
        state.maxDeviceHeight = action.payload;
      } else {
        delete state.maxDeviceHeight;
      }
    },
    updateGlobalOperatingClass: {
      reducer: (state, action) => {
        if (!action.payload) {
          delete state.globalOperatingClass;
        } else {
          state.globalOperatingClass = action.payload;
        }
      },
      prepare: (operatingClassArray) => {
        return { payload: operatingClassArray };
      },
    },
    updateFrequencyBand: {
      reducer: (state, action) => {
        if (!action.payload) {
          delete state.frequencyBand;
        } else {
          state.frequencyBand = action.payload;
        }
      },
      prepare: (frequencyBand) => {
        return { payload: frequencyBand };
      },
    },
    updatePrimaryGlobalExtension: {
      reducer: (state, action) => {
        if (!action.payload) {
          delete state.primaryGlobalVendorExtension;
        } else {
          state.primaryGlobalVendorExtension = action.payload;
        }
      },
      prepare: (extensions) => {
        return { payload: extensions };
      },
    },
    updatePrimaryRequestExtension: {
      reducer: (state, action) => {
        if (!action.payload) {
          delete state.primaryRequestVendorExtension;
        } else {
          state.primaryRequestVendorExtension = action.payload;
        }
      },
      prepare: (extensions) => {
        return { payload: extensions };
      },
    },
    updateSerialNumber: {
      reducer: (state, action) => {
        if (!action.payload) {
          delete state.serialNumber;
        } else {
          state.serialNumber = action.payload;
        }
      },
      prepare: (serialNumber) => {
        return { payload: serialNumber };
      },
    },
    updateFccCertificationId: {
      reducer: (state, action) => {
        if (!action.payload) {
          delete state.fccCertificationId;
        } else {
          state.fccCertificationId = action.payload;
        }
      },
      prepare: (fccCertificationId) => {
        return { payload: fccCertificationId };
      },
    },
  },
});

export const {
  updateBoundingBox,
  updateIndoor,
  updateMaxHeight,
  updateGlobalOperatingClass,
  updateFrequencyBand,
  updatePrimaryGlobalExtension,
  updatePrimaryRequestExtension,
  updateSerialNumber,
  updateFccCertificationId,
} = deviceFilterSlice.actions;

export const selectDeviceFilter = (state) => state.deviceFilter;

export default deviceFilterSlice.reducer;
