import { Box, Typography } from "@mui/material";
import PropType from "prop-types";

const SmallLegend = ({ color, description }) => {
  return (
    <Box sx={{ paddingTop: "10px" }}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Box
          sx={{
            minHeight: "15px",
            minWidth: "15px",
            backgroundColor: color,
            borderRadius: "50%",
            border: "solid 1px",
            display: "inline-block",
          }}
        ></Box>
        <Box sx={{ display: "inline-block", paddingLeft: "10px" }}>
          <Typography sx={{ fontSize: "0.75rem" }}>{description}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

SmallLegend.propTypes = {
  color: PropType.string,
  description: PropType.string,
};

export default SmallLegend;
