import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { error } from "../notification/notificationSlice";
import { fetchHistoricalDeviceDailyRequests as fetchHistoricalDeviceDailyRequestsAPI } from "./historicalDeviceDailyRequestsAPI";

export const intervalType = {
  DAILY: "DAILY",
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
};

export const initialState = {
  isFetchingHistoricalDeviceDailyRequests: false,
  requestsList: [],
};

export const fetchHistoricalDeviceDailyRequests = createAsyncThunk(
  "fetchHistoricalDeviceDailyRequests/fetch",
  async ({ serialNumber }, thunkApi) => {
    try {
      const requestList = await fetchHistoricalDeviceDailyRequestsAPI(
        serialNumber
      );
      return requestList;
    } catch (err) {
      thunkApi.dispatch(error(err.message, "Loading device daily requests"));
      return thunkApi.rejectWithValue(errorMessage(err));
    }
  }
);

export const historicalDeviceDailyRequestsSlice = createSlice({
  name: "historicalDeviceDailyRequests",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchHistoricalDeviceDailyRequests.pending, (state) => {
        state.isFetchingHistoricalDeviceDailyRequests = true;
      })
      .addCase(
        fetchHistoricalDeviceDailyRequests.fulfilled,
        (state, { payload }) => {
          state.isFetchingHistoricalDeviceDailyRequests = false;
          state.requestsList = payload;
        }
      )
      .addCase(
        fetchHistoricalDeviceDailyRequests.rejected,
        (state, { payload, error }) => {
          state.isFetchingHistoricalDeviceDailyRequests = false;
          state.requestsList = [];
        }
      );
  },
});

export const selectHistoricalDeviceDailyRequestsList = (state) =>
  state.historicalDeviceDailyRequests.requestsList;
export const selectIsFetchingHistoricalDeviceDailyRequests = (state) =>
  state.historicalDeviceDailyRequests.isFetchingHistoricalDeviceDailyRequests;

export default historicalDeviceDailyRequestsSlice.reducer;
