import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchTokens,
  createTokens,
  deleteToken,
  fetchTokenByOrgId,
} from "./getTokensAPI";

export const getTokens = createAsyncThunk(
  "tokens/fetch",
  async (_, thunkApi) => {
    try {
      return await fetchTokens();
    } catch (err) {
      return thunkApi.rejectWithValue(err.toString());
    }
  }
);

const tokenSlice = createSlice({
  name: "tokens",
  initialState: {
    loading: false,
    data: [],
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTokens.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTokens.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(getTokens.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const getTokensByOrgId = createAsyncThunk(
  "tokensByOrg/fetch",
  async (orgId, thunkApi) => {
    try {
      return await fetchTokenByOrgId(orgId);
    } catch (err) {
      console.log(err);
      return thunkApi.rejectWithValue(err.toString());
    }
  }
);

const tokensByOrgSlice = createSlice({
  name: "tokensByOrgId",
  initialState: {
    loading: false,
    data: [],
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTokensByOrgId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTokensByOrgId.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(getTokensByOrgId.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const createToken = createAsyncThunk(
  "tokens/fetch",
  async ({ tokenName, orgId }, thunkApi) => {
    try {
      const response = await createTokens(tokenName, orgId);
      return response;
    } catch (err) {
      return thunkApi.rejectWithValue(err.toString());
    }
  }
);

const createTokenSlice = createSlice({
  name: "createToken",
  initialState: {
    tokens: [],
    loading: false,
    error: null,
    success: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(createToken.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(createToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

export const removeToken = createAsyncThunk(
  "tokens/delete",
  async ({ orgId, tokenName }, thunkApi) => {
    try {
      return await deleteToken(orgId, tokenName);
    } catch (error) {
      return thunkApi.rejectWithValue(error.toString());
    }
  }
);

const deleteTokenSlice = createSlice({
  name: "deleteToken",
  initialState: {
    loading: false,
    data: [],
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeToken.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter(
          (token) => token.tokenName !== action.meta.arg.tokenName
        );
      })
      .addCase(removeToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const getTokensReducer = tokenSlice.reducer;
export const getTokensByOrgIdReducer = tokensByOrgSlice.reducer;
export const createTokensReducer = createTokenSlice.reducer;
export const deleteTokenReducer = deleteTokenSlice.reducer;
