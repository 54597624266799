import PropType from "prop-types";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";

// Should take a new value and prevent the sliders from overlapping
// they should never get closer than minDistance from each other
export function processChange(newValue, currentValue, activeThumb) {
  const minDistance = 0.1;
  if (activeThumb === 0) {
    return [
      Math.min(newValue[0], currentValue[1] - minDistance),
      currentValue[1],
    ];
  }

  return [
    currentValue[0],
    Math.max(newValue[1], currentValue[0] + minDistance),
  ];
}

export function fireOnChange(value, maxHeight, minHeight, onChange) {
  if (value[0] !== minHeight || value[1] !== maxHeight) {
    onChange({
      maximum: value[1],
      minimum: value[0],
    });
  } else {
    onChange(undefined);
  }
}

const HeightFilter = ({ value, maxHeight = 10, minHeight = 0, onChange }) => {
  const [sliderValue, setSliderValue] = useState([minHeight, maxHeight]);

  useEffect(() => {
    const min = value?.minimum;
    const max = value?.maximum;
    if (min && max) {
      setSliderValue([min, max]);
    }
  }, [value]);

  const handleChange = (event, newValue, activeThumb) => {
    setSliderValue(processChange(newValue, sliderValue, activeThumb));
  };

  const commitChange = () => {
    fireOnChange(sliderValue, maxHeight, minHeight, onChange);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Typography sx={{ paddingRight: "30px" }}>Height</Typography>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography>{`${sliderValue[0]}m - ${sliderValue[1]}m`}</Typography>
        <Slider
          value={sliderValue}
          step={0.1}
          max={maxHeight}
          min={minHeight}
          onChange={handleChange}
          onChangeCommitted={commitChange}
          data-testid="height-slider"
        />
      </Box>
    </Box>
  );
};

HeightFilter.propTypes = {
  value: PropType.shape({
    minimum: PropType.number,
    maximum: PropType.number,
  }),
  maxHeight: PropType.number,
  minHeight: PropType.number,
  onChange: PropType.func.isRequired,
};

export default HeightFilter;
