import moment from "moment";

export const momentFormat = "yyyy-MM-DD";

const today = () =>
  window.config.DATE_OVERRIDE
    ? moment.utc(window.config.DATE_OVERRIDE)
    : moment.utc();

export const intervalTypes = {
  DAILY: {
    id: "DAILY",
    description: "Daily",
    defaultStartDate: () =>
      today().startOf("day").subtract(31, "day").format(momentFormat),
    defaultEndDate: () => today().endOf("day").format(momentFormat),
  },
  MONTHLY: {
    id: "MONTHLY",
    description: "Monthly",
    defaultStartDate: () =>
      today()
        .startOf("day")
        .startOf("month")
        .subtract(11, "months")
        .format(momentFormat),
    defaultEndDate: () => today().endOf("day").format(momentFormat),
  },
};
