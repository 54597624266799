import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDeviceInformation } from "./deviceInformationAPI";

import { error } from "../notification/notificationSlice";
import { deepEqual } from "fast-equals";

export const initialState = {
  deviceList: [],
  totalDeviceCount: 0,
  rowsPerPage: 10,
  currentPage: 0,
  isFetchingDeviceList: false,
  currentFilter: undefined,
  lastPageFetched: undefined,
  lastRowsPerPageFetched: undefined,
};

export const fetchDeviceList = createAsyncThunk(
  "deviceInformation/fetchDeviceList",
  async (deviceFilter, thunkApi) => {
    try {
      const {
        deviceInformation: { rowsPerPage, currentPage },
      } = thunkApi.getState();
      thunkApi.dispatch(updateCurrentFilter(deviceFilter));
      thunkApi.dispatch(updateLastTableFetch(currentPage, rowsPerPage));
      return await fetchDeviceInformation(
        deviceFilter,
        rowsPerPage,
        currentPage
      );
    } catch (err) {
      thunkApi.dispatch(error(err.message, "Loading devices"));
      return thunkApi.rejectWithValue(err.message);
    }
  },
  {
    condition: (newFilter, { getState }) => {
      // Only fetch if the filters have changed, prevents multiple fetches for the same data
      // also fetch if the currentPage or rowsPerPage have changed
      const {
        deviceInformation: {
          currentFilter,
          rowsPerPage,
          currentPage,
          lastRowsPerPageFetched,
          lastPageFetched,
        },
      } = getState();
      if (
        deepEqual(newFilter, currentFilter) &&
        rowsPerPage !== undefined &&
        rowsPerPage === lastRowsPerPageFetched &&
        currentPage !== undefined &&
        currentPage === lastPageFetched
      ) {
        return false;
      }
    },
  }
);

export const updateCurrentPage = createAsyncThunk(
  "deviceInformation/updateCurrentPage",
  async (pageNumber, thunkApi) => {
    // The pageNumber update will be synchronous so will be available for the next dispatch
    thunkApi.dispatch(setCurrentPage(pageNumber));
    const { deviceFilter } = thunkApi.getState();
    thunkApi.dispatch(fetchDeviceList(deviceFilter));
  }
);

export const updateRowsPerPage = createAsyncThunk(
  "deviceInformation/updateRowsPerPage",
  async (rowsPerPage, thunkApi) => {
    thunkApi.dispatch(setRowsPerPage(rowsPerPage));
    const { deviceFilter } = thunkApi.getState();
    thunkApi.dispatch(fetchDeviceList(deviceFilter));
  }
);

export const deviceInformationSlice = createSlice({
  name: "deviceInformation",
  initialState,
  reducers: {
    setCurrentPage: {
      reducer: (state, action) => {
        state.currentPage = action.payload.currentPage;
      },
      prepare: (pageNumber) => {
        return { payload: { currentPage: pageNumber } };
      },
    },
    setRowsPerPage: {
      reducer: (state, action) => {
        state.rowsPerPage = action.payload.rowsPerPage;
        state.currentPage = 0;
      },
      prepare: (rowsPerPage) => {
        return { payload: { rowsPerPage } };
      },
    },
    updateCurrentFilter(state, action) {
      state.currentFilter = action.payload;
    },
    updateLastTableFetch: {
      reducer: (state, action) => {
        state.lastPageFetched = action.payload.pageNumber;
        state.lastRowsPerPageFetched = action.payload.rowsPerPage;
      },
      prepare: (pageNumber, rowsPerPage) => {
        return { payload: { pageNumber, rowsPerPage } };
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeviceList.pending, (state) => {
        state.isFetchingDeviceList = true;
      })
      .addCase(fetchDeviceList.fulfilled, (state, action) => {
        state.isFetchingDeviceList = false;
        state.deviceList = action.payload.deviceList;
        state.totalDeviceCount = action.payload.rowCount;
      })
      .addCase(fetchDeviceList.rejected, (state) => {
        state.isFetchingDeviceList = false;
        state.deviceList = undefined;
        state.currentFilter = undefined;
      });
  },
});

export const {
  setCurrentPage,
  setRowsPerPage,
  updateCurrentFilter,
  updateLastTableFetch,
} = deviceInformationSlice.actions;

export const selectDeviceList = (state) => state.deviceInformation.deviceList;
export const selectDeviceRowCount = (state) =>
  state.deviceInformation.totalDeviceCount;
export const selectRowsPerPage = (state) => state.deviceInformation.rowsPerPage;
export const selectCurrentPage = (state) => state.deviceInformation.currentPage;
export const selectIsFetchingDeviceList = (state) =>
  state.deviceInformation.isFetchingDeviceList;

export default deviceInformationSlice.reducer;
