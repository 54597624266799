import { Auth, Hub } from "aws-amplify";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLookupValues } from "../../redux/lookup/lookupSlice";
import { signIn } from "../../redux/user/AmplifySlice";
import {
  fetchUserConfig,
  selectUserConfig,
} from "../../redux/userConfig/ConfigSlice";

export const handleLogout = async () => {
  await Auth.signOut();
};

const updateCurrentUser = async (dispatch) => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    const user = {
      attributes: currentUser.attributes,
    };
    await dispatch({
      type: "RESET",
    });
    await dispatch(signIn(user));
  } catch (err) {
    // Do nothing, is thrown when user is not authenticated
  }
};

const AmplifyHubBridge = () => {
  const dispatch = useDispatch();

  const userConfig = useSelector(selectUserConfig);
  useEffect(() => {
    if (!userConfig) {
      dispatch(fetchUserConfig());
      dispatch(fetchLookupValues());
    }
  }, [userConfig]);

  const listener = async (data) => {
    switch (data.payload.event) {
      case "signIn": {
        dispatch({
          type: "RESET",
        });
        // No need to update the user
        // the RESET will clear the state and
        // the rerender will update the user.
        break;
      }
      case "signOut":
      case "tokenRefresh_failure":
        dispatch({
          type: "RESET",
        });
        localStorage.clear();
        window.location = "/";
        break;
    }
  };

  useEffect(() => {
    Hub.listen("auth", listener);
    return () => Hub.remove("auth", listener);
  });

  return <div data-testid="amplify-hub-bridge" />;
};

export default AmplifyHubBridge;
