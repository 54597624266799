import PropType from "prop-types";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import moment from "moment";

const HEADER_CELL_COLOR = "rgba(234, 234, 234, 1)";

const DeviceDetailTable = ({
  userConfig: {
    primaryGlobalVendorExtensionName,
    primaryRequestVendorExtensionName,
  },
  device: {
    serialNumber,
    primaryGlobalVendorExtension,
    primaryRequestVendorExtension,
    requestId,
    certification,
    expiresDatetime,
    uncertaintyArea,
    height,
    verticalUncertainty,
    incumbentsQueried,
    requests,
    latitude,
    longitude,
  },
}) => {
  const headerCellStyle = {
    backgroundColor: HEADER_CELL_COLOR,
  };

  const expires = moment
    .utc(expiresDatetime)
    .format("MMM D, YYYY [at] HH:mm:SS [UTC]");

  return (
    <Box>
      <Box>
        <TableContainer sx={{ padding: "1rem" }}>
          <Table data-testid="component-device-detail-table">
            <TableBody>
              <TableRow>
                <TableCell variant="head" style={headerCellStyle} width="20%">
                  Serial Number
                </TableCell>
                <TableCell>{serialNumber}</TableCell>
              </TableRow>
              {primaryGlobalVendorExtensionName && (
                <TableRow>
                  <TableCell variant="head" style={headerCellStyle} width="20%">
                    {primaryGlobalVendorExtensionName}
                  </TableCell>
                  <TableCell>{primaryGlobalVendorExtension}</TableCell>
                </TableRow>
              )}
              {primaryRequestVendorExtensionName && (
                <TableRow>
                  <TableCell variant="head" style={headerCellStyle} width="20%">
                    {primaryRequestVendorExtensionName}
                  </TableCell>
                  <TableCell>{primaryRequestVendorExtension}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell variant="head" style={headerCellStyle} width="20%">
                  Request ID
                </TableCell>
                <TableCell>{requestId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" style={headerCellStyle} width="20%">
                  Certification ID
                </TableCell>
                <TableCell>{certification}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" style={headerCellStyle} width="20%">
                  Expire Time
                </TableCell>
                <TableCell>{expires}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" style={headerCellStyle} width="20%">
                  Uncertainty Area
                </TableCell>
                <TableCell>{`${uncertaintyArea?.toFixed(2)}m²`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" style={headerCellStyle} width="20%">
                  Latitude / Longitude
                </TableCell>
                <TableCell>{`${latitude} / ${longitude}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" style={headerCellStyle} width="20%">
                  Height
                </TableCell>
                <TableCell>{`${height?.toFixed(
                  2
                )}m +/- ${verticalUncertainty?.toFixed(2)}m`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" style={headerCellStyle} width="20%">
                  Incumbents Queried
                </TableCell>
                <TableCell>{incumbentsQueried ?? ""}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" style={headerCellStyle} width="20%">
                  Requests Today
                </TableCell>
                <TableCell>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {requests &&
                          requests.map((request) => (
                            <TableRow key={request.requestId}>
                              <TableCell>RID: {request.requestId}</TableCell>
                              <TableCell>
                                TS:{" "}
                                {moment
                                  .utc(request.expiresDatetime)
                                  .subtract(1, "day")
                                  .format("MMM D, YYYY [at] HH:mm:SS [UTC]")}
                              </TableCell>
                              <TableCell>
                                Latency: {request.latency}ms
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

DeviceDetailTable.propTypes = {
  userConfig: PropType.shape({
    primaryGlobalVendorExtensionName: PropType.string,
    primaryRequestVendorExtensionName: PropType.string,
  }).isRequired,
  device: PropType.shape({
    serialNumber: PropType.string.isRequired,
    primaryGlobalVendorExtension: PropType.string,
    primaryRequestVendorExtension: PropType.string,
    requestId: PropType.string.isRequired,
    certification: PropType.string.isRequired,
    expiresDatetime: PropType.string.isRequired,
    uncertaintyArea: PropType.number.isRequired,
    height: PropType.number.isRequired,
    verticalUncertainty: PropType.number.isRequired,
    incumbentsQueried: PropType.number.isRequired,
    requests: PropType.array,
    latitude: PropType.number,
    longitude: PropType.number,
  }).isRequired,
};

export default DeviceDetailTable;
