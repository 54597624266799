import PropType from "prop-types";
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMemo } from "react";
import { useTable } from "react-table";

const DeviceIncumbentTable = ({ incumbentList, showLoading }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Callsign",
        accessor: "callsign",
      },
      {
        Header: "Center Frequency",
        accessor: "frequency",
      },
      {
        Header: "Bandwidth",
        accessor: "bandwidth",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: incumbentList.map((incumbent) => ({
        ...incumbent.properties,
        color: incumbent.color,
      })),
    });

  return (
    <Box sx={{ width: 1, height: 1, overflow: "auto" }}>
      <TableContainer>
        {showLoading && (
          <Skeleton
            sx={{ paddingTop: "16px" }}
            variant="rectangular"
            height={1500}
          />
        )}
        {!showLoading && (
          <Table
            {...getTableProps()}
            data-testid="device-incumbents-table"
            stickyHeader
          >
            <TableHead>
              {headerGroups.map((hg, hgIdx) => (
                <TableRow key={hgIdx} {...hg.getHeaderGroupProps()}>
                  {hg.headers.map((col) => (
                    <TableCell key={col.id}>{col.render("Header")}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);

                return (
                  <TableRow
                    key={`${row.original.callsign}-${row.original.frequency}`}
                    hover
                    {...row.getRowProps({
                      style: {
                        backgroundColor: row.original.color,
                      },
                    })}
                  >
                    {row.cells.map((cell, cellIndex) => (
                      <TableCell
                        key={`${row.original.callsign}-${row.original.frequency}-${cellIndex}`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Box>
  );
};

DeviceIncumbentTable.propTypes = {
  incumbentList: PropType.array.isRequired,
  showLoading: PropType.bool,
};

export default DeviceIncumbentTable;
