import axios from "axios";

export async function fetchIncumbentClusters(incumbentFilter) {
  const response = await axios.post(
    `${window.config.API_ROOT}/incumbentCluster`,
    incumbentFilter,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return response.data;
}
