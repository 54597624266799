import PropType from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import NetworkPingIcon from "@mui/icons-material/NetworkPing";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RequestsPerDayChart from "./RequestsPerDayChart";
import Filter from "./Filter";
import {
  selectHistoricalDeviceKpi,
  selectHistoricalDeviceRequests,
  selectHistoricalDeviceStatus,
  updateHistoricalDeviceKPI,
} from "../../../../redux/deviceKpi/deviceKpiSlice";
import ErrorChart from "./ErrorChart";
import KpiTable from "./KpiTable";
import { errorTypes } from "./errorTypes";
import {
  updateDeviceLatencyReport,
  selectIsFetchingLatencyReport,
  selectHasReport,
  selectReport,
  clear,
  selectReportName,
} from "../../../../redux/deviceLatencyReport/deviceLatencyReportSlice";
import { selectDeviceDetail } from "../../../../redux/deviceDetail/deviceDetailSlice";
import RequestsPerDayChartSkeleton from "./RequestsPerDayChartSkeleton";
import KpiTableSkeleton from "./KpiTableSkeleton";

export const viewType = {
  REQUESTS: "REQUESTS",
  KPI: "KPI",
};

const RequestsPerDayContainer = ({ device, show }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [selectedView, setSelectedView] = useState(viewType.REQUESTS);

  useEffect(() => {
    if (device?.serialNumber) {
      dispatch(
        updateHistoricalDeviceKPI({
          ...filter,
          serialNumber: device?.serialNumber,
        })
      );
      dispatch(clear());
    }
  }, [device, filter]);

  const {
    isFetchingDeviceKpi: loading,
    error,
    emptyRequests,
    emptyKpi,
  } = useSelector(selectHistoricalDeviceStatus);
  const kpi = useSelector(selectHistoricalDeviceKpi);
  const requests = useSelector(selectHistoricalDeviceRequests);
  const requestsLoaded = !loading && requests && requests?.length > 0;
  const kpiLoaded = !loading && kpi && kpi?.length > 0;

  const isLoadingReport = useSelector(selectIsFetchingLatencyReport);
  const hasLatencyReport = useSelector(selectHasReport);
  const latencyReport = useSelector(selectReport);
  const latencyReportName = useSelector(selectReportName);
  const isReportEmpty = latencyReport === "";
  const deviceDetail = useSelector(selectDeviceDetail);

  const triggerReportGeneration = () => {
    dispatch(
      updateDeviceLatencyReport({
        serialNumber: deviceDetail.current.serialNumber,
        customerId: deviceDetail.current.customerId,
        organizationId: deviceDetail.current.organizationId,
      })
    );
  };

  const handleViewChange = (_, newViewType) => {
    // If the already selected view is clicked null will be passed
    if (newViewType) {
      setSelectedView(newViewType);
    }
  };

  const kpiRows = useMemo(() => {
    if (kpi) {
      const errorKeys = Object.keys(errorTypes);
      const initial = {};
      errorKeys.forEach((key) => {
        initial[key] = {
          eventName: errorTypes[key].label,
          count: 0,
        };
      });
      const counts = kpi.reduce((accum, next) => {
        errorKeys.forEach((key) => {
          if (next[key]) {
            accum[key].count += next[key];
          }
        });
        return accum;
      }, initial);
      return Object.values(counts);
    }
    return null;
  }, [kpi]);

  if (!show) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: 1,
        maxHeight: 1,
        overflow: "hidden",
        paddingBottom: "16px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Filter
          currentFilter={filter}
          onChange={(newFilter) => setFilter(newFilter)}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!hasLatencyReport && !isLoadingReport && (
            <Button onClick={triggerReportGeneration}>
              Generate Latency Report
            </Button>
          )}
          {isLoadingReport && (
            <Button disabled>
              <CircularProgress size={"1.4em"} color={"inherit"} />
              Generating Latency Report
            </Button>
          )}
          {hasLatencyReport && !isLoadingReport && !isReportEmpty && (
            <Button>
              <a
                href={`data:text/csv;charset=utf-8,${encodeURIComponent(
                  latencyReport
                )}`}
                target="_blank"
                download={latencyReportName}
                rel="noreferrer"
              >
                Download Latency Report
              </a>
            </Button>
          )}
          {hasLatencyReport && isReportEmpty && (
            <Button disabled>Empty Latency Report</Button>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tooltip title="Requests or KPI">
            <ToggleButtonGroup
              size="small"
              exclusive
              onChange={handleViewChange}
              value={selectedView}
              sx={{ maxHeight: "38px" }}
            >
              <ToggleButton value={viewType.REQUESTS}>
                <NetworkPingIcon />
              </ToggleButton>
              <ToggleButton value={viewType.KPI}>
                <ShowChartIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, padding: "16px", minHeight: 0 }}>
        <Box sx={{ height: 0.5, maxHeight: 0.5 }}>
          {requestsLoaded && selectedView == viewType.REQUESTS && (
            <RequestsPerDayChart dailyRequestCounts={requests} />
          )}
          {!requestsLoaded && selectedView == viewType.REQUESTS && (
            <RequestsPerDayChartSkeleton error={error} empty={emptyRequests} />
          )}
          {kpiLoaded && selectedView == viewType.KPI && (
            <ErrorChart data={kpi} />
          )}
        </Box>
        <Box
          sx={{
            height: 0.5,
            maxHeight: 0.5,
            marginTop: "16px",
          }}
        >
          {kpiLoaded && <KpiTable rows={kpiRows} />}
          {!kpiLoaded && <KpiTableSkeleton error={error} empty={emptyKpi} />}
        </Box>
      </Box>
    </Box>
  );
};

RequestsPerDayContainer.propTypes = {
  device: PropType.shape({
    id: PropType.number,
    organizationId: PropType.number,
    serialNumber: PropType.string,
  }),
  show: PropType.bool.isRequired,
};

export default RequestsPerDayContainer;
