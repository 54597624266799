export const makeDisplayTypes = (displayConfig) =>
  Object.entries(displayConfig).reduce(
    (output, curr) => ({
      ...output,
      [curr[0]]: {
        description: curr[1].description,
        color: curr[1].color,
      },
    }),
    {}
  );

export const toChartDescription = ({ stopLight }, descriptionTypes) => {
  if (stopLight in descriptionTypes) {
    return descriptionTypes[stopLight].description;
  }
  return undefined;
};

export const toChartColor = ({ stopLight }, colorTypes) => {
  if (stopLight in colorTypes) {
    return colorTypes[stopLight].color;
  }
  return undefined;
};
