import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchHistoricalDeviceDailyRequests,
  fetchHistoricalDeviceKPI,
} from "./deviceKpiAPI";

export const initialState = {
  isFetchingDeviceKpi: false,
  requests: undefined,
  kpi: undefined,
};

export const updateHistoricalDeviceKPI = createAsyncThunk(
  "deviceKpi/fetchAll",
  async (filter, thunkApi) => {
    try {
      const { startDate, endDate, intervalType, serialNumber } = filter;
      const requests = await fetchHistoricalDeviceDailyRequests(
        startDate,
        intervalType,
        serialNumber
      );
      const kpi = await fetchHistoricalDeviceKPI(
        startDate,
        endDate,
        intervalType,
        serialNumber
      );
      return {
        requests,
        kpi,
      };
    } catch (err) {
      if (err.response?.status == 404) {
        return {
          requests: [],
          kpi: [],
        };
      }
      // Not dispatching notification - errors will be shown in dialog
      // thunkApi.dispatch(error(err?.message, "Loading device KPI"));
      return thunkApi.rejectWithValue(err?.message);
    }
  }
);

export const deviceKpiSlice = createSlice({
  name: "deviceKpi",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(updateHistoricalDeviceKPI.pending, (state) => {
        state.isFetchingDeviceKpi = true;
        state.error = null;
        state.emptyRequests = false;
        state.emptyKpi = false;
      })
      .addCase(updateHistoricalDeviceKPI.fulfilled, (state, action) => {
        state.isFetchingDeviceKpi = false;
        if (!action.payload.requests?.length) {
          state.emptyRequests = true;
        }
        if (!action.payload.kpi?.length) {
          state.emptyKpi = true;
        }
        state.requests = action.payload.requests;
        state.kpi = action.payload.kpi;
      })
      .addCase(
        updateHistoricalDeviceKPI.rejected,
        (state, { payload, error }) => {
          state.isFetchingDeviceKpi = false;
          state.requests = undefined;
          state.kpi = undefined;
          state.error = payload || error.message;
        }
      );
  },
});

export const selectHistoricalDeviceKpi = (state) => state.deviceKpi.kpi;
export const selectHistoricalDeviceRequests = (state) =>
  state.deviceKpi.requests;

export const selectHistoricalDeviceStatus = (state) => state.deviceKpi;
export default deviceKpiSlice.reducer;
