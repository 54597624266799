import Chart from "react-google-charts";
import PropType from "prop-types";
import { useMemo } from "react";
import ChartError, { ChartLoading } from "../../ChartError";
import { Typography } from "@mui/material";

const hAxisTitle = "channelCfi";
const vAxisTitle = "Average maxEIRP (dBm)";

export function transformData(maxEirpRows, selectedOperatingClass) {
  const filteredRows = maxEirpRows.filter(
    (row) => row.operatingClass === selectedOperatingClass
  );

  // Get a distinct list of the extension types in the dataset
  const extensionTypes = [];
  filteredRows.forEach((row) => {
    if (row.extension && !extensionTypes.includes(row.extension)) {
      extensionTypes.push(row.extension);
    }
  });
  extensionTypes.sort();

  // If we have no extension types we are generating a single line pm the chart
  if (extensionTypes.length > 0) {
    return transformDataWithExtensions(filteredRows, extensionTypes);
  }
  return transformDataWithoutExtensions(filteredRows);
}

export function transformDataWithExtensions(filteredRows, extensionTypes) {
  const descriptions = [hAxisTitle];
  extensionTypes.forEach((type) => {
    descriptions.push({ label: type, type: "number" });
  });

  const mappedData = {};
  const channelValues = [];
  filteredRows.forEach((row) => {
    if (!channelValues.includes(row.channel)) {
      channelValues.push(row.channel);
    }

    let channelValue = mappedData[row.channel];
    if (!channelValue) {
      channelValue = mappedData[row.channel] = { channel: row.channel };
    }
    channelValue[row.extension] = row.average;
  });
  channelValues.sort((a, b) => a - b);

  const chartData = [];
  chartData.push(descriptions);
  channelValues.forEach((channel) => {
    const row = [`${channel}`];
    extensionTypes.forEach((type) => {
      row.push(mappedData[channel][type]);
    });
    chartData.push(row);
  });
  return chartData;
}

export function transformDataWithoutExtensions(filteredRows) {
  const descriptions = [hAxisTitle, "maxEIRP"];
  const chartData = [];
  chartData.push(descriptions);
  filteredRows.forEach((row) => {
    chartData.push([`${row.channel}`, row.average]);
  });
  chartData.sort((a, b) => a[0] - b[0]);

  return chartData;
}

const MaxEirpPerChannelChart = ({
  maxEirpRows,
  selectedOperatingClass,
  lineChart,
  showLegend,
}) => {
  const data = useMemo(() => {
    return transformData(maxEirpRows, selectedOperatingClass);
  }, [maxEirpRows, selectedOperatingClass]);

  const options = {
    hAxis: {
      title: hAxisTitle,
    },
    vAxis: {
      title: vAxisTitle,
      viewWindow: {
        min: 11,
        max: 37,
      },
    },
    focusTarget: "category",
    theme: "material",
    legend: {
      position: "none",
    },
  };

  if (showLegend) {
    options.legend.position = "right";
    options.chartArea = { width: "60%" };
  }

  let chartType = "ColumnChart";
  if (lineChart) {
    chartType = "LineChart";
  }

  return (
    <Chart
      chartType={chartType}
      width="100%"
      height="100%"
      data={data}
      options={options}
      rootProps={{ "data-testid": "max-eirp-per-channel-chart" }}
      errorElement={<ChartError />}
      loader={<ChartLoading />}
    />
  );
};

MaxEirpPerChannelChart.propTypes = {
  maxEirpRows: PropType.arrayOf(
    PropType.shape({
      maxEIRP: PropType.number,
      channel: PropType.number,
      extension: PropType.string,
      operatingClass: PropType.number,
    })
  ).isRequired,
  selectedOperatingClass: PropType.number.isRequired,
  lineChart: PropType.bool,
  showLegend: PropType.bool,
};

export default MaxEirpPerChannelChart;
