import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import PropType from "prop-types";
import BarChartIcon from "@mui/icons-material/BarChart";
import ShowChartIcon from "@mui/icons-material/ShowChart";

export const chartType = {
  COLUMN: "column",
  LINE: "line",
};

const ChartTypeSelector = ({ selectedChartType, onChange }) => {
  const handleChange = (_, newSelection) => {
    if (newSelection) {
      onChange(newSelection);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "10px",
      }}
    >
      <Tooltip title="Chart type">
        <ToggleButtonGroup
          size="small"
          exclusive
          onChange={handleChange}
          value={selectedChartType}
        >
          <ToggleButton data-testid="column-chart-btn" value={chartType.COLUMN}>
            <BarChartIcon />
          </ToggleButton>
          <ToggleButton data-testid="line-chart-btn" value={chartType.LINE}>
            <ShowChartIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Tooltip>
    </Box>
  );
};

ChartTypeSelector.propTypes = {
  selectedChartType: PropType.string.isRequired,
  onChange: PropType.func.isRequired,
};

export default ChartTypeSelector;
