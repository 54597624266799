import { Box, Paper } from "@mui/material";
import PropType from "prop-types";
import Chart from "react-google-charts";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import ChartError, { ChartLoading } from "../../../ChartError";

const RequestsPerDayChart = ({ dailyRequestCounts }) => {
  // The google chart does not correctly resize on window resize
  // this will force a rerender of the chart using a key attribute.
  const [chartKey, setChartKey] = useState(0);
  const handleResize = () => {
    setChartKey((prev) => prev + 1);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const chartData = useMemo(() => {
    const dataArray = [["Date", "Number of Requests"]];
    dailyRequestCounts?.forEach((request) => {
      dataArray.push([moment(request.date).utc(false).toDate(), request.count]);
    });
    return dataArray;
  }, [dailyRequestCounts]);

  return (
    <Paper
      elevation={3}
      sx={{
        width: 1,
        height: 1,
      }}
      data-testid="kpi-chart"
    >
      {dailyRequestCounts && dailyRequestCounts.length > 0 && (
        <Chart
          key={chartKey}
          chartType="AreaChart"
          height="100%"
          width="100%"
          data={chartData}
          options={{
            title: "Daily Number of Requests",
            vAxis: {
              minValue: 0,
            },
            legend: { position: "none" },
          }}
          errorElement={<ChartError />}
          loader={<ChartLoading />}
        />
      )}
    </Paper>
  );
};

RequestsPerDayChart.propTypes = {
  dailyRequestCounts: PropType.arrayOf(
    PropType.shape({
      count: PropType.number,
      date: PropType.string,
    })
  ),
};

export default RequestsPerDayChart;
