import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { HIST_DATE_FORMAT } from "../../util/constants";

import { fetchHistoricalDeviceSummaryEIRPById } from "./historicalDeviceSummaryEirpAPI";

export const intervalType = {
  HOURLY: "HOURLY",
  DAILY: "DAILY",
  MONTHLY: "MONTHLY",
  MTD: "MTD",
  YTD: "YTD",
};

export const initialState = {
  loading: false,
};

const errorMessage = (err) => {
  if (err?.response?.status === 404) {
    return "The historical EIRP data could not be found";
  }
  return err?.message;
};

export const fetchHistoricalDeviceSummaryEIRP = createAsyncThunk(
  "fetchHistoricalDeviceSummaryEIRP/fetch",
  async ({ serialNumber, intervalType, startDate, maxEIRP }, thunkApi) => {
    try {
      const localDate = moment(startDate).utc(true).format(HIST_DATE_FORMAT);
      const summary = await fetchHistoricalDeviceSummaryEIRPById(
        serialNumber,
        intervalType,
        localDate,
        maxEIRP
      );
      return summary;
    } catch (err) {
      if (err.response?.status == 404) {
        return [];
      }
      // Not dispatching notification - errors will be shown in dialog
      return thunkApi.rejectWithValue(errorMessage(err));
    }
  }
);

export const historicalDeviceSummaryEIRPSlice = createSlice({
  name: "historicalDeviceSummaryEIRP",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchHistoricalDeviceSummaryEIRP.pending, (state) => {
        state.loading = true;
        state.current = null;
        state.error = null;
        state.empty = false;
      })
      .addCase(
        fetchHistoricalDeviceSummaryEIRP.fulfilled,
        (state, { payload }) => {
          if (!payload?.length) {
            state.empty = true;
          } else {
            state.current = payload;
          }
          state.loading = false;
        }
      )
      .addCase(
        fetchHistoricalDeviceSummaryEIRP.rejected,
        (state, { payload, error }) => {
          state.loading = false;
          state.error = payload || error.message;
        }
      );
  },
});

export const selectHistoricalDeviceSummaryEIRP = (state) =>
  state.historicalDeviceSummaryEIRP;

export default historicalDeviceSummaryEIRPSlice.reducer;
