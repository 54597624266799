import { useEffect, useMemo, useState } from "react";
import PropType from "prop-types";

import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";

import intervalTypes from "../../../../util/intervalTypes";
import { MAX_PSD_DBM_HZ, MIN_PSD_DBM_HZ } from "./util";

import { HIST_DATE_FORMAT } from "../../../../util/constants";
import moment from "moment";
import { useDebouncedCallback } from "use-debounce";
import { TextField } from "@mui/material";
import validate from "./validation";

const HistoricalDeviceSummaryPsdFilter = ({ readonly, onChange }) => {
  const initialDate = useMemo(() =>
    window.config.DATE_OVERRIDE
      ? moment(window.config.DATE_OVERRIDE, "YYYY-MM-DD")
      : moment()
  );

  const [filter, setFilter] = useState(() => {
    const startDate = moment(initialDate)
      .utc(true)
      .startOf("day")
      .subtract(30, "day")
      .format(HIST_DATE_FORMAT);
    return {
      intervalType: intervalTypes.DAILY,
      startDate,
      maxPSD: "18",
      maxStartDate: startDate,
      errors: {},
    };
  });

  const handleFilterChange = (values) => {
    onChange({
      maxPSD: values.maxPSD,
      startDate: values.startDate,
      intervalType: values.intervalType.id,
    });
  };

  useEffect(() => {
    handleFilterChange(filter);
  }, []);

  const handleFilterChangeDebounced = useDebouncedCallback(() => {
    handleFilterChange(filter);
  }, 500);

  const handlePsdChange = (newValue) => {
    let newState;
    setFilter((prev) => {
      newState = validate({
        ...prev,
        maxPSD: newValue,
      });
      return newState;
    });
    handleFilterChangeDebounced();
  };

  const handleIntervalChange = (intervalType) => {
    let newStartDate;
    let selectedIntervalType;
    if (intervalType === intervalTypes.DAILY.id) {
      selectedIntervalType = intervalTypes.DAILY;
      newStartDate = moment(initialDate)
        .startOf("day")
        .subtract(30, "days")
        .format(HIST_DATE_FORMAT);
    }

    if (intervalType === intervalTypes.MONTHLY.id) {
      selectedIntervalType = intervalTypes.MONTHLY;
      newStartDate = moment(initialDate)
        .subtract(11, "months")
        .startOf("month")
        .format(HIST_DATE_FORMAT);
    }

    if (intervalType === intervalTypes.MTD.id) {
      selectedIntervalType = intervalTypes.MTD;
      newStartDate = moment(initialDate)
        .startOf("month")
        .format(HIST_DATE_FORMAT);
    }

    if (intervalType === intervalTypes.YTD.id) {
      selectedIntervalType = intervalTypes.YTD;
      newStartDate = moment(initialDate)
        .startOf("year")
        .format(HIST_DATE_FORMAT);
    }

    let newState;
    setFilter((prev) => {
      newState = validate({
        ...prev,
        startDate: newStartDate,
        maxStartDate: newStartDate,
        intervalType: selectedIntervalType,
      });
      return newState;
    });
    handleFilterChangeDebounced();
  };

  const handleDateChange = (newValue) => {
    let newState;
    let newStartDate = newValue;
    setFilter((prev) => {
      if (newValue === undefined || !moment(newValue).isValid()) {
        newStartDate = prev.maxStartDate;
      } else if (moment(newValue).isAfter(moment(prev.maxStartDate))) {
        newStartDate = prev.maxStartDate;
      }

      if (prev.intervalType.id === intervalTypes.MONTHLY.id) {
        newStartDate = moment(newValue)
          .startOf("month")
          .format(HIST_DATE_FORMAT);
      }

      newState = validate({
        ...prev,
        startDate: newStartDate,
      });
      return newState;
    });
    handleFilterChangeDebounced();
  };

  const disableDateSelection = filter.intervalType?.startDateIgnored;

  return (
    <Grid container columnSpacing={2} sx={{ pt: "1rem" }}>
      <Grid item xs={2}>
        <TextField
          name="maxPSD"
          type="number"
          label="Target PSD (dBm/Hz)"
          inputProps={{
            min: MIN_PSD_DBM_HZ,
            max: MAX_PSD_DBM_HZ,
            step: 0.01,
          }}
          disabled={readonly}
          onChange={(event) => handlePsdChange(event.target.value)}
          value={filter.maxPSD}
          error={!!filter.errors.maxPSD}
          helperText={filter.errors.maxPSD}
          sx={{ width: 1 }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          name="intervalType"
          label="Interval"
          select
          disabled={readonly}
          data-testid="intervalType"
          onChange={(event) => handleIntervalChange(event.target.value)}
          value={filter.intervalType.id}
          sx={{ width: 1 }}
        >
          {Object.entries(intervalTypes).map(([k, v]) => (
            <MenuItem key={k} value={v.id}>
              {v.description}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <TextField
          name="startDate"
          type="date"
          label="Start Date"
          disabled={readonly || disableDateSelection}
          onChange={(event) => handleDateChange(event.target.value)}
          value={filter.startDate}
          inputProps={{
            max: filter.maxStartDate,
          }}
          error={filter.errors.startDate}
          helperText={filter.errors.startDate}
          sx={{ width: 1 }}
        />
      </Grid>
    </Grid>
  );
};

HistoricalDeviceSummaryPsdFilter.propTypes = {
  readonly: PropType.bool.isRequired,
  onChange: PropType.func.isRequired,
};

export default HistoricalDeviceSummaryPsdFilter;
