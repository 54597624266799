import PropType from "prop-types";
import { Chip } from "@mui/material";

const GlobalOperatingClassChip = ({ deviceFilter, onDelete }) => {
  const { globalOperatingClass } = deviceFilter;
  // Do not render the chip if the filter is not set
  if (globalOperatingClass === undefined) {
    return null;
  }

  return (
    <Chip
      onDelete={onDelete}
      label={`Global operating class  [${globalOperatingClass.join(", ")}]`}
      data-testid="global-class-chip"
    />
  );
};

GlobalOperatingClassChip.propTypes = {
  deviceFilter: PropType.shape({
    globalOperatingClass: PropType.arrayOf(PropType.number),
  }),
  onDelete: PropType.func,
};

export default GlobalOperatingClassChip;
