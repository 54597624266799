import PropType from "prop-types";
import {
  Box,
  Dialog,
  IconButton,
  ListItem,
  ListItemText,
  Skeleton,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FlashlightOnIcon from "@mui/icons-material/FlashlightOn";
import FlashlightOffIcon from "@mui/icons-material/FlashlightOff";

const IncumbentDialog = ({
  onClose,
  incumbentDetailList = [],
  numberOfIncumbents,
  onShowContour,
  onHideContour,
  onToggleHighlight,
}) => {
  const listItems = [];
  for (let index = 0; index < numberOfIncumbents; index++) {
    if (index >= incumbentDetailList.length) {
      listItems.push(
        <ListItem key={`loading-${index}`}>
          <Skeleton
            sx={{ width: 1, height: 1 }}
            data-testid="incumbent-loading"
          />
        </ListItem>
      );
    } else {
      const { callsign, frequency, bandwidth, id } =
        incumbentDetailList[index].properties;
      const { isVisible, isHighlighted = false } = incumbentDetailList[index];
      listItems.push(
        <Box
          sx={{ display: "flex", flexDirection: "column" }}
          key={`box-${id}`}
        >
          <ListItem key={`item-${id}`}>
            <ListItemText
              primary={callsign}
              secondary={`Center Frequency: ${frequency}  Bandwidth: ${bandwidth}`}
            />
            {!isVisible && (
              <Tooltip title="Show contour">
                <IconButton onClick={() => onShowContour(id)}>
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            )}
            {isVisible && (
              <Tooltip title="Hide contour">
                <IconButton onClick={() => onHideContour(id)}>
                  <VisibilityOffIcon />
                </IconButton>
              </Tooltip>
            )}
            {(!isHighlighted || !isVisible) && (
              <Tooltip title="Highlight contour">
                <span>
                  <IconButton
                    onClick={() => onToggleHighlight(id)}
                    disabled={!isVisible}
                  >
                    <FlashlightOnIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {isHighlighted && (
              <Tooltip title="Turn off contour highlight">
                <IconButton onClick={() => onToggleHighlight(id)}>
                  <FlashlightOffIcon />
                </IconButton>
              </Tooltip>
            )}
          </ListItem>
        </Box>
      );
    }
  }
  return (
    <Dialog open={true} onClose={onClose}>
      <Box
        sx={{ display: "flex", flexDirection: "column", minWidth: "280px" }}
        data-testid="incumbent-dialog"
      >
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {listItems}
      </Box>
    </Dialog>
  );
};

IncumbentDialog.propTypes = {
  onClose: PropType.func.isRequired,
  incumbentDetailList: PropType.array,
  numberOfIncumbents: PropType.number,
  onShowContour: PropType.func.isRequired,
  onHideContour: PropType.func.isRequired,
  onToggleHighlight: PropType.func.isRequired,
};

export default IncumbentDialog;
