import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchMaxEirpPerChannel as fetchMaxEirpPerChannelApi } from "./maxEirpPerChannelAPI";
import { error } from "../notification/notificationSlice";
import { deepEqual } from "fast-equals";

export const initialState = {
  isFetchingMaxEirpPerChannel: false,
  selectedOperatingClass: undefined,
  selectedChartType: "line",
  operatingClasses: [],
  resultList: [],
  currentFilter: undefined,
};

export const fetchMaxEirpPerChannel = createAsyncThunk(
  "maxEirpPerChannel/fetch",
  async (deviceFilter, thunkApi) => {
    try {
      thunkApi.dispatch(updateCurrentFilter(deviceFilter));
      return await fetchMaxEirpPerChannelApi(deviceFilter);
    } catch (err) {
      thunkApi.dispatch(error(err?.message, "Loading EIRP per channel data"));
      return thunkApi.rejectWithValue(err?.message);
    }
  },
  {
    condition: (newFilter, { getState }) => {
      const {
        maxEirpPerChannel: { currentFilter },
      } = getState();
      if (deepEqual(currentFilter, newFilter)) {
        return false;
      }
      return true;
    },
  }
);

// Maps a channel to the global operating class
export const channelMap = {
  1: 131,
  5: 131,
  9: 131,
  13: 131,
  17: 131,
  21: 131,
  25: 131,
  29: 131,
  33: 131,
  37: 131,
  41: 131,
  45: 131,
  49: 131,
  53: 131,
  57: 131,
  61: 131,
  65: 131,
  69: 131,
  73: 131,
  77: 131,
  81: 131,
  85: 131,
  89: 131,
  93: 131,
  117: 131,
  121: 131,
  125: 131,
  129: 131,
  133: 131,
  137: 131,
  141: 131,
  145: 131,
  149: 131,
  153: 131,
  157: 131,
  161: 131,
  165: 131,
  169: 131,
  173: 131,
  181: 131,
  177: 131,
  3: 132,
  11: 132,
  19: 132,
  27: 132,
  35: 132,
  43: 132,
  51: 132,
  59: 132,
  67: 132,
  75: 132,
  83: 132,
  91: 132,
  123: 132,
  131: 132,
  139: 132,
  147: 132,
  155: 132,
  163: 132,
  171: 132,
  179: 132,
  7: 133,
  23: 133,
  39: 133,
  55: 133,
  71: 133,
  87: 133,
  135: 133,
  151: 133,
  167: 133,
  15: 134,
  47: 134,
  79: 134,
  143: 134,
  2: 136,
};

export function processResultList(resultList, state) {
  const operatingClasses = [];
  // Need to build the list of distinct operating classes after filtering out any null values
  // the list should only contain operating classes with non null values after that.
  resultList
    .filter((row) => row.average !== null)
    .forEach((row) => {
      const rowOperatingClass = channelMap[row.channel];
      if (!rowOperatingClass) {
        // invalid channel just ignore
        return;
      }

      if (!operatingClasses.includes(rowOperatingClass)) {
        operatingClasses.push(rowOperatingClass);
      }
    });

  // augment all of the results with their operating class
  resultList.forEach((row) => {
    const rowOperatingClass = channelMap[row.channel];
    if (!rowOperatingClass) {
      // invalid channel just ignore
      return;
    }
    row.operatingClass = rowOperatingClass;
  });
  // filter out any rows with no operating class
  state.resultList = resultList.filter((row) => row.operatingClass);

  operatingClasses.sort((a, b) => a - b);

  state.operatingClasses = operatingClasses;

  // If the selected operation class is no longer in our data set then
  // choose the first operating class as selected, if there are no data points set undefined
  if (!operatingClasses.includes(state.selectedOperatingClass)) {
    state.selectedOperatingClass = undefined;
  }

  if (!state.selectedOperatingClass && operatingClasses.length > 0) {
    state.selectedOperatingClass = operatingClasses[0];
  }
}

export const maxEirpPerChannelSlice = createSlice({
  name: "maxEirpPerChannel",
  initialState,
  reducers: {
    setSelectedOperatingClass: {
      reducer: (state, action) => {
        state.selectedOperatingClass = action.payload.operatingClass;
      },
      prepare: (operatingClass) => {
        return { payload: { operatingClass } };
      },
    },
    setSelectedChartType: {
      reducer: (state, action) => {
        state.selectedChartType = action.payload.chartType;
      },
      prepare: (chartType) => {
        return { payload: { chartType } };
      },
    },
    updateCurrentFilter(state, action) {
      state.currentFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMaxEirpPerChannel.pending, (state) => {
        state.isFetchingMaxEirpPerChannel = true;
      })
      .addCase(fetchMaxEirpPerChannel.fulfilled, (state, action) => {
        state.isFetchingMaxEirpPerChannel = false;

        // Will add the global operating class to each result,
        // and build a list of distinct classes from the result
        const resultList = action.payload;
        processResultList(resultList, state);
      })
      .addCase(fetchMaxEirpPerChannel.rejected, (state) => {
        state.isFetchingMaxEirpPerChannel = false;
        state.resultList = undefined;
        state.currentFilter = undefined;
      });
  },
});

export const {
  setSelectedOperatingClass,
  setSelectedChartType,
  updateCurrentFilter,
} = maxEirpPerChannelSlice.actions;

export const selectMaxEirpPerChannel = (state) =>
  state.maxEirpPerChannel.resultList;
export const selectIsFetchingMaxEirpPerChannel = (state) =>
  state.maxEirpPerChannel.isFetchingMaxEirpPerChannel;
export const selectOperatingClassList = (state) =>
  state.maxEirpPerChannel.operatingClasses;
export const selectedOperatingClass = (state) =>
  state.maxEirpPerChannel.selectedOperatingClass;
export const selectedChartType = (state) =>
  state.maxEirpPerChannel.selectedChartType;

export default maxEirpPerChannelSlice.reducer;
