import PropType from "prop-types";
import {
  Box,
  Dialog,
  IconButton,
  ListItem,
  ListItemText,
  Skeleton,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import WifiFindIcon from "@mui/icons-material/WifiFind";

const DeviceDialog = ({
  onClose,
  deviceDetailList,
  numberOfDevices,
  onShowDeviceDetail,
  onFindIncumbents,
  userConfig,
}) => {
  const listItems = [];
  for (let index = 0; index < numberOfDevices; index++) {
    if (index >= deviceDetailList.length) {
      listItems.push(
        <ListItem key={`loading-${index}`}>
          <Skeleton sx={{ width: 1, height: 1 }} data-testid="device-loading" />
        </ListItem>
      );
    } else {
      const {
        id,
        serialNumber,
        primaryGlobalVendorExtension,
        primaryRequestVendorExtension,
      } = deviceDetailList[index];
      const hasRequestVendorExtension =
        !!userConfig.primaryRequestVendorExtensionName;
      listItems.push(
        <Box
          sx={{ display: "flex", flexDirection: "column" }}
          key={`box-${id}`}
        >
          <ListItem key={`item-${id}`}>
            {primaryGlobalVendorExtension && (
              <ListItemText
                primary={`${primaryGlobalVendorExtension} ${
                  hasRequestVendorExtension
                    ? `: ${primaryRequestVendorExtension}`
                    : ""
                }`}
                secondary={serialNumber}
              />
            )}
            {!primaryGlobalVendorExtension && (
              <ListItemText primary={serialNumber} />
            )}
            <Tooltip title="Show device detail">
              <IconButton
                onClick={() => onShowDeviceDetail(deviceDetailList[index])}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Show overlapping incumbents">
              <IconButton
                onClick={() => onFindIncumbents(deviceDetailList[index])}
              >
                <WifiFindIcon />
              </IconButton>
            </Tooltip>
          </ListItem>
        </Box>
      );
    }
  }
  return (
    <Dialog open={true} onClose={onClose}>
      <Box
        sx={{ display: "flex", flexDirection: "column", minWidth: "280px" }}
        data-testid="device-dialog"
      >
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {listItems}
      </Box>
    </Dialog>
  );
};

DeviceDialog.propTypes = {
  onClose: PropType.func.isRequired,
  deviceDetailList: PropType.array.isRequired,
  numberOfDevices: PropType.number.isRequired,
  onShowDeviceDetail: PropType.func.isRequired,
  onFindIncumbents: PropType.func.isRequired,
  userConfig: PropType.shape({
    primaryGlobalVendorExtensionName: PropType.string,
    primaryRequestVendorExtensionName: PropType.string,
  }).isRequired,
};

export default DeviceDialog;
