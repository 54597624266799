import PropType from "prop-types";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDeviceFilter,
  updateFccCertificationId,
  updateFrequencyBand,
  updateGlobalOperatingClass,
  updateIndoor,
  updateMaxHeight,
  updatePrimaryGlobalExtension,
  updatePrimaryRequestExtension,
  updateSerialNumber,
} from "../../../redux/deviceFilter/deviceFilterSlice";
import { selectFrequencyBandValues } from "../../../redux/lookup/lookupSlice";
import BoundaryChip from "./FilterChips/BoundaryChip";
import DeviceLocationChip from "./FilterChips/DeviceLocationChip";
import FrequencyBandChip from "./FilterChips/FrequencyBandChip";
import GlobalExtensionChip from "./FilterChips/GlobalExtensionChip";
import GlobalOperatingClassChip from "./FilterChips/GlobalOperatingClassChip";
import HeightChip from "./FilterChips/HeightChip";
import RequestExtensionChip from "./FilterChips/RequestExtensionChip";
import ResetChip from "./FilterChips/ResetChip";
import {
  selectIncumbentFilter,
  updateIncumbentFilter,
} from "../../../redux/incumbentFilter/incumbentFilterSlice";
import IncumbentFrequencyChip from "./FilterChips/IncumbentFrequencyChip";
import DeviceSerialNumberChip from "./FilterChips/DeviceSerialNumberChip";
import FccCertificationIdChip from "./FilterChips/FccCertificationIdChip";
import { primaryGlobalVendorExtension } from "../../../redux/lookup/mockLookupAPI";
import { selectUserConfig } from "../../../redux/userConfig/ConfigSlice";

const FilterChipsContainer = ({ onMapHome }) => {
  const dispatch = useDispatch();
  const deviceFilter = useSelector(selectDeviceFilter);
  const incumbentFilter = useSelector(selectIncumbentFilter);
  const frequencyBandLookup = useSelector(selectFrequencyBandValues);
  const {
    primaryGlobalVendorExtensionName = null,
    primaryRequestVendorExtensionName = null,
  } = useSelector(selectUserConfig);

  const resetAllFilters = () => {
    // Device filters
    dispatch(updateIndoor(undefined));
    dispatch(updateMaxHeight(undefined));
    dispatch(updateGlobalOperatingClass(undefined));
    dispatch(updateFrequencyBand(undefined));
    dispatch(updatePrimaryGlobalExtension(undefined));
    dispatch(updatePrimaryRequestExtension(undefined));
    dispatch(updateSerialNumber(undefined));
    dispatch(updateFccCertificationId(undefined));
    // Incumbent filters
    dispatch(updateIncumbentFilter(undefined));
  };

  const haveActiveFilter =
    Object.keys(deviceFilter).length > 1 || // Do not want to count the boundary box for devices
    Object.keys(incumbentFilter).length > 0;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 0.5,
        paddingLeft: "10px",
        paddingRight: "10px",
        alignItems: "center",
        width: 1,
      }}
      data-testid="filter-chips-container"
    >
      <BoundaryChip deviceFilter={deviceFilter} onReset={onMapHome} />
      <DeviceLocationChip
        deviceFilter={deviceFilter}
        onDelete={() => dispatch(updateIndoor(undefined))}
      />
      <HeightChip
        deviceFilter={deviceFilter}
        onDelete={() => dispatch(updateMaxHeight(undefined))}
      />
      <GlobalOperatingClassChip
        deviceFilter={deviceFilter}
        onDelete={() => dispatch(updateGlobalOperatingClass(undefined))}
      />
      <FrequencyBandChip
        deviceFilter={deviceFilter}
        onDelete={() => dispatch(updateFrequencyBand(undefined))}
        lookupValues={frequencyBandLookup}
      />
      {primaryGlobalVendorExtensionName && (
        <GlobalExtensionChip
          displayName={primaryGlobalVendorExtensionName}
          deviceFilter={deviceFilter}
          onDelete={() => dispatch(updatePrimaryGlobalExtension(undefined))}
        />
      )}
      {primaryRequestVendorExtensionName && (
        <RequestExtensionChip
          displayName={primaryRequestVendorExtensionName}
          deviceFilter={deviceFilter}
          onDelete={() => dispatch(updatePrimaryRequestExtension(undefined))}
        />
      )}
      <DeviceSerialNumberChip
        deviceFilter={deviceFilter}
        onDelete={() => dispatch(updateSerialNumber(undefined))}
      />
      <FccCertificationIdChip
        deviceFilter={deviceFilter}
        onDelete={() => dispatch(updateFccCertificationId(undefined))}
      />
      <IncumbentFrequencyChip
        incumbentFilter={incumbentFilter}
        onDelete={() => dispatch(updateIncumbentFilter(undefined))}
      />
      <Box sx={{ flexGrow: 1, minWidth: 0 }}></Box>
      {haveActiveFilter && <ResetChip onClick={resetAllFilters} />}
    </Box>
  );
};

FilterChipsContainer.propTypes = {
  onMapHome: PropType.func.isRequired,
};

export default FilterChipsContainer;
