import { createSlice } from "@reduxjs/toolkit";

export const NotificationType = {
  NONE: "none",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  SUCCESS: "success",
};

const initialState = {
  type: NotificationType.NONE,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    success: {
      reducer: (state, action) => ({
        ...state,
        ...action.payload,
      }),
      prepare: (message, context = null) => ({
        payload: {
          type: NotificationType.SUCCESS,
          context,
          message,
        },
      }),
    },
    info: {
      reducer: (state, action) => ({
        ...state,
        ...action.payload,
      }),
      prepare: (message, context = null) => ({
        payload: {
          type: NotificationType.INFO,
          context,
          message,
        },
      }),
    },
    warn: {
      reducer: (state, action) => ({
        ...state,
        ...action.payload,
      }),
      prepare: (message, context = null) => ({
        payload: {
          type: NotificationType.WARNING,
          context,
          message,
        },
      }),
    },
    error: {
      reducer: (state, action) => ({
        ...state,
        ...action.payload,
      }),
      prepare: (message, context = null) => ({
        payload: {
          type: NotificationType.ERROR,
          context,
          message,
        },
      }),
    },
    clear: {
      reducer: () => ({
        type: NotificationType.NONE,
        context: undefined,
        message: undefined,
      }),
    },
  },
});

export const { success, info, warn, error, clear } = notificationSlice.actions;

export const selectNotification = (state) => state.notification;

const notificationReducer = notificationSlice.reducer;
export default notificationReducer;
