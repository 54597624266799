import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import PropType from "prop-types";

const OperatingClassSelector = ({
  operatingClasses,
  selectedOperatingClass,
  onChange,
}) => {
  const handleChange = (_, newSelection) => {
    if (newSelection) {
      onChange(newSelection);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "10px",
      }}
    >
      <Tooltip title="Global Operating Class">
        <ToggleButtonGroup
          size="small"
          exclusive
          onChange={handleChange}
          value={selectedOperatingClass}
        >
          <ToggleButton value={131} disabled={!operatingClasses.includes(131)}>
            131
          </ToggleButton>
          <ToggleButton value={132} disabled={!operatingClasses.includes(132)}>
            132
          </ToggleButton>
          <ToggleButton value={133} disabled={!operatingClasses.includes(133)}>
            133
          </ToggleButton>
          <ToggleButton value={134} disabled={!operatingClasses.includes(134)}>
            134
          </ToggleButton>
          <ToggleButton value={136} disabled={!operatingClasses.includes(136)}>
            136
          </ToggleButton>
        </ToggleButtonGroup>
      </Tooltip>
    </Box>
  );
};

OperatingClassSelector.propTypes = {
  operatingClasses: PropType.arrayOf(PropType.number).isRequired,
  selectedOperatingClass: PropType.number.isRequired,
  onChange: PropType.func.isRequired,
};

export default OperatingClassSelector;
