import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { error } from "../notification/notificationSlice";
import {
  fetchAggregateKpi,
  fetchAggregateLatency,
  fetchFccCertificationFilters,
  fetchGlobalExtensionFilters,
  fetchKpiGrid,
  fetchRequestExtensionFilters,
} from "./systemKpiAPI";
import { selectSystemKpiFilter } from "../systemKpiFilter/systemKpiFilterSlice";

export const initialState = {
  isFetchingKpi: false,
  isFetchingLatency: false,
  isFetchingKpiGrid: false,
  isFetchingExensionFilters: false,
  availableExtensions: { global: [], request: [] },
};

export const updateExtensionFilters = createAsyncThunk(
  "systemKpi/fetchExtensionFilters",
  async (filter, thunkApi) => {
    try {
      // Only want the date range to affect the extensions being shown
      const { startDate, endDate, intervalType } = filter;
      const dateOnlyFilter = { startDate, endDate, intervalType };
      const result = await Promise.all([
        fetchGlobalExtensionFilters(dateOnlyFilter),
        fetchRequestExtensionFilters(dateOnlyFilter),
        fetchFccCertificationFilters(dateOnlyFilter),
      ]);
      return {
        global: result[0],
        request: result[1],
        fcc: result[2],
      };
    } catch (err) {
      thunkApi.dispatch(error(err?.message, "Loading extension values"));
      return thunkApi.rejectWithValue(err?.message);
    }
  }
);

export const updateAllAggregateKpi = createAsyncThunk(
  "systemKpi/fetchAll",
  async (_, thunkApi) => {
    const filter = selectSystemKpiFilter(thunkApi.getState());

    // Get the data without grouping
    thunkApi.dispatch(updateAggregateKpi(filter));
    thunkApi.dispatch(updateAggregateLatency(filter));

    // Get the KPI grid
    thunkApi.dispatch(updateKpiGrid(filter));
  }
);

export const updateAggregateKpi = createAsyncThunk(
  "systemKpi/fetchAggregateKpi",
  async (filter, thunkApi) => {
    try {
      const aggregateKpiFilter = { ...filter };
      return await fetchAggregateKpi(aggregateKpiFilter);
    } catch (err) {
      thunkApi.dispatch(error(err?.message, "Loading aggregate KPI"));
      return thunkApi.rejectWithValue(err?.message);
    }
  }
);

export const updateAggregateLatency = createAsyncThunk(
  "systemKpi/fetchAggregateLatency",
  async (filter, thunkApi) => {
    try {
      const aggregateLatencyFilter = { ...filter };
      delete aggregateLatencyFilter.metrics;
      return await fetchAggregateLatency(aggregateLatencyFilter);
    } catch (err) {
      thunkApi.dispatch(error(err?.message, "Loading aggreagte latency"));
      return thunkApi.rejectWithValue(err?.message);
    }
  }
);

export const updateKpiGrid = createAsyncThunk(
  "systemKpi/fetchKpiGrid",
  async (filter, thunkApi) => {
    try {
      return await fetchKpiGrid(filter);
    } catch (err) {
      thunkApi.dispatch(error(err?.message, "Loading KPI grid"));
      return thunkApi.rejectWithValue(err?.message);
    }
  }
);

export const systemKpiSlice = createSlice({
  name: "systemKpi",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(updateAggregateKpi.pending, (state) => {
        state.isFetchingKpi = true;
      })
      .addCase(updateAggregateKpi.fulfilled, (state, action) => {
        state.isFetchingKpi = false;
        state.aggregateKpi = action.payload;
      })
      .addCase(updateAggregateKpi.rejected, (state, action) => {
        state.isFetchingKpi = false;
        state.aggregateKpi = undefined;
      })

      .addCase(updateAggregateLatency.pending, (state) => {
        state.isFetchingLatency = true;
      })
      .addCase(updateAggregateLatency.fulfilled, (state, action) => {
        state.isFetchingLatency = false;
        state.aggregateLatency = action.payload;
      })
      .addCase(updateAggregateLatency.rejected, (state, action) => {
        state.isFetchingLatency = false;
        state.aggregateLatency = undefined;
      })

      .addCase(updateKpiGrid.pending, (state) => {
        state.isFetchingKpiGrid = true;
      })
      .addCase(updateKpiGrid.fulfilled, (state, action) => {
        state.isFetchingKpiGrid = false;
        state.kpiGrid = action.payload;
      })
      .addCase(updateKpiGrid.rejected, (state, action) => {
        state.isFetchingKpiGrid = false;
        state.kpigrid = undefined;
      })

      .addCase(updateExtensionFilters.pending, (state) => {
        state.isFetchingExensionFilters = true;
      })
      .addCase(updateExtensionFilters.fulfilled, (state, action) => {
        state.isFetchingExensionFilters = false;
        state.availableExtensions = action.payload;
      })
      .addCase(updateExtensionFilters.rejected, (state, action) => {
        state.isFetchingExensionFilters = false;
        state.availableExtensions = { global: [], request: [], fcc: [] };
      });
  },
});

export const selectAggregateKpi = (state) => state.systemKpi.aggregateKpi;
export const selectAggregateLatency = (state) =>
  state.systemKpi.aggregateLatency;
export const selectKpiGrid = (state) => state.systemKpi.kpiGrid;
export const selectPrimaryGlobalVendorExtensionValues = (state) =>
  state.systemKpi.availableExtensions.global;
export const selectPrimaryRequestVendorExtensionValues = (state) =>
  state.systemKpi.availableExtensions.request;
export const selectFccCertificationIdValues = (state) =>
  state.systemKpi.availableExtensions.fcc;

export const selectIsFetchingAggregateKpi = (state) =>
  state.systemKpi.isFetchingKpi;
export const selectIsFetchingAggregateLatency = (state) =>
  state.systemKpi.isFetchingLatency;
export const selectIsFetchingKpiGrid = (state) =>
  state.systemKpi.isFetchingKpiGrid;
export default systemKpiSlice.reducer;
