import { Chip } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PropType from "prop-types";

const BoundaryChip = ({ deviceFilter, onReset }) => {
  // Do not render the chip if the filter is not set
  if (deviceFilter.boundingBox === undefined) {
    return null;
  }

  const {
    boundingBox: { nwLat, nwLong, seLat, seLong },
  } = deviceFilter;

  return (
    <>
      <Chip
        label={`Boundary NW:[${nwLat.toFixed(2)}, ${nwLong.toFixed(
          2
        )}] SE:[${seLat.toFixed(2)}, ${seLong.toFixed(2)}]`}
        onDelete={onReset}
        deleteIcon={<HomeIcon />}
        data-testid="boundary-chip"
      />
    </>
  );
};

BoundaryChip.propTypes = {
  deviceFilter: PropType.shape({
    boundingBox: PropType.shape({
      nwLat: PropType.number,
      nwLong: PropType.number,
      seLat: PropType.number,
      seLong: PropType.number,
    }),
  }),
  onReset: PropType.func,
};

export default BoundaryChip;
