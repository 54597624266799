import { Paper } from "@mui/material";
import moment from "moment";
import objectHash from "object-hash";
import PropType from "prop-types";
import { useState, useEffect, useMemo } from "react";
import Chart from "react-google-charts";

function convertData(data) {
  const chartData = [];
  chartData.push([
    {
      type: "date",
      label: "Date",
    },
    {
      type: "number",
      label: "Latency/Request (ms)",
    },
    {
      type: "number",
      label: "Latency/Device (ms)",
    },
  ]);
  data.forEach((row) =>
    chartData.push([
      moment.utc(row.receivedDate).toDate(),
      row.latency,
      row.perDeviceLatency,
    ])
  );
  return chartData;
}

function groupAndConvertData(data) {
  const chartDataByDate = {};
  const keys = [];
  data
    .map((entry) => {
      const newEntry = { ...entry };
      if (
        entry.primaryGlobalVendorExtension &&
        entry.primaryRequestVendorExtension
      ) {
        newEntry.key = `${entry.primaryGlobalVendorExtension} - ${entry.primaryRequestVendorExtension}`;
      } else if (entry.primaryGlobalVendorExtension) {
        newEntry.key = entry.primaryGlobalVendorExtension;
      } else {
        newEntry.key = entry.primaryRequestVendorExtension;
      }
      if (!keys.includes(newEntry.key)) {
        keys.push(newEntry.key);
      }
      return newEntry;
    })
    .forEach((entry) => {
      if (!chartDataByDate[entry.receivedDate]) {
        chartDataByDate[entry.receivedDate] = {};
      }
      chartDataByDate[entry.receivedDate][entry.key] = entry;
    });
  const chartData = [];
  const header = [
    {
      type: "date",
      label: "Date",
    },
  ];
  keys.forEach((key) => {
    header.push({ type: "number", label: `${key} Latency/Request` });
    header.push({ type: "number", label: `${key} Latency/Device` });
  });
  chartData.push(header);

  Object.keys(chartDataByDate).forEach((receivedDate) => {
    const row = chartDataByDate[receivedDate];
    const dateRow = [];
    dateRow.push(moment.utc(receivedDate).toDate());
    keys.forEach((key) => {
      if (row[key]) {
        dateRow.push(row[key].latency);
        dateRow.push(row[key].perDeviceLatency);
      } else {
        dateRow.push(null);
        dateRow.push(null);
      }
    });
    chartData.push(dateRow);
  });
  return chartData;
}

const LatencyChart = ({ data, hasGlobalExtension, hasRequestExtension }) => {
  const options = {
    title: "Latency",
    legend: { position: "bottom" },
  };

  // The google chart does not correctly resize on window resize
  // this will force a rerender of the chart using a key attribute.
  const [chartKey, setChartKey] = useState(0);
  const handleResize = () => {
    setChartKey((prev) => prev + 1);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const chartData = useMemo(() => {
    if (data) {
      if (
        data[0].primaryRequestVendorExtension ||
        data[0].primaryGlobalVendorExtension
      ) {
        return groupAndConvertData(
          data,
          hasGlobalExtension,
          hasRequestExtension
        );
      } else {
        // No vendor extensions so group by latency per request and device
        return convertData(data);
      }
    }
  }, [data]);

  return (
    <Paper elevation={3} sx={{ width: 1, height: 1 }}>
      <Chart
        key={chartKey}
        chartType="LineChart"
        width="100%"
        height="100%"
        data={chartData}
        options={options}
      />
    </Paper>
  );
};

LatencyChart.propTypes = {
  data: PropType.arrayOf(
    PropType.shape({
      latency: PropType.number,
      primaryRequestVendorExtension: PropType.string,
      primaryGlobalVendorExtension: PropType.string,
      per_device_latency: PropType.number,
      receivedDate: PropType.string,
    })
  ),
  hasGlobalExtension: PropType.bool,
  hasRequestExtension: PropType.bool,
};

export default LatencyChart;
