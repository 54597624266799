import { Box, Chip, Typography } from "@mui/material";
import PropType from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchIncumbentDeviceOverlap,
  selectIncumbentDetailsList,
  selectIsFetchingDeviceOverlap,
} from "../../../../redux/incumbentContour/incumbentContourSlice";
import { selectIncumbentFilter } from "../../../../redux/incumbentFilter/incumbentFilterSlice";
import DeviceIncumbentTable from "./DeviceIncumbentTable";

const DeviceIncumbentOverlapContainer = ({ show, deviceId }) => {
  const dispatch = useDispatch();
  const incumbentFilter = useSelector(selectIncumbentFilter);
  useEffect(() => {
    if (!!deviceId && show) {
      dispatch(fetchIncumbentDeviceOverlap({ deviceId, incumbentFilter }));
    }
  }, [dispatch, deviceId, show, incumbentFilter]);
  const isLoading = useSelector(selectIsFetchingDeviceOverlap);
  const incumbentList = useSelector(selectIncumbentDetailsList);

  if (!deviceId || !show) {
    return null;
  }

  return (
    <Box>
      {incumbentFilter.frequencyRange && (
        <Box sx={{ paddingTop: "16px" }}>
          <Chip
            color="primary"
            label={`Incumbents Filtered to ${incumbentFilter.frequencyRange.minimum} - ${incumbentFilter.frequencyRange.maximum} MHz`}
          />
        </Box>
      )}
      <DeviceIncumbentTable
        incumbentList={incumbentList || []}
        showLoading={isLoading}
      />
    </Box>
  );
};

DeviceIncumbentOverlapContainer.propTypes = {
  show: PropType.bool.isRequired,
  deviceId: PropType.number,
};

export default DeviceIncumbentOverlapContainer;
