import {
  Box,
  Drawer,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropType from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSystemKpiFilter,
  updateSystemKpiFilter,
} from "../../../../redux/systemKpiFilter/systemKpiFilterSlice";
import { deepEqual } from "fast-equals";
import { updateExtensionFilters } from "../../../../redux/systemKpi/systemKpiSlice";
import { intervalTypes } from "../../Filters/intervalTypes";
import DateRangeFilter from "../../Filters/DateRangeFilter";
import { removeUndefinedProperties } from "../../../../util";

const FilterDrawer = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const currentFilter = useSelector(selectSystemKpiFilter);

  const [interval, setInterval] = useState(
    currentFilter.interval?.id
      ? intervalTypes[currentFilter.interval.id]
      : intervalTypes.DAILY
  );
  const [dateRange, setDateRange] = useState(
    currentFilter.dateRange ?? {
      startDate: interval.defaultStartDate(),
      endDate: interval.defaultEndDate(),
    }
  );

  useEffect(() => {
    dispatch(updateExtensionFilters(getNewFilter()));
  }, [interval, dateRange]);

  const getNewFilter = () => ({
    intervalType: interval.id,
    startDate: dateRange?.startDate,
    endDate: dateRange?.endDate,
  });

  const handleClose = () => {
    const newFilter = getNewFilter();
    if (!deepEqual(newFilter, currentFilter)) {
      removeUndefinedProperties(newFilter);
      dispatch(updateSystemKpiFilter(newFilter));
    }
    onClose();
  };

  const handleDateRangeChange = (newValue) => {
    setDateRange(newValue);
  };

  const handleIntervalChange = (interval) => {
    setInterval(interval);
    setDateRange({
      startDate: interval.defaultStartDate(),
      endDate: interval.defaultEndDate(),
    });
  };

  return (
    <Drawer
      anchor="right"
      open={show}
      onClose={handleClose}
      data-testid="system-errors-filter-drawer"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          border: "30px",
        }}
      >
        <IconButton onClick={handleClose} data-testid="filter-close">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          paddingLeft: "32px",
          paddingRight: "32px",
          minWidth: "80ch",
          maxWidth: "80ch",
        }}
      >
        <Stack spacing={4}>
          <Typography align="center" variant="h4">
            Device Filters
          </Typography>
          <TextField
            name="interval"
            label="Interval"
            select
            onChange={(event) => handleIntervalChange(event.target.value)}
            value={interval}
          >
            <MenuItem value={intervalTypes.DAILY}>Daily</MenuItem>
            <MenuItem value={intervalTypes.MONTHLY}>Monthly</MenuItem>
          </TextField>
          <DateRangeFilter
            interval={interval}
            onChange={handleDateRangeChange}
            start={dateRange.startDate}
            end={dateRange.endDate}
          />
        </Stack>
      </Box>
    </Drawer>
  );
};

FilterDrawer.propTypes = {
  show: PropType.bool.isRequired,
  onClose: PropType.func.isRequired,
};

export default FilterDrawer;
