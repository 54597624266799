import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Dialog, Autocomplete, TextField, Typography } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CustomButton from "../CustomButton/CustomButton";
import PropTypes from "prop-types";
import { initiateResetUserAuthentication } from "../../redux/userManagement/ResetPasswordSlice";

const ResetPasswordModal = ({
  open,
  handleClose,
  userEmails,
  setShowSnackbar,
  setSnackbarMessage,
}) => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const closeModal = () => {
    setEmail("");
    setErrorMessage("");
    handleClose();
  };

  const handleConfirmClick = () => {
    if (email) {
      dispatch(initiateResetUserAuthentication(email))
        .then((actionResult) => {
          if (actionResult.meta.requestStatus === "fulfilled") {
            setSnackbarMessage("Password reset successful");
            setShowSnackbar(true);
            setErrorMessage("");
            closeModal();
          } else {
            setErrorMessage("Failed to reset password. Please try again.");
          }
        })
        .catch((error) => {
          setErrorMessage("Failed to reset password. Please try again.");
        });
    } else {
      setErrorMessage("Please select an email.");
    }
  };

  const handleCancelClick = () => {
    closeModal();
  };

  return (
    <Dialog open={open} onClose={closeModal} style={{ borderRadius: "24px" }}>
      <div
        style={{
          padding: "20px",
          width: "400px",
          height: "280px",
          margin: "auto",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <PersonOutlineOutlinedIcon style={{ marginRight: "8px" }} />
          <Typography variant="h6" style={{ textAlign: "center" }}>
            Reset Password/MFA
          </Typography>
        </div>

        <Autocomplete
          fullWidth
          options={userEmails}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Email"
              variant="outlined"
              margin="normal"
            />
          )}
          value={email}
          onChange={(event, newValue) => {
            setEmail(newValue);
            setErrorMessage(false);
          }}
          isOptionEqualToValue={(option, value) =>
            option === value || (!value && value === "")
          }
        />
        <div style={{ color: "red" }}>{errorMessage}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "50px",
          }}
        >
          <CustomButton
            text="Cancel"
            onClick={handleCancelClick}
            sx={{
              color: "white",
              "&:hover": {
                backgroundColor: "#f86903",
                opacity: "70%",
              },
              backgroundColor: "#f86903",
              opacity: "100%",
              width: "120px",
              height: "50px",
              borderRadius: 10,
            }}
          />
          <CustomButton
            text="Confirm"
            onClick={handleConfirmClick}
            sx={{
              color: "white",
              "&:hover": {
                backgroundColor: "#2196f3",
                opacity: "70%",
              },
              backgroundColor: "#2196f3",
              opacity: "100%",
              width: "120px",
              borderRadius: 10,
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

ResetPasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
  setShowSnackbar: PropTypes.func.isRequired,
  setSnackbarMessage: PropTypes.func.isRequired,
};

export default ResetPasswordModal;
