import Chart from "react-google-charts";
import PropType from "prop-types";
import { frequencyBandPropType } from "./frequencyBands";
import { useMemo } from "react";
import ChartError, { ChartLoading } from "../../ChartError";

export function transformData(maxPsdPerFrequencyRows, band) {
  const filteredRows = maxPsdPerFrequencyRows.filter((row) =>
    band.isInBand(row.frequency)
  );

  const extensions = [];
  filteredRows.forEach((row) => {
    if (row.extension && !extensions.includes(row.extension)) {
      extensions.push(row.extension);
    }
  });
  extensions.sort();

  // If we have no extension types we are generating a single line pm the chart
  if (extensions.length > 0) {
    return transformDataWithExtensions(filteredRows, extensions);
  }
  return transformDataWithoutExtensions(filteredRows);
}

export function transformDataWithExtensions(filteredRows, extensions) {
  const descriptions = [hAxisTitle];
  extensions.forEach((type) => {
    descriptions.push({ label: type, type: "number" });
  });

  const mappedData = {};
  const frequencyValues = [];
  filteredRows.forEach((row) => {
    if (!frequencyValues.includes(row.frequency)) {
      frequencyValues.push(row.frequency);
    }

    let frequencyValue = mappedData[row.frequency];
    if (!frequencyValue) {
      frequencyValue = mappedData[row.frequency] = { frequency: row.frequency };
    }
    frequencyValue[row.extension] = row.average;
  });
  frequencyValues.sort((a, b) => a - b);

  const chartData = [];
  chartData.push(descriptions);
  frequencyValues.forEach((frequency) => {
    const row = [frequency];
    extensions.forEach((type) => {
      row.push(mappedData[frequency][type]);
    });
    chartData.push(row);
  });
  return chartData;
}

export function transformDataWithoutExtensions(filteredRows) {
  const descriptions = [hAxisTitle, "maxPSD"];
  const chartData = [];
  chartData.push(descriptions);
  filteredRows.forEach((row) => {
    chartData.push([row.frequency, row.average]);
  });
  chartData.sort((a, b) => a[0] - b[0]);

  return chartData;
}

const hAxisTitle = "Frequency";
const vAxisTitle = "Average maxPSD (dBm/MHz)";

const MaxPsdPerFrequencyChart = ({
  maxPsdPerFrequencyRows,
  band,
  showLegend,
}) => {
  const data = useMemo(() => {
    return transformData(maxPsdPerFrequencyRows, band);
  }, [maxPsdPerFrequencyRows, band]);

  const options = {
    hAxis: {
      title: hAxisTitle,
      viewWindow: {
        min: band.lower,
        max: band.upper,
      },
    },
    vAxis: {
      title: vAxisTitle,
      viewWindow: {
        min: 0,
        max: 23,
      },
    },
    focusTarget: "category",
    theme: "material",
    legend: {
      position: "none",
    },
  };

  if (showLegend) {
    options.legend.position = "right";
    options.chartArea = { width: "60%" };
  }

  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height="100%"
      data={data}
      options={options}
      rootProps={{ "data-testid": "max-psd-per-frequency-chart" }}
      errorElement={<ChartError />}
      loader={<ChartLoading />}
    />
  );
};

MaxPsdPerFrequencyChart.propTypes = {
  maxPsdPerFrequencyRows: PropType.arrayOf(
    PropType.shape({
      average: PropType.number,
      extension: PropType.string,
      frequency: PropType.number,
    })
  ).isRequired,
  band: frequencyBandPropType.isRequired,
  showLegend: PropType.bool,
};

export default MaxPsdPerFrequencyChart;
