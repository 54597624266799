import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropType from "prop-types";
import {
  fetchMaxEirpPerChannel,
  selectedChartType,
  selectedOperatingClass,
  selectIsFetchingMaxEirpPerChannel,
  selectMaxEirpPerChannel,
  selectOperatingClassList,
  setSelectedChartType,
  setSelectedOperatingClass,
} from "../../../redux/maxEirpPerChannel/maxEirpPerChannelSlice";
import MaxEirpPerChannelChart from "./MaxEirpPerChannelChart";
import OperatingClassSelector from "./OperatingClassSelector";
import ChartTypeSelector from "./ChartTypeSelector";
import { selectDeviceFilter } from "../../../redux/deviceFilter/deviceFilterSlice";

const MaxEirpPerChannelContainer = ({ chartKey, showLegend }) => {
  // chartKey is passed to the chart component and changing its value rerenders the chart
  const dispatch = useDispatch();
  const deviceListFilter = useSelector(selectDeviceFilter);
  useEffect(() => {
    dispatch(fetchMaxEirpPerChannel(deviceListFilter));
  }, [dispatch, deviceListFilter]);

  const maxEirpRows = useSelector(selectMaxEirpPerChannel);
  const isUpdating = useSelector(selectIsFetchingMaxEirpPerChannel);
  const operatingClasses = useSelector(selectOperatingClassList);
  const selectedClass = useSelector(selectedOperatingClass);
  const selectedChart = useSelector(selectedChartType);

  const handleOperatingClassChange = (selectedClass) => {
    dispatch(setSelectedOperatingClass(selectedClass));
  };

  const handleChartTypeChange = (selectedType) => {
    dispatch(setSelectedChartType(selectedType));
  };

  // Need to filter out null values in case they are all null
  const hasData =
    maxEirpRows && maxEirpRows.filter((row) => row.average !== null).length > 0;

  return (
    <>
      {isUpdating && <Skeleton sx={{ height: "100%" }} />}
      {!isUpdating && hasData && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <OperatingClassSelector
                operatingClasses={operatingClasses}
                selectedOperatingClass={selectedClass}
                onChange={handleOperatingClassChange}
              />
              <ChartTypeSelector
                selectedChartType={selectedChart}
                onChange={handleChartTypeChange}
              />
            </Box>
            <Box sx={{ flexGrow: 1, paddingBottom: "10px" }}>
              <MaxEirpPerChannelChart
                key={chartKey}
                maxEirpRows={maxEirpRows}
                selectedOperatingClass={selectedClass}
                lineChart={selectedChart === "line"}
                showLegend={showLegend}
              />
            </Box>
          </Box>
        </>
      )}
      {!isUpdating && !hasData && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 1,
            height: 1,
          }}
        >
          <Typography align="center">No Data to Display</Typography>
        </Box>
      )}
    </>
  );
};

MaxEirpPerChannelContainer.propTypes = {
  chartKey: PropType.any,
  showLegend: PropType.bool,
};

export default MaxEirpPerChannelContainer;
