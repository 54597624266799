import { Box } from "@mui/material";
import PropType from "prop-types";
import styles from "./DeviceClusterMap.module.css";

export const incumbentMarker = window.btoa(`
  <svg viewBox="0 0 746 746" xmlns="http://www.w3.org/2000/svg" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.5;">
    <circle fill="#ff0000" cx="373" cy="373" opacity=".6" r="373" />
    <g fill="white" stroke="white" transform="translate(158.014 96.25) scale(0.75 0.75)">
      <g>
        <circle cx="286.648" cy="276.907" r="35.837"/>
        <path d="M287.526,291.432l-133.358,430" style="fill:none;stroke-width:37.5px;"/>
        <path d="M415.83,726.659l-135.166,-433.137" style="fill:none;stroke-width:37.5px;"/>
        <path d="M173.379,705.43l204.619,-110.34" style="fill:none;stroke-width:20.83px;"/>
        <path d="M194.298,589.015l207.698,118.049" style="fill:none;stroke-width:20.83px;"/>
        <path d="M363.848,590.849l-134.649,-105.86" style="fill:none;stroke-width:20.83px;"/>
        <path d="M205.313,597.245l141.965,-127.119" style="fill:none;stroke-width:20.83px;"/>
        <path d="M254.847,396.907l80.633,77.215" style="fill:none;stroke-width:12.5px;"/>
        <path d="M230.721,474.962l91.467,-77.362" style="fill:none;stroke-width:12.5px;"/>
      </g>
      <path d="M356.921,403.864c41.286,-24.807 66.541,-69.445 66.541,-117.61c-0,-75.728 -61.481,-137.209 -137.208,-137.209c-75.728,0 -137.209,61.481 -137.209,137.209c0,45.613 22.668,88.244 60.483,113.75c-37.815,-25.506 -60.483,-68.137 -60.483,-113.75c0,-75.728 61.481,-137.209 137.209,-137.209c75.727,0 137.208,61.481 137.208,137.209c-0,48.165 -25.255,92.803 -66.541,117.61Z" style="fill:none;stroke-width:20.83px;"/>
      <path d="M390.114,459.107c60.678,-36.459 97.796,-102.065 97.796,-172.853c-0,-111.298 -90.359,-201.657 -201.656,-201.657c-111.298,0 -201.657,90.359 -201.657,201.657c0,67.038 33.315,129.693 88.892,167.18c-55.577,-37.487 -88.892,-100.142 -88.892,-167.18c0,-111.298 90.359,-201.657 201.657,-201.657c111.297,0 201.656,90.359 201.656,201.657c-0,70.788 -37.118,136.394 -97.796,172.853Z" style="fill:none;stroke-width:20.83px;"/>
      <path d="M428.32,522.692c82.999,-49.871 133.77,-139.609 133.77,-236.438c0,-152.239 -123.598,-275.837 -275.836,-275.837c-152.239,-0 -275.837,123.598 -275.837,275.837c-0,91.698 45.569,177.402 121.591,228.679c-76.022,-51.277 -121.591,-136.981 -121.591,-228.679c-0,-152.239 123.598,-275.837 275.837,-275.837c152.238,-0 275.836,123.598 275.836,275.837c0,96.829 -50.771,186.567 -133.77,236.438Z" style="fill:none;stroke-width:20.83px;"/>
    </g>
  </svg>`);

export const exampleIncumbentButton = window.btoa(`<svg
            viewBox="0 0 746 746"
            xmlns="http://www.w3.org/2000/svg"
            style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 1.5"
          >
            <g
              fill="black"
              stroke="black"
              transform="translate(158.014 96.25) scale(0.75 0.75)"
            >
              <g>
                <circle cx="286.648" cy="276.907" r="35.837" />
                <path
                  d="M287.526,291.432l-133.358,430"
                  style="fill: none; stroke-width: 37.5px"
                />
                <path
                  d="M415.83,726.659l-135.166,-433.137"
                  style="fill: none; stroke-width: 37.5px"
                />
                <path
                  d="M173.379,705.43l204.619,-110.34"
                  style="fill: none; stroke-width: 12.5px"
                />
                <path
                  d="M194.298,589.015l207.698,118.049"
                  style="fill: none; stroke-width: 12.5px"
                />
                <path
                  d="M363.848,590.849l-134.649,-105.86"
                  style="fill: none; stroke-width: 12.5px"
                />
                <path
                  d="M205.313,597.245l141.965,-127.119"
                  style="fill: none; stroke-width: 12.5px"
                />
                <path
                  d="M254.847,396.907l80.633,77.215"
                  style="fill: none; stroke-width: 12.5px"
                />
                <path
                  d="M230.721,474.962l91.467,-77.362"
                  style="fill: none; stroke-width: 12.5px"
                />
              </g>
              <path
                stroke="black"
                d="M356.921,403.864c41.286,-24.807 66.541,-69.445 66.541,-117.61c-0,-75.728 -61.481,-137.209 -137.208,-137.209c-75.728,0 -137.209,61.481 -137.209,137.209c0,45.613 22.668,88.244 60.483,113.75c-37.815,-25.506 -60.483,-68.137 -60.483,-113.75c0,-75.728 61.481,-137.209 137.209,-137.209c75.727,0 137.208,61.481 137.208,137.209c-0,48.165 -25.255,92.803 -66.541,117.61Z"
                style="fill: none; stroke-width: 20.83px"
              />
              <path
                stroke="black"
                d="M390.114,459.107c60.678,-36.459 97.796,-102.065 97.796,-172.853c-0,-111.298 -90.359,-201.657 -201.656,-201.657c-111.298,0 -201.657,90.359 -201.657,201.657c0,67.038 33.315,129.693 88.892,167.18c-55.577,-37.487 -88.892,-100.142 -88.892,-167.18c0,-111.298 90.359,-201.657 201.657,-201.657c111.297,0 201.656,90.359 201.656,201.657c-0,70.788 -37.118,136.394 -97.796,172.853Z"
                style="fill: none; stroke-width: 20.83px"
              />
              <path
                stroke="black"
                d="M428.32,522.692c82.999,-49.871 133.77,-139.609 133.77,-236.438c0,-152.239 -123.598,-275.837 -275.836,-275.837c-152.239,-0 -275.837,123.598 -275.837,275.837c-0,91.698 45.569,177.402 121.591,228.679c-76.022,-51.277 -121.591,-136.981 -121.591,-228.679c-0,-152.239 123.598,-275.837 275.837,-275.837c152.238,-0 275.836,123.598 275.836,275.837c0,96.829 -50.771,186.567 -133.77,236.438Z"
                style="fill: none; stroke-width: 20.83px"
              />
            </g>
          </svg>`);

export const IncumbentButton = ({ selected = false, onClick }) => {
  const color = "black";
  const radioColour = selected ? "red" : "black";
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
        borderRadius: "2px",
        cursor: "pointer",
        margin: "10px",
        textAlign: "center",
        title: "Toggle incumbent contours",
      }}
    >
      <button
        onClick={onClick}
        className={styles.mapControlButton}
        data-testid={"show-incumbent-button"}
      >
        <i>
          <svg
            viewBox="0 0 746 746"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              fillRule: "evenodd",
              clipRule: "evenodd",
              strokeLinejoin: "round",
              strokeMiterlimit: 1.5,
            }}
          >
            <g
              fill={color}
              stroke={color}
              transform="translate(158.014 96.25) scale(0.75 0.75)"
            >
              <g>
                <circle cx="286.648" cy="276.907" r="35.837" />
                <path
                  d="M287.526,291.432l-133.358,430"
                  style={{ fill: "none", strokeWidth: "37.5px" }}
                />
                <path
                  d="M415.83,726.659l-135.166,-433.137"
                  style={{ fill: "none", strokeWidth: "37.5px" }}
                />
                <path
                  d="M173.379,705.43l204.619,-110.34"
                  style={{ fill: "none", strokeWidth: "12.5px" }}
                />
                <path
                  d="M194.298,589.015l207.698,118.049"
                  style={{ fill: "none", strokeWidth: "12.5px" }}
                />
                <path
                  d="M363.848,590.849l-134.649,-105.86"
                  style={{ fill: "none", strokeWidth: "12.5px" }}
                />
                <path
                  d="M205.313,597.245l141.965,-127.119"
                  style={{ fill: "none", strokeWidth: "12.5px" }}
                />
                <path
                  d="M254.847,396.907l80.633,77.215"
                  style={{ fill: "none", strokeWidth: "12.5px" }}
                />
                <path
                  d="M230.721,474.962l91.467,-77.362"
                  style={{ fill: "none", strokeWidth: "12.5px" }}
                />
              </g>
              <path
                stroke={radioColour}
                d="M356.921,403.864c41.286,-24.807 66.541,-69.445 66.541,-117.61c-0,-75.728 -61.481,-137.209 -137.208,-137.209c-75.728,0 -137.209,61.481 -137.209,137.209c0,45.613 22.668,88.244 60.483,113.75c-37.815,-25.506 -60.483,-68.137 -60.483,-113.75c0,-75.728 61.481,-137.209 137.209,-137.209c75.727,0 137.208,61.481 137.208,137.209c-0,48.165 -25.255,92.803 -66.541,117.61Z"
                style={{ fill: "none", strokeWidth: "20.83px" }}
              />
              <path
                stroke={radioColour}
                d="M390.114,459.107c60.678,-36.459 97.796,-102.065 97.796,-172.853c-0,-111.298 -90.359,-201.657 -201.656,-201.657c-111.298,0 -201.657,90.359 -201.657,201.657c0,67.038 33.315,129.693 88.892,167.18c-55.577,-37.487 -88.892,-100.142 -88.892,-167.18c0,-111.298 90.359,-201.657 201.657,-201.657c111.297,0 201.656,90.359 201.656,201.657c-0,70.788 -37.118,136.394 -97.796,172.853Z"
                style={{ fill: "none", strokeWidth: "20.83px" }}
              />
              <path
                stroke={radioColour}
                d="M428.32,522.692c82.999,-49.871 133.77,-139.609 133.77,-236.438c0,-152.239 -123.598,-275.837 -275.836,-275.837c-152.239,-0 -275.837,123.598 -275.837,275.837c-0,91.698 45.569,177.402 121.591,228.679c-76.022,-51.277 -121.591,-136.981 -121.591,-228.679c-0,-152.239 123.598,-275.837 275.837,-275.837c152.238,-0 275.836,123.598 275.836,275.837c0,96.829 -50.771,186.567 -133.77,236.438Z"
                style={{ fill: "none", strokeWidth: "20.83px" }}
              />
            </g>
          </svg>
        </i>
      </button>
    </Box>
  );
};

IncumbentButton.propTypes = {
  selected: PropType.bool,
  onClick: PropType.func.isRequired,
};
