import { Box, Tooltip, Typography } from "@mui/material";
import { GLOBAL_OPERATING_CLASS_FILTERED_CHANNELS } from "../../../util/constants";
import { interpolateHslLong } from "d3-interpolate";

const IncumbentLegend = () => {
  const numChannels = GLOBAL_OPERATING_CLASS_FILTERED_CHANNELS[131].length;

  return (
    <Box sx={{ width: 1, height: "2rem", position: "relative" }}>
      <Box sx={{ display: "flex", flexDirection: "row", height: 1 }}>
        {GLOBAL_OPERATING_CLASS_FILTERED_CHANNELS[131].map((channel) => (
          <Tooltip
            title={`${channel.lowerFreq} - ${channel.upperFreq}`}
            key={channel.index}
          >
            <Box
              sx={{
                height: 1,
                flexGrow: 1,
                backgroundColor: interpolateHslLong(
                  "blue",
                  "yellow"
                )(channel.index / numChannels),
              }}
            ></Box>
          </Tooltip>
        ))}
      </Box>
      <Box
        sx={{
          height: 1,
          width: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: 0,
          left: 0,
          pointerEvents: "none",
        }}
      >
        <Typography sx={{ position: "absolute" }}>
          Incumbent Center Frequency
        </Typography>
      </Box>
    </Box>
  );
};

export default IncumbentLegend;
