import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useMemo } from "react";
import PropType from "prop-types";
import { usePagination, useTable } from "react-table";
import DeviceZoomButton from "../DeviceZoomButton";

const DeviceInformationTable = ({
  userConfig: {
    primaryGlobalVendorExtensionName,
    primaryRequestVendorExtensionName,
  },
  deviceList,
  totalDeviceCount,
  currentPage,
  rowsPerPage,
  onSelectDevice,
  onPageChange,
  onRowsPerPageChange,
  onDeviceZoom,
}) => {
  const columns = useMemo(() => {
    const headers = [
      {
        Header: "Certification Id",
        accessor: "certificationId",
      },
      {
        Header: "Serial No.",
        accessor: "serialNumber",
      },
    ];
    if (primaryGlobalVendorExtensionName) {
      headers.push({
        Header: primaryGlobalVendorExtensionName,
        accessor: "primaryGlobalVendorExtension",
      });
    }
    if (primaryRequestVendorExtensionName) {
      headers.push({
        Header: primaryRequestVendorExtensionName,
        accessor: "primaryRequestVendorExtension",
      });
    }
    return headers;
  }, []);

  const rowData = deviceList.map((device) => {
    // Need to check if we need the request vendor extension, if not ensure it's not in the data
    const deviceData = { ...device };
    if (!primaryGlobalVendorExtensionName) {
      delete deviceData.primaryGlobalVendorExtension;
    }
    if (!primaryRequestVendorExtensionName) {
      delete deviceData.primaryRequestVendorExtension;
    }
    return deviceData;
  });

  const handlePageChange = (_, newPage) => {
    if (onPageChange) {
      onPageChange(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    if (onRowsPerPageChange) {
      onRowsPerPageChange(parseInt(event.target.value, 10));
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: deviceList ?? [],
        manualPagination: true,
      },
      usePagination
    );
  return (
    <TableContainer sx={{ width: 1, height: 1 }}>
      <Table
        {...getTableProps()}
        data-testid="component-device-information-table"
        stickyHeader
      >
        <TableHead>
          {headerGroups.map((headerGroup, headIndex) => (
            <TableRow key={headIndex} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <TableCell key={columnIndex}>
                  {column.render("Header")}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <TableRow
                key={rowIndex}
                hover
                {...row.getRowProps({
                  onClick: () => onSelectDevice(row.original),
                  style: { cursor: "pointer" },
                })}
              >
                {row.cells.map((cell, cellIndex) => (
                  <TableCell key={cellIndex} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableCell>
                ))}
                <TableCell>
                  <DeviceZoomButton
                    desiredZoom={15}
                    onClick={onDeviceZoom}
                    location={row.original.location}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={totalDeviceCount}
              page={currentPage}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 50, 100]}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

DeviceInformationTable.propTypes = {
  userConfig: PropType.shape({
    primaryGlobalVendorExtensionName: PropType.string,
    primaryRequestVendorExtensionName: PropType.string,
  }).isRequired,
  deviceList: PropType.array.isRequired,
  totalDeviceCount: PropType.number.isRequired,
  currentPage: PropType.number.isRequired,
  rowsPerPage: PropType.number.isRequired,
  onSelectDevice: PropType.func,
  onPageChange: PropType.func.isRequired,
  onRowsPerPageChange: PropType.func.isRequired,
  onDeviceZoom: PropType.func.isRequired,
};

DeviceInformationTable.defaulProps = {
  onSelectDevice: () => {},
};

export default DeviceInformationTable;
