import PropType from "prop-types";
import { Chip } from "@mui/material";

const GlobalExtensionChip = ({
  displayName = "Instance ID",
  deviceFilter,
  onDelete,
}) => {
  const { primaryGlobalVendorExtension } = deviceFilter;
  // Do not render the chip if the filter is not set
  if (primaryGlobalVendorExtension === undefined) {
    return null;
  }

  return (
    <Chip
      onDelete={onDelete}
      label={`${displayName}  [${primaryGlobalVendorExtension.length} selected]`}
      data-testid="global-extension-chip"
    />
  );
};

GlobalExtensionChip.propTypes = {
  displayName: PropType.string,
  deviceFilter: PropType.shape({
    primaryGlobalVendorExtension: PropType.arrayOf(PropType.string),
  }),
  onDelete: PropType.func,
};

export default GlobalExtensionChip;
