import axios from "axios";

export const fetchOrganizations = async () => {
  try {
    const response = await axios.get(
      `${window.config.MANAGEMENT_API_ROOT}/organizations`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
