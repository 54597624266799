import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropType from "prop-types";

import Grid from "@mui/material/Grid";

import {
  fetchDeviceChannelConfig,
  selectDeviceChannelConfig,
} from "../../../../redux/deviceChannelConfig/deviceChannelConfigSlice";

import DeviceChannelConfigLegend from "./DeviceChannelConfigLegend";
import DeviceChannelConfigChart from "./DeviceChannelConfigChart";
import DeviceChannelConfigSkeleton from "./DeviceChannelConfigSkeleton";
import { selectUserConfig } from "../../../../redux/userConfig/ConfigSlice";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const DeviceChannelConfig = ({ deviceId, show }) => {
  const dispatch = useDispatch();
  const [profile, setProfile] = useState("default");
  const { loading, current, error } = useSelector(selectDeviceChannelConfig);

  const { psdDisplayConfig } = useSelector(selectUserConfig);
  const profileNameList = useMemo(() => {
    const nameList = psdDisplayConfig ? Object.keys(psdDisplayConfig) : [];
    // Need to make the default option the first option
    nameList.sort((a, b) => {
      if (a === "default") {
        return -1;
      }
      if (b === "default") {
        return 1;
      }
      return a.localeCompare(b);
    });
    return nameList;
  }, [psdDisplayConfig]);

  useEffect(() => {
    if (deviceId != null) {
      dispatch(fetchDeviceChannelConfig({ deviceId, profile }));
    }
  }, [deviceId, profile]);

  const loaded = !loading && !!current;

  const handleProfileChange = (event) => {
    setProfile(event.target.value);
  };

  return (
    <Box
      sx={{ display: { xs: !show ? "none" : "flex" }, flexDirection: "column" }}
    >
      {profileNameList && profileNameList.length > 1 && (
        <Box>
          <FormControl sx={{ margin: "10px" }}>
            <InputLabel id="profile-select">Profile</InputLabel>
            <Select
              labelId="profile-select"
              value={profile}
              label="Profile"
              onChange={handleProfileChange}
              size="small"
            >
              {profileNameList.map((profileName) => (
                <MenuItem key={profileName} value={profileName}>
                  {profileName === "default" ? "Federated" : profileName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
        <Box sx={{ flexGrow: 1, margin: "10px" }}>
          {loaded && (
            <DeviceChannelConfigChart
              config={current}
              displayProfileName={profile}
            />
          )}
          {!loaded && <DeviceChannelConfigSkeleton error={error} />}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <DeviceChannelConfigLegend profile={profile} />
        </Box>
      </Box>
    </Box>
  );
};

DeviceChannelConfig.propTypes = {
  deviceId: PropType.number,
  show: PropType.bool.isRequired,
};

DeviceChannelConfig.defaultProps = {
  deviceId: null,
};

export default DeviceChannelConfig;
