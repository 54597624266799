import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropType from "prop-types";

import Grid from "@mui/material/Grid";

import { Chart } from "react-google-charts";

import {
  fetchHistoricalDeviceSummaryEIRP,
  selectHistoricalDeviceSummaryEIRP,
} from "../../../../redux/historicalDeviceSummaryEIRP/historicalDeviceSummaryEirpSlice";

import { toChartOptions, toDataTable } from "./util";

import HistoricalDeviceSummaryEirpSkeleton from "./HistoricalDeviceSummaryEirpSkeleton";
import HistoricalDeviceSummaryEirpFilter from "./HistoricalDeviceSummaryEirpFilter";
import ChartError, { ChartLoading } from "../../../ChartError";
import { Box, Typography } from "@mui/material";

const HistoricalDeviceSummaryEirp = ({ serialNumber, show }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(null);

  // The google chart does not correctly resize on window resize
  // this will force a rerender of the chart using a key attribute.
  const [chartKey, setChartKey] = useState(0);
  const handleResize = () => {
    setChartKey((prev) => prev + 1);
  };

  useEffect(() => {
    if (serialNumber && filter) {
      dispatch(
        fetchHistoricalDeviceSummaryEIRP({
          serialNumber,
          ...filter,
        })
      );
    }
  }, [serialNumber, filter]);

  const { loading, current, error, empty } = useSelector(
    selectHistoricalDeviceSummaryEIRP
  );

  const loaded = !loading && !!current;

  const chartData = useMemo(() => {
    if (loaded) {
      return toDataTable(current);
    }
  }, [loaded, current]);

  const chartOptions = useMemo(() => {
    if (loaded) {
      return toChartOptions(current);
    }
  }, [loaded, current]);

  return (
    <Box
      sx={{
        display: { xs: !show ? "none" : "flex" },
        flexDirection: "column",
        height: 1,
      }}
    >
      <Box>
        <HistoricalDeviceSummaryEirpFilter
          readonly={loading}
          onChange={setFilter}
        />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        {loaded && (
          <Chart
            key={chartKey}
            chartType="LineChart"
            data={chartData}
            options={chartOptions}
            height="95%"
            width="100%"
            rootProps={{ "data-testid": "chartDeviceEirp" }}
            errorElement={<ChartError />}
            loader={<ChartLoading />}
          />
        )}
        {!loaded && (
          <HistoricalDeviceSummaryEirpSkeleton error={error} empty={empty} />
        )}
      </Box>
    </Box>
  );
};

HistoricalDeviceSummaryEirp.propTypes = {
  serialNumber: PropType.string,
  show: PropType.bool.isRequired,
};

HistoricalDeviceSummaryEirp.defaultProps = {
  serialNumber: null,
};

export default HistoricalDeviceSummaryEirp;
