import axios from "axios";

export async function fetchAggregateKpi(filter) {
  return fetchAggregate(filter, "Kpi");
}

export async function fetchAggregateLatency(filter) {
  return fetchAggregate(filter, "Latency");
}

async function fetchAggregate(filter, name) {
  //TODO remove once filter is fixed
  const newFilter = { ...filter };
  if (filter.primaryGlobalVendorExtensions) {
    newFilter.primaryGlobalVendorExtension =
      filter.primaryGlobalVendorExtensions;
  }
  if (filter.primaryRequestVendorExtensions) {
    newFilter.primaryRequestVendorExtension =
      filter.primaryRequestVendorExtensions;
  }

  const response = await axios.post(
    `${window.config.API_ROOT}/aggregate${name}`,
    newFilter,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  // Axios will return the data as a string for invalid json
  // We are expecting an Array so need to check it is an Array
  if (!Array.isArray(response.data)) {
    throw new Error("Response is not a valid JSON array");
  }

  return response.data;
}

export async function fetchKpiGrid(filter) {
  const newFilter = { ...filter };
  if (filter.primaryGlobalVendorExtensions) {
    newFilter.primaryGlobalVendorExtension =
      filter.primaryGlobalVendorExtensions;
  }
  if (filter.primaryRequestVendorExtensions) {
    newFilter.primaryRequestVendorExtension =
      filter.primaryRequestVendorExtensions;
  }

  const response = await axios.post(
    `${window.config.API_ROOT}/kpiGrid`,
    newFilter,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  // Axios will return the data as a string for invalid json
  // We are expecting an Array so need to check it is an Array
  if (!Array.isArray(response.data)) {
    throw new Error("Response is not a valid JSON array");
  }

  return response.data;
}

export async function fetchGlobalExtensionFilters(filter) {
  const response = await axios.post(
    `${window.config.API_ROOT}/filters/primaryGlobalVendorExtensionKpi`,
    filter,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  // Axios will return the data as a string for invalid json
  // We are expecting an Array so need to check it is an Array
  if (!Array.isArray(response.data)) {
    throw new Error("Response is not a valid JSON array");
  }
  return response.data;
}

export async function fetchRequestExtensionFilters(filter) {
  const response = await axios.post(
    `${window.config.API_ROOT}/filters/primaryRequestVendorExtensionKpi`,
    filter,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  // Axios will return the data as a string for invalid json
  // We are expecting an Array so need to check it is an Array
  if (!Array.isArray(response.data)) {
    throw new Error("Response is not a valid JSON array");
  }
  return response.data;
}

export async function fetchFccCertificationFilters(filter) {
  const response = await axios.post(
    `${window.config.API_ROOT}/filters/fccCertificationIdKpi`,
    filter,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  // Axios will return the data as a string for invalid json
  // We are expecting an Array so need to check it is an Array
  if (!Array.isArray(response.data)) {
    throw new Error("Response is not a valid JSON array");
  }
  return response.data;
}
