import {
  Box,
  Divider,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import PropType from "prop-types";
import hash from "object-hash";
import moment from "moment/moment";

const ErrorsTable = ({
  systemErrors,
  pageSize,
  pageNumber,
  onPageChange,
  onRowsPerPageChange,
  isLoading,
}) => {
  const rowCount = systemErrors?.count ?? 0;
  const rows = systemErrors?.data ?? [];
  const showLoading = isLoading ?? false;
  const handleRowsPerPageChange = (event) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };
  const handlePageChange = (_, newNum) => {
    onPageChange(newNum);
  };
  return (
    <Paper
      elevation={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: 1,
        minHeight: 1,
      }}
      data-testid="errors-table"
    >
      <TableContainer sx={{ overflow: "auto", flexGrow: 1 }}>
        <Table
          sx={{
            overflow: "auto",
            maxHeight: 1,
          }}
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>Timestamp (UTC)</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>State Change Reason</TableCell>
            </TableRow>
          </TableHead>
          {showLoading && (
            <TableBody>
              {Array.apply(null, Array(pageSize)).map((_, index) => (
                <TableRow key={index}>
                  <TableCell colSpan={3}>
                    <Skeleton data-testid="loading-spinner" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
          {!showLoading && (
            <TableBody>
              {rows.map((row) => (
                <TableRow key={hash(row)}>
                  <TableCell>
                    {moment.utc(row.timeStamp).toDate().toLocaleDateString()}{" "}
                    {moment.utc(row.timeStamp).toDate().toLocaleTimeString()}
                  </TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.error}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box>
        <Divider />
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowCount}
          rowsPerPage={pageSize}
          page={pageNumber}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </Paper>
  );
};

ErrorsTable.propTypes = {
  systemErrors: PropType.shape({
    count: PropType.number,
    data: PropType.arrayOf(
      PropType.shape({
        error: PropType.string,
        status: PropType.string,
        timeStamp: PropType.string,
      })
    ),
  }),
  pageSize: PropType.number,
  pageNumber: PropType.number,
  onPageChange: PropType.func,
  onRowsPerPageChange: PropType.func,
  isLoading: PropType.bool,
};

export default ErrorsTable;
