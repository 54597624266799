import axios from "axios";

export const fetchHistoricalDeviceSummaryPSDById = async (
  serialNumber,
  intervalType,
  startDate,
  maxPSD
) => {
  const response = await axios.get(
    `${window.config.API_ROOT}/historicalDeviceSummaryPSD`,
    {
      headers: {
        accept: "application/json",
      },
      params: {
        serialNumber,
        intervalType,
        startDate,
        maxPSD,
      },
    }
  );
  return response.data;
};
