import { useDispatch, useSelector } from "react-redux";

import IncumbentsTable from "./IncumbentsTable";
import {
  selectIncumbentContoursList,
  toggleContourHighlight,
} from "../../../redux/incumbentContour/incumbentContourSlice";
import { useMemo } from "react";
import { Box } from "@mui/material";

const Incumbents = () => {
  const dispatch = useDispatch();
  const incumbents = useSelector(selectIncumbentContoursList);
  const mappedIncumbents = useMemo(
    () =>
      incumbents.map((incumbent) => {
        // Will add an opacity to the color depending on whether the contour is selected.
        const opacity = incumbent.isHighlighted ? "0.9" : "0.6";
        const adjustedColour = incumbent.color.replace(")", `, ${opacity})`);
        return {
          id: incumbent.detail.properties.id,
          callsign: incumbent.detail.properties.callsign,
          frequency: incumbent.detail.properties.frequency,
          bandwidth: incumbent.detail.properties.bandwidth,
          color: adjustedColour,
          isHighlighted: incumbent.isHighlighted,
        };
      }),
    [incumbents]
  );
  const handleRowSelect = (incumbentId) => {
    dispatch(toggleContourHighlight(incumbentId));
  };

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
      }}
    >
      <IncumbentsTable
        incumbents={mappedIncumbents}
        onRowSelect={handleRowSelect}
      />
    </Box>
  );
};

export default Incumbents;
