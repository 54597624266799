import PropType from "prop-types";
import { useMemo } from "react";

import { useSelector } from "react-redux";
import { selectUserConfig } from "../../../../redux/userConfig/ConfigSlice";

import { GLOBAL_OPERATING_CLASS_CHANNELS } from "../../../../util/constants";
import ChannelChart from "./ChannelChart";

import {
  makeDisplayTypes,
  toChartColor,
  toChartDescription,
} from "./chartColor";
import PSDChart from "./PSDChart";

const MIN_CHART_VALUE = 5945;
const MAX_CHART_VALUE = 6875;

const CHART_WIDTH = MAX_CHART_VALUE - MIN_CHART_VALUE;

// Add color to the full list of channels, default to grey unavailable
function augmentChannelsWithColor(globalOperatingClass, config, eirpTypes) {
  return GLOBAL_OPERATING_CLASS_CHANNELS[globalOperatingClass].map(
    (channel) => {
      const channelConfig = config.find((c) => c.channelCFI == channel.cfi);
      return {
        ...channel,
        color: channelConfig
          ? toChartColor(channelConfig.maxEIRP, eirpTypes)
          : "grey",
        eirp: channelConfig ? channelConfig.maxEIRP.value : undefined,
        description: channelConfig
          ? toChartDescription(channelConfig.maxEIRP, eirpTypes)
          : undefined,
      };
    }
  );
}

function augmentFrequencyWithColor(freqData, psdTypes) {
  const data = [];
  for (
    let frequency = MIN_CHART_VALUE;
    frequency <= MAX_CHART_VALUE;
    frequency++
  ) {
    const psdInterval = freqData.find(
      (interval) =>
        interval.minFrequency <= frequency && interval.maxFrequency >= frequency
    );
    data.push({
      frequency,
      color: psdInterval ? toChartColor(psdInterval?.maxPSD, psdTypes) : "grey",
      value: psdInterval?.maxPSD?.value,
      description: psdInterval
        ? toChartDescription(psdInterval?.maxPSD, psdTypes)
        : undefined,
    });
  }
  return data;
}

const DeviceChannelConfigChart = ({ config, displayProfileName }) => {
  const { eirpDisplayConfig, psdDisplayConfig } = useSelector(selectUserConfig);

  const eirpTypes = useMemo(
    () => makeDisplayTypes(eirpDisplayConfig[displayProfileName] ?? {}),
    [eirpDisplayConfig, displayProfileName]
  );
  const psdTypes = useMemo(
    () => makeDisplayTypes(psdDisplayConfig[displayProfileName] ?? {}),
    [psdDisplayConfig, displayProfileName]
  );

  // Copy the channel data and add a color attribute for each channel
  const mhz20 = useMemo(
    () => augmentChannelsWithColor(131, config.MHz20, eirpTypes),
    [config]
  );
  const mhz40 = useMemo(
    () => augmentChannelsWithColor(132, config.MHz40, eirpTypes),
    [config]
  );
  const mhz80 = useMemo(
    () => augmentChannelsWithColor(133, config.MHz80, eirpTypes),
    [config]
  );
  const mhz160 = useMemo(
    () => augmentChannelsWithColor(134, config.MHz160, eirpTypes),
    [config]
  );

  const freq = useMemo(
    () => augmentFrequencyWithColor(config.Frequency, psdTypes),
    [config.Frequency]
  );
  return (
    <>
      <ChannelChart
        channelBandwidth={20}
        chartWidth={CHART_WIDTH}
        chartData={mhz20}
        testId="chartMHz20"
        minEirp={-2}
      />
      <ChannelChart
        channelBandwidth={40}
        chartWidth={CHART_WIDTH}
        chartData={mhz40}
        testId="chartMHz40"
        minEirp={1}
      />
      <ChannelChart
        channelBandwidth={80}
        chartWidth={CHART_WIDTH}
        chartData={mhz80}
        testId="chartMHz80"
        minEirp={4}
      />
      <ChannelChart
        channelBandwidth={160}
        chartWidth={CHART_WIDTH}
        chartData={mhz160}
        testId="chartMHz160"
        minEirp={7}
      />
      <PSDChart
        minChartValue={MIN_CHART_VALUE}
        maxChartValue={MAX_CHART_VALUE}
        chartData={freq}
        testId="chartPsd"
      />
    </>
  );
};

DeviceChannelConfigChart.propTypes = {
  config: PropType.shape({
    MHz20: PropType.arrayOf(PropType.object).isRequired,
    MHz40: PropType.arrayOf(PropType.object).isRequired,
    MHz80: PropType.arrayOf(PropType.object).isRequired,
    MHz160: PropType.arrayOf(PropType.object).isRequired,
    Frequency: PropType.arrayOf(PropType.object).isRequired,
  }).isRequired,
  displayProfileName: PropType.string.isRequired,
};

export default DeviceChannelConfigChart;
