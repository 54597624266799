import { useEffect, useMemo } from "react";
import PropType from "prop-types";

import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";

import { HIST_DATE_FORMAT } from "../../../../util/constants";
import { MAX_EIRP_DBM, MIN_EIRP_DBM } from "./util";

import moment from "moment";
import { useState } from "react";
import { TextField } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";
import validate from "./validation";
import intervalTypes from "../../../../util/intervalTypes";

const HistoricalDeviceSummaryEirpFilter = ({ readonly, onChange }) => {
  const initialDate = useMemo(
    () =>
      window.config.DATE_OVERRIDE
        ? moment(window.config.DATE_OVERRIDE)
        : moment(),
    []
  );

  const [filter, setFilter] = useState(() => {
    const startDate = moment(initialDate)
      .utc(true)
      .startOf("day")
      .subtract(30, "day")
      .format(HIST_DATE_FORMAT);
    return {
      intervalType: intervalTypes.DAILY,
      startDate,
      maxEIRP: "28",
      maxStartDate: startDate,
      errors: {},
    };
  });

  const handleFilterChange = (values) => {
    onChange({
      intervalType: values.intervalType.id,
      startDate: values.startDate,
      maxEIRP: values.maxEIRP,
    });
  };

  useEffect(() => {
    handleFilterChange(filter);
  }, []);

  const handleFilterChangeDebounced = useDebouncedCallback(() => {
    handleFilterChange(filter);
  }, 500);

  const handleEirpChange = (newValue) => {
    let newState;
    setFilter((prev) => {
      newState = validate({
        ...prev,
        maxEIRP: newValue,
      });
      return newState;
    });
    handleFilterChangeDebounced();
  };

  const handleIntervalChange = (intervalType) => {
    let newStartDate;
    let selectedIntervalType;
    if (intervalType === intervalTypes.DAILY.id) {
      selectedIntervalType = intervalTypes.DAILY;
      newStartDate = moment(initialDate)
        .startOf("day")
        .subtract(30, "days")
        .format(HIST_DATE_FORMAT);
    }

    if (intervalType === intervalTypes.MONTHLY.id) {
      selectedIntervalType = intervalTypes.MONTHLY;
      newStartDate = moment(initialDate)
        .subtract(11, "months")
        .startOf("month")
        .format(HIST_DATE_FORMAT);
    }

    if (intervalType === intervalTypes.MTD.id) {
      selectedIntervalType = intervalTypes.MTD;
      newStartDate = moment(initialDate)
        .startOf("month")
        .format(HIST_DATE_FORMAT);
    }

    if (intervalType === intervalTypes.YTD.id) {
      selectedIntervalType = intervalTypes.YTD;
      newStartDate = moment(initialDate)
        .startOf("year")
        .format(HIST_DATE_FORMAT);
    }

    let newState;
    setFilter((prev) => {
      newState = validate({
        ...prev,
        startDate: newStartDate,
        maxStartDate: newStartDate,
        intervalType: selectedIntervalType,
      });
      return newState;
    });
    handleFilterChangeDebounced();
  };

  const handleDateChange = (newValue) => {
    let newState;
    let newStartDate = newValue;
    setFilter((prev) => {
      if (newValue === undefined || !moment(newValue).isValid()) {
        newStartDate = prev.maxStartDate;
      } else if (moment(newValue).isAfter(moment(prev.maxStartDate))) {
        newStartDate = prev.maxStartDate;
      }

      if (prev.intervalType.id === intervalTypes.MONTHLY.id) {
        newStartDate = moment(newValue)
          .startOf("month")
          .format(HIST_DATE_FORMAT);
      }

      newState = validate({
        ...prev,
        startDate: newStartDate,
      });
      return newState;
    });
    handleFilterChangeDebounced();
  };

  const disableDateSelection = filter.intervalType?.startDateIgnored;

  return (
    <Grid container columnSpacing={2} sx={{ pt: "1rem" }}>
      <Grid item xs={2}>
        <TextField
          name="maxEIRP"
          type="number"
          label="Target EIRP (dBm)"
          inputProps={{
            min: MIN_EIRP_DBM,
            max: MAX_EIRP_DBM,
            step: 0.01,
          }}
          disabled={readonly}
          onChange={(event) => handleEirpChange(event.target.value)}
          value={filter.maxEIRP}
          error={filter.errors.maxEIRP}
          helperText={filter.errors.maxEIRP}
          sx={{ width: 1 }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          name="intervalType"
          label="Interval"
          select
          disabled={readonly}
          data-testid="intervalType"
          onChange={(event) => handleIntervalChange(event.target.value)}
          value={filter.intervalType.id}
          sx={{ width: 1 }}
        >
          {Object.entries(intervalTypes).map(([k, v]) => (
            <MenuItem key={k} value={v.id}>
              {v.description}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <TextField
          name="startDate"
          type="date"
          label="Start Date"
          disabled={readonly || disableDateSelection}
          onChange={(event) => handleDateChange(event.target.value)}
          value={filter.startDate}
          inputProps={{
            max: filter.maxStartDate,
          }}
          error={filter.errors.startDate}
          helperText={filter.errors.startDate}
          sx={{ width: 1 }}
        />
      </Grid>
    </Grid>
  );
};

HistoricalDeviceSummaryEirpFilter.propTypes = {
  readonly: PropType.bool.isRequired,
  onChange: PropType.func.isRequired,
};

export default HistoricalDeviceSummaryEirpFilter;
