import PropType from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearIncumbentMap,
  displayContour,
  fetchIncumbentContourById,
  removeContour,
  selectIncumbentDetailsList,
  toggleContourHighlight,
} from "../../../redux/incumbentContour/incumbentContourSlice";
import IncumbentDialog from "./IncumbentDialog";

const IncumbentDialogContainer = ({ incumbentIdList, onClose }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (incumbentIdList.length > 0) {
      dispatch(fetchIncumbentContourById(incumbentIdList));
    }
  }, [incumbentIdList]);

  const incumbentDetailList = useSelector(selectIncumbentDetailsList);

  // Only render if we are given a list of incumbents
  if (incumbentIdList.length === 0) {
    return null;
  }

  const handleClose = () => {
    dispatch(clearIncumbentMap());
    onClose();
  };

  return (
    <IncumbentDialog
      onClose={handleClose}
      incumbentDetailList={incumbentDetailList}
      numberOfIncumbents={incumbentIdList.length}
      onShowContour={(incumbentId) => dispatch(displayContour([incumbentId]))}
      onHideContour={(incumbentId) => dispatch(removeContour(incumbentId))}
      onToggleHighlight={(incumbentId) =>
        dispatch(toggleContourHighlight(incumbentId))
      }
    />
  );
};

IncumbentDialogContainer.propTypes = {
  incumbentIdList: PropType.arrayOf(PropType.number).isRequired,
  onClose: PropType.func.isRequired,
};

export default IncumbentDialogContainer;
