import axios from "axios";

export async function fetchSystemErrors(
  startDate,
  endDate,
  pageNumber,
  pageSize
) {
  const response = await axios.get(`${window.config.API_ROOT}/systemErrors`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: {
      pageNumber,
      pageSize,
      startDate,
      endDate,
    },
  });

  return response.data;
}
