import axios from "axios";

export async function fetchUserConfig() {
  const response = await axios.get(`${window.config.API_ROOT}/config`, {
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
}
