import axios from "axios";

export async function fetchPrimaryGlobalVendorExtension(lookupFilter) {
  const response = await axios.post(
    `${window.config.API_ROOT}/filters/primaryGlobalVendorExtension`,
    lookupFilter ?? {},
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return response.data;
}

export async function fetchPrimaryRequestVendorExtension(lookupFilter) {
  const response = await axios.post(
    `${window.config.API_ROOT}/filters/primaryRequestVendorExtension`,
    lookupFilter ?? {},
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return response.data;
}

export async function fetchGlobalOperatingClass() {
  const response = await axios.post(
    `${window.config.API_ROOT}/filters/globalOperatingClass`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return response.data;
}

export async function fetchFrequencyBand() {
  const response = await axios.post(
    `${window.config.API_ROOT}/filters/frequencyBand`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return response.data;
}

export async function fetchMaxHeight() {
  const response = await axios.post(
    `${window.config.API_ROOT}/filters/maxHeight`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return response.data;
}

export async function fetchDeviceSerialNumbers(lookupFilter) {
  const response = await axios.post(
    `${window.config.API_ROOT}/filters/serialNumber`,
    lookupFilter ?? {},
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  if (response.data.length > 1000) {
    return [];
  }
  return response.data;
}

export async function fetchCertificationFccIds(lookupFilter) {
  const response = await axios.post(
    `${window.config.API_ROOT}/filters/fccCertificationId`,
    lookupFilter ?? {},
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  if (response.data.length > 1000) {
    return [];
  }
  return response.data;
}
