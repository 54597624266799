import PropType from "prop-types";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

const FrequencyBandFilter = ({ options, value, onChange }) => {
  const handleButton = (_, newValue) => {
    if (newValue.length === 0) {
      onChange(undefined);
    } else {
      onChange(newValue);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Typography sx={{ paddingRight: "30px" }}>Frequency Band</Typography>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ToggleButtonGroup
          value={value}
          onChange={handleButton}
          aria-label="frequency band"
        >
          {options &&
            options.map((band) => (
              <ToggleButton key={band.id} value={band.id}>
                {band.name}
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

FrequencyBandFilter.propTypes = {
  options: PropType.arrayOf(
    PropType.shape({
      id: PropType.number,
      name: PropType.string,
    })
  ),
  value: PropType.arrayOf(PropType.number),
  onChange: PropType.func.isRequired,
};

export default FrequencyBandFilter;
