const intervalTypes = {
  DAILY: {
    id: "DAILY",
    description: "Daily",
  },
  MONTHLY: {
    id: "MONTHLY",
    description: "Monthly",
  },
  MTD: {
    id: "MTD",
    description: "Month to date",
    startDateIgnored: true,
  },
  YTD: {
    id: "YTD",
    description: "Year to date",
    startDateIgnored: true,
  },
};

export default intervalTypes;
