import moment from "moment";

export const DEFAULT_CHART_OPTIONS = {
  vAxis: {
    title: "% Above Target",
  },
  focusTarget: "category",
  theme: "material",
};

const dataDateFormat = "MMMM D, YYYY";

export const dateToFormattedDate = (db_date) => {
  const date = moment(db_date).utc();
  return { v: date.toDate(), f: date.format(dataDateFormat) };
};

const tickDateFormat = "MM/DD";

export const toHistoricalDeviceSummaryChartTicks = (
  dataSortedAndGroupedByDate
) =>
  dataSortedAndGroupedByDate
    .map(([d]) => moment(d).utc())
    .map((d) => ({ v: d.toDate(), f: d.format(tickDateFormat) }));
