export const deviceMarker = window.btoa(`
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
  <circle fill="#0000ff" cx="256" cy="256" opacity=".6" r="256" />
  <g fill="white" stroke="white" transform="translate(64 64) scale(0.75 0.75)">
    <path d="M423.29,213.04c11.13,10.58,12.24,27.82,1.67,38.95c-5.56,6.12-12.8,8.9-20.59,8.9c-6.67,0-13.35-2.22-18.92-7.23
      c-123.54-112.97-256.55-5.56-262.11-1.11c-11.68,10.02-29.49,8.35-38.95-3.34c-10.02-11.68-8.35-28.94,3.34-38.95
      C148.38,159.62,295.3,95.62,423.29,213.04z M257.46,367.74c-13.91,0-27.82,5.56-37.29,15.58c-10.02,10.02-15.58,23.37-15.58,37.29
      c0,13.91,5.56,27.27,15.58,37.29c10.02,10.02,23.37,15.58,37.29,15.58c13.91,0,27.82-5.56,37.29-15.58
      c10.02-10.02,15.58-23.37,15.58-37.29c0-13.91-5.56-27.26-15.58-37.29C284.72,373.31,271.37,367.74,257.46,367.74z M502.31,137.36
      C370.42,22.16,242.99,28.28,158.96,53.88C69.37,81.15,10.93,134.57,8.71,137.36c-11.13,10.57-11.68,27.82-1.11,38.96
      c10.58,11.13,28.38,11.68,39.51,1.11c0.55-0.55,51.19-47.3,128.55-70.67c102.4-31.16,199.78-6.68,290.49,72.35
      c5.01,4.45,11.68,6.67,18.36,6.67c7.79,0,15.58-3.34,21.15-9.46C515.11,165.18,514,147.37,502.31,137.36z M163.97,289.28
      c-12.24,9.46-13.91,26.71-4.46,38.95c9.46,12.24,27.27,13.91,38.95,4.45c2.23-1.67,61.21-47.3,111.3-0.55
      c5.56,5.01,12.24,7.23,18.92,7.23c7.23,0,15.03-2.78,20.59-8.91c10.57-11.13,10.02-28.93-1.67-38.95
      C280.27,229.18,197.91,263.12,163.97,289.28z"/>
  </g>
</svg>`);
