import axios from "axios";

export const fetchTokens = async () => {
  try {
    const response = await axios.get(
      `${window.config.TOKEN_API_ROOT}/listTokens`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching tokens:", error);
    throw error;
  }
};

export const fetchTokenByOrgId = async (orgId) => {
  try {
    const response = await axios.get(
      `${window.config.TOKEN_API_ROOT}/listTokens/${orgId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching tokens:", error);
    throw error;
  }
};

export const createTokens = async (tokenName, orgId) => {
  const body = {
    tokenName: tokenName,
    org: orgId,
  };

  try {
    const response = await axios.post(
      `${window.config.TOKEN_API_ROOT}/generateToken`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating token:", error);
    throw error;
  }
};

export const deleteToken = async (orgId, tokenName) => {
  try {
    const response = await axios.delete(
      `${window.config.TOKEN_API_ROOT}/deleteToken/${orgId}/${tokenName}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    if (response.status === 204) {
      return {};
    } else {
      return response.data;
    }
  } catch (error) {
    console.error("Error deleting token:", error);
    throw error;
  }
};
