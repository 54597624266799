import PropType from "prop-types";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import Skeleton from "@mui/material/Skeleton";

const DeviceDetailSkeleton = ({ error }) => {
  return (
    <>
      {error && (
        <Alert severity="error" style={{ width: "90%", marginLeft: "5%" }}>
          <AlertTitle>Error - Loading Device Details</AlertTitle>
          {error}
        </Alert>
      )}
      <Skeleton
        variant="rectangular"
        height={650}
        sx={{ margin: "1rem" }}
        animation={!error ? "pulse" : false}
        data-testid="skeletonDeviceDetail"
      />
    </>
  );
};

DeviceDetailSkeleton.propTypes = {
  error: PropType.string,
};

DeviceDetailSkeleton.defaultProps = {
  error: null,
};

export default DeviceDetailSkeleton;
