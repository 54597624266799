import PropType from "prop-types";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import DeviceChannelConfigSkeletonChart from "./DeviceChannelConfigSkeletonChart";

const DeviceChannelConfigSkeleton = ({ error }) => {
  return (
    <>
      {error && (
        <Alert severity="error" style={{ width: "90%", marginLeft: "5%" }}>
          <AlertTitle>Error - Loading Device Spectrum Availability</AlertTitle>
          {error}
        </Alert>
      )}
      <DeviceChannelConfigSkeletonChart
        animate={!error}
        data-testid="skeletonChartMHz20"
      />
      <DeviceChannelConfigSkeletonChart
        animate={!error}
        data-testid="skeletonChartMHz40"
      />
      <DeviceChannelConfigSkeletonChart
        animate={!error}
        data-testid="skeletonChartMHz80"
      />
      <DeviceChannelConfigSkeletonChart
        animate={!error}
        data-testid="skeletonChartMHz160"
      />
      <DeviceChannelConfigSkeletonChart
        animate={!error}
        data-testid="skeletonChartPsd"
      />
    </>
  );
};

DeviceChannelConfigSkeleton.propTypes = {
  error: PropType.string,
};

DeviceChannelConfigSkeleton.defaultProps = {
  error: null,
};

export default DeviceChannelConfigSkeleton;
