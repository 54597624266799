import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchMaxPsdPerBand as fetchMaxPsdPerBandApi } from "./maxPsdPerBandAPI";
import { error } from "../notification/notificationSlice";
import { deepEqual } from "fast-equals";

export const initialState = {
  resultList: [],
  isFetchingMaxPsdPerBand: false,
  currentFilter: undefined,
};

export const fetchMaxPsdPerBand = createAsyncThunk(
  "maxPsdPerBand/fetch",
  async (deviceFilter, thunkApi) => {
    try {
      thunkApi.dispatch(updateCurrentFilter(deviceFilter));
      return await fetchMaxPsdPerBandApi(deviceFilter);
    } catch (err) {
      thunkApi.dispatch(error(err?.message, "Loading PSD per band data"));
      return thunkApi.rejectWithValue(err?.message);
    }
  },
  {
    condition: (newFilter, { getState }) => {
      const {
        maxPsdPerBand: { currentFilter },
      } = getState();
      if (deepEqual(currentFilter, newFilter)) {
        return false;
      }
      return true;
    },
  }
);

export const maxPsdPerBandSlice = createSlice({
  name: "maxPsdPerBand",
  initialState,
  reducers: {
    updateCurrentFilter(state, action) {
      state.currentFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMaxPsdPerBand.pending, (state) => {
        state.isFetchingMaxPsdPerBand = true;
      })
      .addCase(fetchMaxPsdPerBand.fulfilled, (state, action) => {
        state.isFetchingMaxPsdPerBand = false;
        state.resultList = action.payload ?? [];
      })
      .addCase(fetchMaxPsdPerBand.rejected, (state, action) => {
        state.isFetchingMaxPsdPerBand = false;
        state.resultList = undefined;
        state.currentFilter = undefined;
      });
  },
});
export const { updateCurrentFilter } = maxPsdPerBandSlice.actions;

export const selectMaxPsdPerBand = (state) => state.maxPsdPerBand.resultList;
export const selectIsFetchingMaxPsdPerBand = (state) =>
  state.maxPsdPerBand.isFetchingMaxPsdPerBand;

export default maxPsdPerBandSlice.reducer;
