import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Box,
  Tooltip,
} from "@mui/material";
import PropType from "prop-types";
import hash from "object-hash";
import { useState } from "react";

const KpiGridTable = ({ globalExtensionName, requestExtensionName, rows }) => {
  const toPercentage = (value) => {
    if (value) {
      return (value * 100).toFixed(2);
    }
    return undefined;
  };

  const descendingComparator = (a, b, orderBy) => {
    if (!a[orderBy] && !b[orderBy]) {
      return 0;
    }
    if (!a[orderBy]) {
      return 1;
    }
    if (!b[orderBy]) {
      return -1;
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const descendingCountComparator = (a, b, orderBy) => {
    if (!a[orderBy] && !b[orderBy]) {
      return 0;
    }
    if (!a[orderBy]) {
      return 1;
    }
    if (!b[orderBy]) {
      return -1;
    }

    if (b[orderBy].count < a[orderBy].count) {
      return -1;
    }
    if (b[orderBy].count > a[orderBy].count) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    let comparator;
    if (
      [
        "latency",
        "perDeviceLatency",
        "primaryRequestVendorExtension",
        "primaryGlobalVendorExtension",
      ].includes(orderBy)
    ) {
      comparator = descendingComparator;
    } else {
      comparator = descendingCountComparator;
    }

    return order === "desc"
      ? (a, b) => comparator(a, b, orderBy)
      : (a, b) => -comparator(a, b, orderBy);
  };

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: "latency",
      numeric: true,
      label: "Latency/Request (ms)",
    },
    {
      id: "perDeviceLatency",
      numeric: true,
      label: "Latency/Device (ms)",
    },
    {
      id: "success",
      numeric: true,
      label: "Success",
    },
    {
      id: "deviceDisallowed",
      numeric: true,
      label: "Device Disallowed",
    },
    {
      id: "generalFailure",
      numeric: true,
      label: "General Failure",
    },
    {
      id: "invalidValue",
      numeric: true,
      label: "Invalid Value",
    },
    {
      id: "missingParm",
      numeric: true,
      label: "Missing Param",
    },
    {
      id: "unexpectedParam",
      numeric: true,
      label: "Unexpected Param",
    },
    {
      id: "unspecifiedClientError",
      numeric: true,
      label: "Unspecified Client Error",
    },
    {
      id: "unspecifiedServerError",
      numeric: true,
      label: "Unspecified Server Error",
    },
    {
      id: "unsupportedSpectrum",
      numeric: true,
      label: "Unspupported Spectrum",
    },
  ];

  if (requestExtensionName) {
    headCells.splice(0, 0, {
      id: "primaryRequestVendorExtension",
      numeric: false,
      label: requestExtensionName,
    });
  }
  if (globalExtensionName) {
    headCells.splice(0, 0, {
      id: "primaryGlobalVendorExtension",
      numeric: false,
      label: globalExtensionName,
    });
  }

  const EnhancedTableHead = (props) => {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  EnhancedTableHead.propTypes = {
    onRequestSort: PropType.func.isRequired,
    order: PropType.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropType.string.isRequired,
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("latency");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  if (!rows) {
    return null;
  }

  return (
    <TableContainer
      component={Paper}
      elevation={3}
      sx={{ paddingLeft: "1ch", paddingRight: "1ch", paddingTop: 0 }}
    >
      <Table
        sx={{
          width: "auto",
          maxHeight: 1,
          height: "fit-content",
          overflow: "auto",
        }}
        stickyHeader
      >
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
            return (
              <TableRow key={hash(row)}>
                {globalExtensionName && row.primaryGlobalVendorExtension && (
                  <TableCell>{row.primaryGlobalVendorExtension}</TableCell>
                )}
                {globalExtensionName && !row.primaryGlobalVendorExtension && (
                  <TableCell>{`All ${globalExtensionName}s`}</TableCell>
                )}

                {requestExtensionName && row.primaryRequestVendorExtension && (
                  <TableCell>{row.primaryRequestVendorExtension}</TableCell>
                )}
                {requestExtensionName && !row.primaryRequestVendorExtension && (
                  <TableCell>{`All ${requestExtensionName}s`}</TableCell>
                )}
                <TableCell align="right">{row.latency ?? "-"}</TableCell>
                <TableCell align="right">
                  {row.perDeviceLatency ?? "-"}
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={`${toPercentage(row.success?.percentage) ?? "-"}%`}
                  >
                    <span>{row.success?.count ?? "-"}</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={`${
                      toPercentage(row.deviceDisallowed?.percentage) ?? "-"
                    }%`}
                  >
                    <span>{row.deviceDisallowed?.count ?? "-"}</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={`${
                      toPercentage(row.generalFailure?.percentage) ?? "-"
                    }%`}
                  >
                    <span>{row.generalFailure?.count ?? "-"}</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={`${
                      toPercentage(row.invalidValue?.percentage) ?? "-"
                    }%`}
                  >
                    <span>{row.invalidValue?.count ?? "-"}</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={`${
                      toPercentage(row.missingParam?.percentage) ?? "-"
                    }%`}
                  >
                    <span>{row.missingParam?.count ?? "-"}</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={`${
                      toPercentage(row.unexpectedParam?.percentage) ?? "-"
                    }%`}
                  >
                    <span>{row.unexpectedParam?.count ?? "-"}</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={`${
                      toPercentage(row.unspecifiedClientError?.percentage) ??
                      "-"
                    }%`}
                  >
                    <span>{row.unspecifiedClientError?.count ?? "-"}</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={`${
                      toPercentage(row.unspecifiedServerError?.percentage) ??
                      "-"
                    }%`}
                  >
                    <span>{row.unspecifiedServerError?.count ?? "-"}</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={`${
                      toPercentage(row.unsupportedSpectrum?.percentage) ?? "-"
                    }%`}
                  >
                    <span>{row.unsupportedSpectrum?.count ?? "-"}</span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const errorPropType = PropType.shape({
  count: PropType.number,
  percentage: PropType.number,
});

KpiGridTable.propTypes = {
  globalExtensionName: PropType.string,
  requestExtensionName: PropType.string,
  rows: PropType.arrayOf(
    PropType.shape({
      primaryGlobalVendorExtension: PropType.string,
      primaryRequestVendorExtension: PropType.string,
      latency: PropType.number,
      per_device_latency: PropType.number,
      success: errorPropType,
      deviceDisallowed: errorPropType,
      generalFailure: errorPropType,
      invalidValue: errorPropType,
      missingParam: errorPropType,
      unexpectedParam: errorPropType,
      unspecifiedClientError: errorPropType,
      unspecifiedServerError: errorPropType,
      unsupportedSpectrum: errorPropType,
    })
  ),
};

export default KpiGridTable;
