import React, { useState, useEffect } from "react";
import "./App.css";
import { Backdrop, CircularProgress, List, Stack } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import SpeedIcon from "@mui/icons-material/Speed";
import PeopleIcon from "@mui/icons-material/People";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import MenuListItem from "./components/Layout/MenuListItem";
import DeviceInformation from "./components/DeviceInformation";
import TopAppBar from "./components/Layout/TopAppBar";
import { handleLogout } from "./components/AmplifyHub/AmplifyHubBridge";
import Notification from "./components/Notification";
import { useSelector } from "react-redux";
import { selectIsUpdatePending } from "./redux/store";
import { selectUserConfig } from "./redux/userConfig/ConfigSlice";
import KpiSystem from "./components/Kpi/System/KpiSystem";
import SystemErrors from "./components/Kpi/SystemErrors/SystemErrors";
import ErrorBoundary from "./components/ErrorBoundary";
import EditProfile from "./components/UserManagement/EditProfile";
import UserAndOrgs from "./components/UserManagement/UsersAndOrgs";
import { Auth } from "aws-amplify";
export const Paths = {
  deviceInformation: "/",
  kpiSystem: "/analytics/kpi/system",
  systemErrors: "/analytics/kpi/errors",
  userManagement: "/user-data",
};

export const usersIconVisible = async () => {
  try {
    const session = await Auth.currentSession();
    const adminOrgIds = session.getIdToken().payload.administrator_org_ids;
    return adminOrgIds && adminOrgIds.length > 0;
  } catch (error) {
    console.error("Error checking user permissions", error);
    return false;
  }
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<DeviceInformation />} />
        <Route path={Paths.kpiSystem} element={<KpiSystem />} />
        <Route path={Paths.systemErrors} element={<SystemErrors />} />

        {/* Redirect routes */}
        <Route path="/analytics" element={<Navigate to={Paths.kpiSystem} />} />
        <Route path="*" element={<Navigate to="/" />} />

        {/* user management routes */}
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path={Paths.userManagement} element={<UserAndOrgs />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  const isUpdatePending = useSelector(selectIsUpdatePending);
  const userConfig = useSelector(selectUserConfig);
  return (
    <>
      <Notification />
      <Stack
        className="App"
        sx={{
          width: 1,
          height: 1,
        }}
      >
        <Box sx={{ bgcolor: "pantone.darkBlue", height: "60px" }}>
          <TopAppBar
            onLogout={handleLogout}
            dateOverride={window.config.DATE_OVERRIDE}
          />
        </Box>
        <Box
          className="AppCon"
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "calc(100vh - 60px)",
            overflow: "hidden",
          }}
        >
          <SideMenu />
          <Box sx={{ flexGrow: "1", maxWidth: "calc(100vw - 60px)" }}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isUpdatePending || !userConfig}
              data-testid="busy-backdrop"
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            {!!userConfig && (
              <ErrorBoundary>
                <Outlet />
              </ErrorBoundary>
            )}
          </Box>
        </Box>
      </Stack>
    </>
  );
}

function SideMenu() {
  const location = useLocation();
  const [showUsersIcon, setShowUsersIcon] = useState(false);

  useEffect(() => {
    const checkVisibility = async () => {
      const isVisible = await usersIconVisible();
      setShowUsersIcon(isVisible);
    };

    checkVisibility();
  }, []);
  return (
    <Box
      sx={{
        bgcolor: "pantone.darkBlue",
        color: "white",
        display: "flex",
        flexDirection: "column",
        width: "60px",
      }}
    >
      <List>
        <MenuListItem
          isSelected={location.pathname == Paths.deviceInformation}
          to={Paths.deviceInformation}
          tooltip="Device Information"
        >
          <MapIcon />
        </MenuListItem>
        {window.config.ENABLE_KPI && (
          <>
            <MenuListItem
              isSelected={location.pathname == Paths.kpiSystem}
              to={Paths.kpiSystem}
              tooltip="Aggregate Device KPI"
            >
              <SpeedIcon />
            </MenuListItem>
            <MenuListItem
              isSelected={location.pathname == Paths.systemErrors}
              to={Paths.systemErrors}
              tooltip="System Health"
            >
              <WysiwygIcon />
            </MenuListItem>
          </>
        )}
        {showUsersIcon && (
          <MenuListItem
            isSelected={location.pathname == Paths.userManagement}
            to={Paths.userManagement}
            tooltip="Users and Organizations"
          >
            <PeopleIcon />
          </MenuListItem>
        )}
      </List>
    </Box>
  );
}

export default App;
