import PropType from "prop-types";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

import DeviceChannelConfigLegendItem from "./DeviceChannelConfigLegendItem";

import { makeDisplayTypes } from "./chartColor";
import { Box } from "@mui/material";

import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectUserConfig } from "../../../../redux/userConfig/ConfigSlice";

const DeviceChannelConfigLegend = ({ profile }) => {
  const { eirpDisplayConfig, psdDisplayConfig } = useSelector(selectUserConfig);
  const eirpTypes = useMemo(
    () => makeDisplayTypes(eirpDisplayConfig[profile] ?? {}),
    [eirpDisplayConfig]
  );
  const psdTypes = useMemo(
    () => makeDisplayTypes(psdDisplayConfig[profile] ?? {}),
    [psdDisplayConfig]
  );
  return (
    <Box>
      <Box
        sx={{
          borderWidth: 1,
          borderColor: "black",
          borderStyle: "solid",
          borderRadius: "3px",
          marginBottom: "2px",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          sx={{ fontSize: "1rem", textDecoration: "underline" }}
        >
          maxEIRP
        </Typography>
        <List dense={true} disablePadding={true}>
          {Object.entries(eirpTypes).map(([k, et]) => (
            <DeviceChannelConfigLegendItem key={k} item={et} />
          ))}
        </List>
      </Box>
      <Box
        sx={{
          borderWidth: 1,
          borderColor: "black",
          borderStyle: "solid",
          borderRadius: "3px",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          sx={{
            fontSize: "1rem",
            textDecoration: "underline",
            paddingTop: "8px",
          }}
        >
          PSD
        </Typography>
        <List dense={true} disablePadding={true}>
          {Object.entries(psdTypes).map(([k, pt]) => (
            <DeviceChannelConfigLegendItem key={k} item={pt} />
          ))}
        </List>
      </Box>
    </Box>
  );
};

DeviceChannelConfigLegend.propTypes = {
  profile: PropType.string.isRequired,
};

export default DeviceChannelConfigLegend;
