import PropType from "prop-types";
import { Autocomplete, FormControl, TextField } from "@mui/material";

const DeviceSerialNumberFilter = ({
  value,
  onChange,
  onInputChange,
  onClose,
  labelName = "Serial Number",
  options = [],
}) => {
  const handleChange = (_, newValue) => {
    if (newValue && newValue.length === 0) {
      onChange(undefined);
    } else {
      onChange(newValue);
    }
  };

  const handleInputChange = (_, newValue) => {
    onInputChange(newValue);
  };

  return (
    <FormControl sx={{ width: "500px" }}>
      <Autocomplete
        freeSolo
        multiple
        disableCloseOnSelect
        filterOptions={(x) => x}
        options={options}
        value={value || []}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onClose={onClose}
        fullWidth
        data-testid="serial-number-filter"
        renderInput={(params) => <TextField {...params} label={labelName} />}
      />
    </FormControl>
  );
};

DeviceSerialNumberFilter.propTypes = {
  value: PropType.arrayOf(PropType.string),
  onChange: PropType.func.isRequired,
  onInputChange: PropType.func.isRequired,
  onClose: PropType.func.isRequired,
  labelName: PropType.string,
  options: PropType.arrayOf(PropType.string),
};

export default DeviceSerialNumberFilter;
