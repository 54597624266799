import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  filter: {},
};

export const systemKpiFilterSlice = createSlice({
  name: "systemKpiFilter",
  initialState,
  reducers: {
    updateSystemKpiFilter: {
      reducer: (state, action) => {
        state.filter = action.payload;
      },
      prepare: (newFilter) => {
        return { payload: newFilter };
      },
    },
  },
});

export const { updateSystemKpiFilter } = systemKpiFilterSlice.actions;

export const selectSystemKpiFilter = (state) => state.systemKpiFilter.filter;

export default systemKpiFilterSlice.reducer;
