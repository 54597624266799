import { Box, Dialog, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropType from "prop-types";
import { deviceMarker } from "../DeviceMap/DeviceIcon";
import {
  exampleIncumbentButton,
  incumbentMarker,
} from "../DeviceMap/IncumbentIcon";
import Grid from "@mui/material/Grid";
import { LegendButtonIcon } from "../DeviceMap/LegendIcon";
import IncumbentLegend from "../IncumbentLegend";

const LegendSectionRow = ({ sectionTitle }) => (
  <Grid item xs={12} marginTop={1}>
    <Typography variant={"h5"} component="h5">
      {sectionTitle}
    </Typography>
  </Grid>
);

LegendSectionRow.propTypes = {
  sectionTitle: PropType.string.isRequired,
};

const LegendRow = ({ iconSource, legendText }) => (
  <>
    <Grid item xs={1}>
      <img width={"24px"} src={iconSource} />
    </Grid>
    <Grid item xs={11} paddingLeft={1} textAlign={"right"}>
      {legendText}
    </Grid>
  </>
);

LegendRow.propTypes = {
  iconSource: PropType.string.isRequired,
  legendText: PropType.string.isRequired,
};

const coloredMarkerSvg = (color) =>
  window.btoa(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle fill="${color}" cx="120" cy="120" opacity=".6" r="70" />
    <circle fill="${color}" cx="120" cy="120" opacity=".3" r="90" />
    <circle fill="${color}" cx="120" cy="120" opacity=".2" r="110" />
    <text xmlns="http://www.w3.org/2000/svg" fill="#fff" style="font-size: 120px" dx="90" dy="160">5</text>
    </svg>`);

const MapLegend = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: "280px",
          padding: "0",
          margin: "1em 1.5em 1.2em 1.5em",
        }}
        data-testid="map-legend"
      >
        <Box sx={{ display: "flex" }}>
          <IconButton
            style={{ position: "absolute", right: 0 }}
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Grid container spacing={0}>
          <LegendSectionRow sectionTitle={"Icons"} />
          <LegendRow
            iconSource={`data:image/svg+xml;base64,${deviceMarker}`}
            legendText={"Single AFC device"}
          />
          <LegendRow
            iconSource={`data:image/svg+xml;base64,${coloredMarkerSvg("blue")}`}
            legendText={"AFC device cluster"}
          />
          <LegendRow
            iconSource={`data:image/svg+xml;base64,${incumbentMarker}`}
            legendText={"Single spectrum incumbent"}
          />
          <LegendRow
            iconSource={`data:image/svg+xml;base64,${coloredMarkerSvg("red")}`}
            legendText={"Spectrum incumbent cluster"}
          />
          <LegendSectionRow sectionTitle={"Controls"} />
          <LegendRow
            iconSource={`data:image/svg+xml;base64,${LegendButtonIcon}`}
            legendText={"Show this legend"}
          />
          <LegendRow
            iconSource={`data:image/svg+xml;base64,${exampleIncumbentButton}`}
            legendText={"Show incumbent contours"}
          />
          <LegendRow
            legendText={"Zoom-in map"}
            iconSource={
              "data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23111%22%20d%3D%22M18%207h-7V0H7v7H0v4h7v7h4v-7h7z%22/%3E%3C/svg%3E"
            }
          />
          <LegendRow
            legendText={"Zoom-out map"}
            iconSource={
              "data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23111%22%20d%3D%22M0%207h18v4H0V7z%22/%3E%3C/svg%3E"
            }
          />
          <Grid item xs={12}>
            <Typography textAlign={"center"}>
              Incumbent contours are colored by center frequency (5925-6875 MHz)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <IncumbentLegend />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

MapLegend.propTypes = {
  onClose: PropType.func.isRequired,
  isOpen: PropType.bool.isRequired,
};

export default MapLegend;
