import axios from "axios";

export const fetchDeviceLatencyReportQueryId = async ({
  serialNumber,
  customerId,
  organizationId,
}) => {
  const response = await axios.get(
    `${window.config.REPORTING_API_ROOT}/device-history/latency`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        "customer-id": customerId,
        "organization-id": organizationId,
        "device-serial-number": serialNumber,
      },
    }
  );
  const { queryId } = response.data;
  return queryId;
};

export const fetchDeviceLatencyReportData = async ({ queryId }) => {
  const response = await axios.get(
    `${window.config.REPORTING_API_ROOT}/device-history/latency/${queryId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "text/csv",
      },
    }
  );
  if (response.status === 202) {
    return null;
  }
  return response.data;
};
