import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { error } from "../notification/notificationSlice";

import { fetchDeviceDetailById } from "./deviceDetailAPI";

export const initialState = {
  loading: false,
  selectedDeviceMap: {},
};

const errorMessage = (err) => {
  if (err?.response?.status === 404) {
    return "The device details could not be found";
  }
  return err?.message;
};

// fetches a single device detail
export const fetchDeviceDetail = createAsyncThunk(
  "deviceDetail/fetch",
  async (deviceId, thunkApi) => {
    try {
      return await fetchDeviceDetailById(deviceId);
    } catch (err) {
      // Not dispatching notification - errors will be shown in dialog
      return thunkApi.rejectWithValue(errorMessage(err));
    }
  }
);

// fetches the device detail for all the Ids in the list
export const fetchAllDeviceDetail = createAsyncThunk(
  "deviceDetail/fetchAll",
  async (deviceIdList, thunkApi) => {
    try {
      await Promise.all(
        deviceIdList.map(async (deviceId) => {
          const device = await fetchDeviceDetailById(deviceId);
          // Need to add the deviceId to the response to be able to reference the result
          thunkApi.dispatch(addOrReplaceDevice({ ...device, id: deviceId }));
        })
      );
    } catch (err) {
      const message = errorMessage(err);

      thunkApi.dispatch(error(message, "Loading device detail"));
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const deviceDetailSlice = createSlice({
  name: "deviceDetail",
  initialState,
  reducers: {
    addOrReplaceDevice(state, action) {
      const deviceDetail = action.payload;
      const { selectedDeviceMap } = state;
      const deviceId = deviceDetail.id;
      selectedDeviceMap[deviceId] = deviceDetail;
    },
    clearSelectedDevices(state) {
      state.selectedDeviceMap = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeviceDetail.pending, (state) => {
        state.loading = true;
        state.current = null;
        state.error = null;
      })
      .addCase(fetchDeviceDetail.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.current = payload;
      })
      .addCase(fetchDeviceDetail.rejected, (state, { payload, error }) => {
        state.loading = false;
        state.error = payload || error.message;
      })

      .addCase(fetchAllDeviceDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllDeviceDetail.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchAllDeviceDetail.rejected, (state) => {
        state.loading = false;
        state.selectedDeviceMap = {};
      });
  },
});

export const { addOrReplaceDevice, clearSelectedDevices } =
  deviceDetailSlice.actions;

export const selectDeviceDetail = (state) => state.deviceDetail;
export const selectDeviceDetailList = createSelector(
  selectDeviceDetail,
  (deviceDetail) => {
    const { selectedDeviceMap } = deviceDetail;
    const keyNames = Object.getOwnPropertyNames(selectedDeviceMap);
    const deviceDetailList = keyNames.map(
      (deviceId) => selectedDeviceMap[deviceId]
    );
    return deviceDetailList;
  }
);

export default deviceDetailSlice.reducer;
