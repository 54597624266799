import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { downloadUsersCSV, downloadOrgsCSV } from "./downloadCsvAPI";

export const initialState = {
  loading: false,
  error: null,
};

export const initiateUsersCSVDownload = createAsyncThunk(
  "usersCsvDownload/initiate",
  async (_, thunkApi) => {
    try {
      const response = await downloadUsersCSV();
      const blob = new Blob([response.data], {
        type: "text/csv;charset=utf-8;",
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "users.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();

      return { status: "success", message: "Users CSV download initiated" };
    } catch (error) {
      return thunkApi.rejectWithValue(error.toString());
    }
  }
);

const usersCsvDownloadSlice = createSlice({
  name: "usersCsvDownload",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(initiateUsersCSVDownload.pending, (state) => {
        state.loading = true;
      })
      .addCase(initiateUsersCSVDownload.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(initiateUsersCSVDownload.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const initiateOrgsCSVDownload = createAsyncThunk(
  "orgsCsvDownload/initiate",
  async (_, thunkApi) => {
    try {
      const response = await downloadOrgsCSV();
      const blob = new Blob([response.data], {
        type: "text/csv;charset=utf-8;",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "organizations.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();

      return {
        status: "success",
        message: "Organizations CSV download initiated",
      };
    } catch (error) {
      return thunkApi.rejectWithValue(error.toString());
    }
  }
);

const orgsCsvDownloadSlice = createSlice({
  name: "orgsCsvDownload",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(initiateOrgsCSVDownload.pending, (state) => {
        state.loading = true;
      })
      .addCase(initiateOrgsCSVDownload.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(initiateOrgsCSVDownload.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const usersCsvDownloadReducer = usersCsvDownloadSlice.reducer;
export const orgsCsvDownloadReducer = orgsCsvDownloadSlice.reducer;
