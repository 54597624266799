import PropType from "prop-types";
import { Chip } from "@mui/material";
import { useMemo } from "react";

const FrequencyBandChip = ({ deviceFilter, onDelete, lookupValues }) => {
  const { frequencyBand } = deviceFilter;

  const bandList = useMemo(() => {
    if (frequencyBand) {
      return frequencyBand
        .map((band) => {
          const lookup = lookupValues.find((lookup) => lookup.id === band);
          return lookup?.name || band;
        })
        .join(", ");
    }
  });

  // Do not render the chip if the filter is not set
  if (!frequencyBand) {
    return null;
  }

  return (
    <Chip
      onDelete={onDelete}
      label={`Frequency band [${bandList}]`}
      data-testid="frequency-band-chip"
    />
  );
};

FrequencyBandChip.propTypes = {
  deviceFilter: PropType.shape({
    frequencyBand: PropType.arrayOf(PropType.number),
  }),
  onDelete: PropType.func,
  lookupValues: PropType.arrayOf(
    PropType.shape({
      id: PropType.number,
      name: PropType.string,
    })
  ),
};

export default FrequencyBandChip;
