import { ListItem, ListItemButton, ListItemIcon, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import PropType from "prop-types";

const MenuListItem = ({ to, children, isSelected, tooltip }) => {
  return (
    <ListItem>
      <ListItemButton
        component={Link}
        to={to}
        sx={{ justifyContent: "center", alignItems: "center", padding: "0px" }}
      >
        <ListItemIcon
          sx={{
            justifyContent: "center",
            alignItems: "center",
            color: isSelected ? "white" : "pantone.lightBlue",
            "&:hover": { color: "white" },
          }}
        >
          <Tooltip title={tooltip} placement="right">
            {children}
          </Tooltip>
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
  );
};

MenuListItem.propTypes = {
  to: PropType.string.isRequired,
  children: PropType.element,
  isSelected: PropType.bool,
  tooltip: PropType.string,
};

export default MenuListItem;
