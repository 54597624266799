import { Box, Typography } from "@mui/material";

const ErrorElement = () => {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography>There has been an error loading the Charts.</Typography>
      <Typography>Please refresh the browser.</Typography>
    </Box>
  );
};

export default ErrorElement;
