import axios from "axios";

export const fetchIncumbentContour = async (contourFilter) => {
  const response = await axios.post(
    `${window.config.API_ROOT}/incumbentContoursMap`,
    contourFilter,
    {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }
  );
  return response.data;
};

export const fetchIncumbentDeviceOverlap = async (
  deviceId,
  incumbentFilter
) => {
  const params = {};
  if (incumbentFilter?.frequencyRange?.maximum) {
    params.frequencyMaximum = incumbentFilter.frequencyRange.maximum;
  }
  if (incumbentFilter?.frequencyRange?.minimum) {
    params.frequencyMinimum = incumbentFilter.frequencyRange.minimum;
  }
  const response = await axios.get(
    `${window.config.API_ROOT}/incumbentsOverlap/${deviceId}`,
    {
      headers: {
        accept: "application/json",
      },
      params,
    }
  );
  return response.data;
};
