import PropType from "prop-types";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import Skeleton from "@mui/material/Skeleton";

const RequestsPerDayChartSkeleton = ({ error, empty }) => {
  return (
    <>
      {error && !empty && (
        <Alert
          severity="error"
          style={{ width: "90%", marginLeft: "5%", marginTop: "1rem" }}
        >
          <AlertTitle>Error - Loading Daily Number of Requests</AlertTitle>
          {error}
        </Alert>
      )}
      {!error && empty && (
        <Alert
          severity="warning"
          style={{ width: "90%", marginLeft: "5%", marginTop: "1rem" }}
        >
          <AlertTitle>Daily Number of Requests</AlertTitle>
          No data found for given filter conditions.
        </Alert>
      )}
      {!error && !empty && (
        <Skeleton
          sx={{ width: 1, height: 1 }}
          data-testid="requests-per-day-skeleton"
        />
      )}
    </>
  );
};

RequestsPerDayChartSkeleton.propTypes = {
  error: PropType.string,
  empty: PropType.bool,
};

RequestsPerDayChartSkeleton.defaultProps = {
  error: null,
  empty: false,
};

export default RequestsPerDayChartSkeleton;
