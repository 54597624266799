import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearIncumbentContours } from "../incumbentContour/incumbentContourSlice";

export const initialState = {};

export const updateIncumbentFilter = createAsyncThunk(
  "incumbentFilter/update",
  async (newFilter, thunkApi) => {
    thunkApi.dispatch(updateFrequencyRange(newFilter));
    thunkApi.dispatch(clearIncumbentContours());
  }
);

export const incumbentFilterSlice = createSlice({
  name: "incumbentFilter",
  initialState,
  reducers: {
    updateFrequencyRange: {
      reducer: (state, action) => {
        if (action.payload) {
          state.frequencyRange = action.payload;
        } else {
          delete state.frequencyRange;
        }
      },
      prepare: (frequencyRange) => {
        return { payload: frequencyRange };
      },
    },
  },
});

export const { updateFrequencyRange } = incumbentFilterSlice.actions;

export const selectIncumbentFilter = (state) => state.incumbentFilter;

export default incumbentFilterSlice.reducer;
