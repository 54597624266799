import { Box } from "@mui/material";
import PropType from "prop-types";
import styles from "./DeviceClusterMap.module.css";
import { incumbentMarker } from "./IncumbentIcon";

export const LegendButtonIcon = window.btoa(
  `<svg
    xmlns="http://www.w3.org/2000/svg"
    height="40"
    viewBox="0 -960 960 960"
    width="40"
  >
    <path d="M160-200v-66.666h640V-200H160Zm0-166.667v-66.666h640v66.666H160ZM160-536v-78.667L400-760l200 142 200-142v82.666L600-536 397-680 160-536Z" />
  </svg>`
);

export const LegendButton = ({ onClick }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
        borderRadius: "2px",
        cursor: "pointer",
        margin: "10px",
        textAlign: "center",
        title: "Toggle map legend",
      }}
    >
      <button
        onClick={onClick}
        className={styles.mapControlButton}
        data-testid={"show-map-legend-button"}
      >
        <i>
          <img src={`data:image/svg+xml;base64,${LegendButtonIcon}`} />
        </i>
      </button>
    </Box>
  );
};

LegendButton.propTypes = {
  selected: PropType.bool,
  onClick: PropType.func.isRequired,
};
