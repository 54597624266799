import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
  Snackbar,
  Alert,
} from "@mui/material";
import PropTypes from "prop-types";

const ViewToken = ({ open, token, onClose }) => {
  const [snackbarOpen, setOpen] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(token);
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        onClose={handleClose}
        autoHideDuration={2000}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Copied to clipboard!
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <DialogContentText>
            <b>
              Please copy the token using the button below. It will not be
              available again after this dialog is closed!
            </b>
          </DialogContentText>
          <br />
          <DialogContentText
            sx={{
              width: "100%",
              wordWrap: "break-word",
              display: "inline-block",
            }}
          >
            Token: {token}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopy} color="primary">
            Copy
          </Button>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ViewToken.propTypes = {
  open: PropTypes.bool,
  token: PropTypes.string,
  onClose: PropTypes.func,
};

export default ViewToken;
