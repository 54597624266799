import PropType from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import styles from "./DeviceClusterMap.module.css";
import { incumbentMarker, IncumbentButton } from "./IncumbentIcon";
import { deviceMarker } from "./DeviceIcon";
import { createRoot } from "react-dom/client";
import { mapStyleArray } from "./MapStyle";
import { LegendButton } from "./LegendIcon";

export function getWidthChangePercentage(currentBoundary, newBoundary) {
  const currentWidth =
    currentBoundary.getNorthEast().lng() - currentBoundary.getSouthWest().lng();
  const newWidth =
    newBoundary.getNorthEast().lng() - newBoundary.getSouthWest().lng();
  const change = currentWidth - newWidth;
  return Math.abs((change / currentWidth) * 100);
}

export function getHeightChangePercentage(currentBoundary, newBoundary) {
  const currentHeight =
    currentBoundary.getNorthEast().lat() - currentBoundary.getSouthWest().lat();
  const newHeight =
    newBoundary.getNorthEast().lat() - newBoundary.getSouthWest().lat();
  const change = currentHeight - newHeight;
  return Math.abs((change / currentHeight) * 100);
}

export function coloredClusterMarker(
  { count, position },
  { color = "rgb(0,0,255)", itemDesc = "markers" } = {} // options
) {
  // create svg url with fill color
  const svg = window.btoa(`
    <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity=".6" r="70" />
    <circle cx="120" cy="120" opacity=".3" r="90" />
    <circle cx="120" cy="120" opacity=".2" r="110" />
    </svg>`);
  // create marker using svg icon
  return new google.maps.Marker({
    position,
    icon: {
      url: `data:image/svg+xml;base64,${svg}`,
      scaledSize: new google.maps.Size(45, 45),
    },
    label: {
      text: String(count),
      color: "rgba(255,255,255,0.9)",
      fontSize: "12px",
    },
    title: `Cluster of ${count} ${itemDesc}`,
    // adjust zIndex to be above other markers
    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
  });
}

/* The Map needs to be separate from the Wrapper or it will prevent a re-render */
const DeviceClusterMap = ({
  deviceList = [],
  incumbentList = [],
  style,
  center,
  zoom,
  onBoundaryChange,
  showContours,
  onToggleContoursClick,
  incumbentContourList = [],
  onIncumbentClick,
  onDeviceClick,
  showMapLegend,
}) => {
  const ref = useRef(null);
  const [map, setMap] = useState();

  let controlRoot = useRef();
  useEffect(() => {
    if (ref.current && !map) {
      let newMap = new window.google.maps.Map(ref.current, {});

      var controlDiv = document.createElement("div");
      controlRoot.current = createRoot(controlDiv);
      newMap.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(controlDiv);

      setMap(newMap);
    }
  }, [ref, map]);

  // This inserts a React connected component into the google maps button.
  useEffect(() => {
    if (controlRoot.current) {
      controlRoot.current.render(
        <>
          <LegendButton onClick={showMapLegend} />
          <IncumbentButton
            onClick={onToggleContoursClick}
            selected={showContours}
          />
        </>
      );
    }
  }, [controlRoot.current, showContours]);

  useEffect(() => {
    if (map) {
      const singleScaledMarkerSize = new google.maps.Size(27, 27);
      const deviceMarkerList = deviceList.map((device) => {
        let marker;
        if (
          device.count === 1 ||
          (Array.isArray(device.ids) && device.ids.length > 0)
        ) {
          // Single device marker
          marker = new google.maps.Marker({
            position: {
              lat: device.location.latitude,
              lng: device.location.longitude,
            },
            icon: {
              url: `data:image/svg+xml;base64,${deviceMarker}`,
              scaledSize: singleScaledMarkerSize,
            },
            title: "Device",
            map,
          });
          marker.addListener("click", () => onDeviceClick(device.ids));
          // Add a count label if a group of markers
          if (Array.isArray(device.ids) && device.ids.length > 0) {
            marker.setLabel({
              text: `${device.ids.length}`,
              color: "rgba(0,0,0,0.9)",
              fontSize: "12px",
              className: styles.countMarker,
            });
          }
        } else {
          marker = coloredClusterMarker(
            {
              count: device.count,
              position: {
                lat: device.location.latitude,
                lng: device.location.longitude,
              },
            },
            { itemDesc: "devices" }
          );
          marker.setMap(map);
          marker.addListener("click", () => {
            map.setZoom(zoom + 2);
            map.setCenter(marker.getPosition());
          });
        }
        return marker;
      });

      const incumbentMarkerList = incumbentList.map((incumbent) => {
        let marker;
        if (
          incumbent.count === 1 ||
          (Array.isArray(incumbent.ids) && incumbent.ids.length > 0)
        ) {
          // Single device marker
          marker = new google.maps.Marker({
            position: {
              lat: incumbent.location.latitude,
              lng: incumbent.location.longitude,
            },
            icon: {
              url: `data:image/svg+xml;base64,${incumbentMarker}`,
              scaledSize: singleScaledMarkerSize,
            },
            title: "Incumbent",
            map,
          });
          marker.addListener("click", () => onIncumbentClick(incumbent.ids));
          // Add a count label if a group of markers
          if (Array.isArray(incumbent.ids) && incumbent.ids.length > 0) {
            marker.setLabel({
              text: `${incumbent.ids.length}`,
              color: "rgba(0,0,0,0.9)",
              fontSize: "12px",
              className: styles.countMarker,
            });
          }
        } else {
          marker = coloredClusterMarker(
            {
              count: incumbent.count,
              position: {
                lat: incumbent.location.latitude,
                lng: incumbent.location.longitude,
              },
            },
            { color: "rgb(255,0,0)", itemDesc: "incumbents" }
          );
          marker.setMap(map);
          marker.addListener("click", () => {
            map.setZoom(zoom + 2);
            map.setCenter(marker.getPosition());
          });
        }
        return marker;
      });
      const markerList = [...deviceMarkerList, ...incumbentMarkerList];
      return () => {
        markerList.forEach((marker) => marker.setMap(null));
        markerList.length = 0;
      };
    }
  }, [map, deviceList, incumbentList]);

  useEffect(() => {
    if (map && showContours) {
      const contourList = [];
      incumbentContourList.forEach((contour) => {
        const color = contour.color;
        const incumbentContour = new google.maps.Polygon({
          paths: contour.path,
          strokeColor: color,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: color,
          fillOpacity: contour.isHighlighted ? 1 : 0.2,
          // This will bring highlighted contours above unhighlighted contours
          zIndex: contour.isHighlighted
            ? 10000 + contour.detail.properties.frequency
            : contour.detail.properties.frequency,
        });
        contourList.push(incumbentContour);
        incumbentContour.setMap(map);
      });
      return () => {
        contourList.forEach((polygon) => polygon.setMap(null));
        contourList.length = 0;
      };
    }
  }, [map, incumbentContourList, showContours, incumbentList]);

  const boundary = useRef();
  const boundsChangedHandler = useDebouncedCallback(() => {
    if (map) {
      const newBoundary = map.getBounds();
      if (!boundary.current) {
        boundary.current = newBoundary;
        onBoundaryChange(map);
      } else {
        const percentWidthChange = getWidthChangePercentage(
          boundary.current,
          newBoundary
        );
        const percentHeightChange = getHeightChangePercentage(
          boundary.current,
          newBoundary
        );
        // The refresh of other components can cause the map boundary to fluctuate by small amounts
        // Only trigger an update if the width or height have changed by more that 0.5%
        if (percentWidthChange > 0.5 || percentHeightChange > 0.5) {
          boundary.current = newBoundary;
          onBoundaryChange(map);
        }
      }
    }
  }, 500);

  useEffect(() => {
    if (map) {
      google.maps.event.clearListeners(map, "bounds_changed");
      google.maps.event.clearListeners(map, "dragend");
      if (onBoundaryChange) {
        map.addListener("bounds_changed", () => boundsChangedHandler());
        map.addListener("dragend", () => onBoundaryChange(map));
      }
    }
  }, [map, onBoundaryChange]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useEffect(() => {
    if (map) {
      map.setOptions({
        zoom,
        center,
        streetViewControl: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        fullscreenControl: true,
        mapTypeControl: false,
        styles: mapStyleArray,
        minZoom: document.fullscreenElement ? 5 : 4,
      });
    }
  }, [map, zoom, center, document.fullscreenElement]);

  const resizeHandler = useDebouncedCallback(() => {
    if (map) {
      onBoundaryChange(map);
    }
  }, 500);

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [resizeHandler]);

  return <div ref={ref} style={style} data-testid="device-cluster-map" />;
};

DeviceClusterMap.propTypes = {
  deviceList: PropType.array,
  incumbentList: PropType.array,
  style: PropType.object,
  center: PropType.shape({
    lat: PropType.number,
    lng: PropType.number,
  }),
  zoom: PropType.number,
  mapTypeId: PropType.oneOf(["roadmap", "satellite", "hybrid", "terrain"]),
  onBoundaryChange: PropType.func.isRequired,
  showContours: PropType.bool,
  onToggleContoursClick: PropType.func.isRequired,
  incumbentContourList: PropType.array,
  onIncumbentClick: PropType.func,
  onDeviceClick: PropType.func,
  showMapLegend: PropType.func,
};

DeviceClusterMap.defaultProps = {
  deviceList: [],
  style: { flexGrow: "1", height: "100%" },
  center: undefined,
  zoom: undefined,
  mapTypeId: "terrain",
  showContours: false,
};

export default DeviceClusterMap;
