import intervalTypes from "../../../../util/intervalTypes";
import { MAX_PSD_DBM_HZ, MIN_PSD_DBM_HZ } from "./util";
import moment from "moment";

export const validate = (state) => {
  const errors = {};

  if (!state.maxPSD && state.maxPSD !== 0) {
    errors.maxPSD = "Required";
  } else if (state.maxPSD < MIN_PSD_DBM_HZ) {
    errors.maxPSD = `Must be greater than or equal to ${MIN_PSD_DBM_HZ} dBm/Hz`;
  } else if (state.maxPSD > MAX_PSD_DBM_HZ) {
    errors.maxPSD = `Must be less than or equal to ${MAX_PSD_DBM_HZ} dBm/Hz`;
  }

  const maxStartDateMoment = moment(state.maxStartDate);

  if (!state.intervalType.startDateIgnored) {
    const startDateMoment = moment(state.startDate);

    if (state.intervalType.id === intervalTypes.MONTHLY.id) {
      const firstOfTheMonth = maxStartDateMoment.startOf("month");
      if (startDateMoment.isBefore(firstOfTheMonth.subtract(365, "days"))) {
        errors.startDate = "Must be within 365 of the first of this month";
      }
    } else {
      if (startDateMoment.isBefore(maxStartDateMoment.subtract(365, "day"))) {
        errors.startDate = "Must be within the last 365 days";
      }
    }
  }

  state.errors = errors;
  return state;
};

export default validate;
