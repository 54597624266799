import { useMemo } from "react";
import { useTable } from "react-table";

import PropType from "prop-types";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { IconButton, Tooltip } from "@mui/material";
import FlashlightOnIcon from "@mui/icons-material/FlashlightOn";
import FlashlightOffIcon from "@mui/icons-material/FlashlightOff";

const IncumbentsTable = ({ incumbents, onRowSelect }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Callsign",
        accessor: "callsign",
      },
      {
        Header: "Center Frequency",
        accessor: "frequency",
      },
      {
        Header: "Bandwidth",
        accessor: "bandwidth",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: incumbents,
    });

  const handleSelectRow = (incumbent) => {
    onRowSelect(incumbent.id);
  };

  return (
    <TableContainer>
      <Table
        {...getTableProps()}
        data-testid="component-incumbents-table"
        stickyHeader
      >
        <TableHead>
          {headerGroups.map((hg, hgIdx) => (
            <TableRow key={hgIdx} {...hg.getHeaderGroupProps()}>
              {hg.headers.map((col) => (
                <TableCell key={col.id}>{col.render("Header")}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.length === 0 && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No contours visible on map
              </TableCell>
            </TableRow>
          )}
          {rows.map((row, rowIdx) => {
            prepareRow(row);

            return (
              <TableRow
                key={row.original.callsign}
                hover
                {...row.getRowProps({
                  onClick: () => handleSelectRow(row.original),
                  style: {
                    cursor: "pointer",
                    backgroundColor: row.original.color,
                  },
                })}
              >
                {row.cells.map((cell, cellIndex) => (
                  <TableCell
                    key={`${row.original.callsign}-${columns[
                      cellIndex
                    ].Header.toLowerCase()}`}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </TableCell>
                ))}
                <TableCell>
                  <FlashlightOnIcon
                    sx={{
                      visibility: row.original.isHighlighted
                        ? "visible"
                        : "hidden",
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

IncumbentsTable.propTypes = {
  incumbents: PropType.arrayOf(PropType.object).isRequired,
  onRowSelect: PropType.func.isRequired,
};

export default IncumbentsTable;
