import PropType from "prop-types";
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import hash from "object-hash";

const KpiTable = ({ rows, showLoading = false }) => {
  return (
    <>
      {showLoading && (
        <Skeleton
          sx={{
            width: 1,
            height: 1,
            maxHeight: 1,
          }}
        />
      )}

      <TableContainer
        component={Paper}
        sx={{ overflow: "auto", width: 1, height: 1, maxHeight: 1 }}
        itemProp={{ elevation: 3 }}
        data-testid="kpi-table"
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Event</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow key={hash(row)}>
                <TableCell>{row.eventName}</TableCell>
                <TableCell>{row.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

KpiTable.propTypes = {
  rows: PropType.arrayOf(
    PropType.shape({
      eventName: PropType.string,
      count: PropType.number,
    })
  ),
  showLoading: PropType.bool,
};

export default KpiTable;
