export const HIST_DATE_FORMAT = "YYYY-MM-DD";

export const LONG_DATETIME_FORMAT = new Intl.DateTimeFormat("default", {
  month: "long",
  day: "2-digit",
  year: "numeric",
  hour: "2-digit",
  hour12: false,
  minute: "2-digit",
  second: "2-digit",
});

const CFI_MULTIPLIER = 5;
const STARTING_FREQUENCY_MHZ = 5950;
const ENDING_FREQUENCY_MHZ = 7125;

const UNII = {
  5: {
    unii_id: 5,
    display_name: "U-NII-5",
    max_frequency_mhz: 6425,
    min_frequency_mhz: 5925,
  },
  7: {
    unii_id: 7,
    display_name: "U-NII-7",
    max_frequency_mhz: 6875,
    min_frequency_mhz: 6525,
  },
};

const GLOBAL_OPERATING_CLASS = [
  {
    global_operating_class_id: 131,
    starting_frequency_mhz: STARTING_FREQUENCY_MHZ,
    channel_spacing_mhz: 20,
    cfi0: 1,
  },
  {
    global_operating_class_id: 132,
    starting_frequency_mhz: STARTING_FREQUENCY_MHZ,
    channel_spacing_mhz: 40,
    cfi0: 3,
  },
  {
    global_operating_class_id: 133,
    starting_frequency_mhz: STARTING_FREQUENCY_MHZ,
    channel_spacing_mhz: 80,
    cfi0: 7,
  },
  {
    global_operating_class_id: 134,
    starting_frequency_mhz: STARTING_FREQUENCY_MHZ,
    channel_spacing_mhz: 160,
    cfi0: 15,
  },
  {
    global_operating_class_id: 136,
    starting_frequency_mhz: STARTING_FREQUENCY_MHZ,
    channel_spacing_mhz: 20,
    cfi0: 2,
  },
];

/*
  generates an array of the channels mapped to the global operating class, e.g.
  {
    131: {
      [{
        cfi: 1,
        index: 0,
        lowerFreq: 5945, 
        centerFreq: 5955,
        upperFreq: 5965
      },
      {
        cfi: 5,
        index: 1,
      ...
    ]
    }
  }
  The index will start at 0 and increment
*/
function generateChannels() {
  const gocChannels = {};
  GLOBAL_OPERATING_CLASS.forEach((goc) => {
    const chanInc = Math.floor(goc.channel_spacing_mhz / CFI_MULTIPLIER);
    const numChannels =
      goc.global_operating_class_id == 136 // Special case for operating class 136, only a single channel
        ? 1
        : Math.ceil(
            (ENDING_FREQUENCY_MHZ - STARTING_FREQUENCY_MHZ) /
              goc.channel_spacing_mhz
          );
    let channels = [];
    for (let i = 0; i < numChannels; i++) {
      const cfi = goc.cfi0 + i * chanInc;
      const centerFreq = STARTING_FREQUENCY_MHZ + cfi * CFI_MULTIPLIER;
      const lowerFreq = centerFreq - Math.floor(goc.channel_spacing_mhz / 2);
      const upperFreq = centerFreq + Math.floor(goc.channel_spacing_mhz / 2);
      if (upperFreq <= ENDING_FREQUENCY_MHZ) {
        channels.push({
          cfi,
          index: i,
          lowerFreq,
          centerFreq,
          upperFreq,
          bandwidth: goc.channel_spacing_mhz,
        });
      }
    }
    gocChannels[goc.global_operating_class_id] = channels;
  });
  return gocChannels;
}
export const GLOBAL_OPERATING_CLASS_CHANNELS = generateChannels();

// Filters out the exclued channels
function filterChannels() {
  const filteredMap = {};
  GLOBAL_OPERATING_CLASS.forEach((goc) => {
    filteredMap[goc.global_operating_class_id] =
      GLOBAL_OPERATING_CLASS_CHANNELS[goc.global_operating_class_id].filter(
        (channel) =>
          (UNII[5].min_frequency_mhz <= channel.lowerFreq &&
            channel.upperFreq <= UNII[5].max_frequency_mhz) ||
          (UNII[7].min_frequency_mhz <= channel.lowerFreq &&
            channel.upperFreq <= UNII[7].max_frequency_mhz)
      );
  });
  return filteredMap;
}
export const GLOBAL_OPERATING_CLASS_FILTERED_CHANNELS = filterChannels();

function generateChannelMap() {
  const channelMap = {};
  GLOBAL_OPERATING_CLASS.forEach((goc) => {
    const channels =
      GLOBAL_OPERATING_CLASS_CHANNELS[goc.global_operating_class_id];
    channels.forEach((channel) => {
      channelMap[channel.cfi] = channel;
    });
  });
  return channelMap;
}

export const CHANNEL_MAP = generateChannelMap();

export default {
  HIST_DATE_FORMAT,
  LONG_DATETIME_FORMAT,
  GLOBAL_OPERATING_CLASS_CHANNELS: GLOBAL_OPERATING_CLASS_FILTERED_CHANNELS,
};
