import PropType from "prop-types";
import { Autocomplete, TextField } from "@mui/material";

const PrimaryExtensionFilter = ({
  extensionListOptions,
  extensionName,
  selectedValues,
  onChange,
}) => {
  const haveValues = selectedValues && selectedValues.length > 0;
  const handleChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <Autocomplete
      multiple
      options={extensionListOptions}
      onChange={(_, newValue) => handleChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={haveValues ? extensionName : `All ${extensionName}s`}
        />
      )}
      value={selectedValues}
    />
  );
};

PrimaryExtensionFilter.propTypes = {
  extensionListOptions: PropType.arrayOf(PropType.string),
  extensionName: PropType.string,
  onChange: PropType.func,
  selectedValues: PropType.arrayOf(PropType.string),
};

export default PrimaryExtensionFilter;
