import axios from "axios";

export async function fetchDeviceClusters(deviceFilter) {
  const response = await axios.post(
    `${window.config.API_ROOT}/deviceCluster`,
    deviceFilter,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return response.data;
}
