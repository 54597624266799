import PropType from "prop-types";

import Skeleton from "@mui/material/Skeleton";

const DeviceChannelConfigSkeletonChart = ({
  animate,
  "data-testid": dataTestId,
}) => (
  <Skeleton
    variant="rectangular"
    height={100}
    sx={{ my: "1rem", mx: "5%" }}
    animation={animate ? "pulse" : false}
    data-testid={dataTestId}
  />
);

DeviceChannelConfigSkeletonChart.propTypes = {
  animate: PropType.bool.isRequired,
  "data-testid": PropType.string.isRequired,
};

export default DeviceChannelConfigSkeletonChart;
