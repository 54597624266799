import PropType from "prop-types";
import { Chip } from "@mui/material";

const HeightChip = ({ deviceFilter, onDelete }) => {
  const { maxDeviceHeight } = deviceFilter;
  // Do not render the chip if the filter is not set
  if (maxDeviceHeight === undefined) {
    return null;
  }

  return (
    <Chip
      onDelete={onDelete}
      label={`Height [${maxDeviceHeight.minimum}m - ${maxDeviceHeight.maximum}m]`}
      data-testid="height-chip"
    />
  );
};

HeightChip.propTypes = {
  deviceFilter: PropType.shape({
    maxDeviceHeight: PropType.shape({
      maximum: PropType.number,
      minimum: PropType.number,
    }),
  }),
  onDelete: PropType.func,
};

export default HeightChip;
