import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchMaxEirpPerClass as fetchMaxEirpPerClassApi } from "./maxEirpPerClassAPI";
import { error } from "../notification/notificationSlice";
import { deepEqual } from "fast-equals";

export const initialState = {
  resultList: [],
  isFetchingMaxEirpPerClass: false,
  currentFilter: undefined,
};

export const fetchMaxEirpPerClass = createAsyncThunk(
  "maxEirpPerClass/fetch",
  async (deviceFilter, thunkApi) => {
    try {
      thunkApi.dispatch(updateCurrentFilter(deviceFilter));
      return await fetchMaxEirpPerClassApi(deviceFilter);
    } catch (err) {
      thunkApi.dispatch(error(err?.message, "Loading EIRP per class data"));
      return thunkApi.rejectWithValue(err?.message);
    }
  },
  {
    condition: (newFilter, { getState }) => {
      const {
        maxEirpPerClass: { currentFilter },
      } = getState();
      if (deepEqual(currentFilter, newFilter)) {
        return false;
      }
      return true;
    },
  }
);

export const maxEirpPerClassSlice = createSlice({
  name: "maxEirpPerClass",
  initialState,
  reducers: {
    updateCurrentFilter(state, action) {
      state.currentFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMaxEirpPerClass.pending, (state) => {
        state.isFetchingMaxEirpPerClass = true;
      })
      .addCase(fetchMaxEirpPerClass.fulfilled, (state, action) => {
        state.isFetchingMaxEirpPerClass = false;
        state.resultList = action.payload;
      })
      .addCase(fetchMaxEirpPerClass.rejected, (state) => {
        state.isFetchingMaxEirpPerClass = false;
        state.resultList = undefined;
        state.currentFilter = undefined;
      });
  },
});

export const { updateCurrentFilter } = maxEirpPerClassSlice.actions;

export const selectMaxEirpPerClass = (state) =>
  state.maxEirpPerClass.resultList;
export const selectIsFetchingMaxEirpPerClass = (state) =>
  state.maxEirpPerClass.isFetchingMaxEirpPerClass;

export default maxEirpPerClassSlice.reducer;
