import axios from "axios";

export const START_FREQUENCY = 5945;
export const END_FREQUENCY = 7125;
const NUM_FREQUENCY = END_FREQUENCY - START_FREQUENCY + 1;

export function convertMaxPSD(results) {
  let output = [];
  for (let i = 0; i < results.length; i++) {
    const freqPSD = results[i].frequencyPSD;
    const extension = results[i].extension;
    let freqPos = 0;
    for (let j = 0; j < freqPSD.length; j += 2) {
      for (let k = 0; k < freqPSD[j]; k++) {
        output.push({
          average: freqPSD[j + 1],
          extension: extension,
          frequency: START_FREQUENCY + freqPos,
        });
        freqPos++;
      }
    }
  }
  return output;
}

export async function fetchMaxPsdPerFrequency(deviceFilter) {
  const response = await axios.post(
    `${window.config.API_ROOT}/maxPSDPerFrequency`,
    deviceFilter,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  if (response.status !== 200) {
    throw Error(`An error response code was returned: ${response.status}`);
  }
  // Axios will return the data as a string for invalid json
  // We are expecting an Array so need to check it is an Array
  if (!Array.isArray(response.data)) {
    throw Error("Response is not a valid JSON array");
  }

  return convertMaxPSD(response.data);
}
