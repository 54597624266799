import PropType from "prop-types";
import { Box, Button, Paper } from "@mui/material";
import Chart from "react-google-charts";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { errorTypes } from "./errorTypes";

const ErrorChart = ({ data }) => {
  // The google chart does not correctly resize on window resize
  // this will force a rerender of the chart using a key attribute.
  const [chartKey, setChartKey] = useState(0);
  const handleResize = () => {
    setChartKey((prev) => prev + 1);
  };

  const [errorsVisible, setErrorsVisible] = useState(
    Object.keys(errorTypes).map((key) => errorTypes[key])
  );
  const errorIsVisible = (errorType) => {
    return !!errorsVisible.find((error) => error.id === errorType.id);
  };

  const convertData = (data) => {
    const colors = [];
    const chartData = [];
    const chartTypes = [
      {
        type: "date",
        label: "Date",
      },
    ];

    Object.keys(errorTypes).forEach((key) => {
      const errorType = errorTypes[key];
      if (errorIsVisible(errorType)) {
        chartTypes.push({
          type: "number",
          label: errorType.label,
        });
        chartTypes.push({ role: "tooltip", type: "string", p: { html: true } });
        colors.push(errorType.color);
      }
    });
    chartData.push(chartTypes);

    // Google charts has a bug, is misses the first column for continuous axis.
    // Will add an artificial row for the minimum
    const minimumDate = data.reduce((min, row) => {
      const receivedMoment = moment.utc(row.date);
      const minMoment = moment.utc(min.date);
      const newMin = receivedMoment.isBefore(minMoment) ? row : min;
      return newMin;
    });

    const firstRow = [moment.utc(minimumDate.date).subtract(1, "day").toDate()];
    for (let x = 0; x < errorsVisible.length; x++) {
      firstRow.push(0);
      firstRow.push("");
    }
    chartData.push(firstRow);

    data.forEach((row) => {
      const rowData = [moment.utc(row.date).set("hour", 1).toDate()];
      Object.keys(errorTypes).forEach((key) => {
        const errorType = errorTypes[key];
        if (errorIsVisible(errorType)) {
          rowData.push(row[errorType.id] || 0);
          rowData.push(
            `<div style="padding: 10px;"><div><b>${moment
              .utc(row.date)
              .format("MMM DD YYYY")}</b></div><div>${errorType.label}: <b>${
              row[errorType.id] || 0
            }</b></div></div>`
          );
        }
      });
      chartData.push(rowData);
    });
    return {
      chartData,
      colors,
    };
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const { chartData, colors } = useMemo(
    () => convertData(data),
    [data, errorsVisible]
  );

  const options = {
    title: "Status",
    isStacked: "true",
    legend: "none",
    vAxis: {
      minValue: 0,
    },
    hAxis: {
      gridlines: {
        color: "transparent",
      },
    },
    colors: colors,
    tooltip: { isHtml: true, trigger: "visible" },
    areaOpacity: 1,
  };

  const ChartButton = ({ errorType }) => {
    const isVisible = errorIsVisible(errorType);
    return (
      <Button
        size="small"
        sx={{
          backgroundColor: isVisible ? errorType.color : "gray",
          color: "white",
          "&:hover": {
            backgroundColor: isVisible ? errorType.color : "gray",
            opacity: "70%",
          },
        }}
        onClick={() => {
          setErrorsVisible((prev) => {
            if (isVisible) {
              // Don't switch off if this is the last enabled
              if (errorsVisible.length > 1) {
                return prev.filter((error) => error.id !== errorType.id);
              }
              return prev;
            } else {
              return [...prev, errorType];
            }
          });
        }}
      >
        {errorType.label}
      </Button>
    );
  };

  ChartButton.propTypes = {
    errorType: PropType.shape({
      id: PropType.string,
      color: PropType.string,
      label: PropType.string,
    }),
  };

  return (
    <Paper
      elevation={3}
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Chart
          key={chartKey}
          chartType="SteppedAreaChart"
          width="100%"
          height="100%"
          data={chartData}
          options={options}
          legendToggle
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          gap: "10px",
          padding: "1ch",
        }}
      >
        {Object.keys(errorTypes)
          .map((key) => errorTypes[key])
          .map((type) => (
            <ChartButton key={type.id} errorType={type} />
          ))}
      </Box>
    </Paper>
  );
};

ErrorChart.propTypes = {
  data: PropType.arrayOf(
    PropType.shape({
      deviceDisallowed: PropType.shape(),
      generalFailure: PropType.shape(),
      invalidValue: PropType.shape(),
      missingParam: PropType.shape(),
      date: PropType.string,
      success: PropType.number,
      unexpectedParam: PropType.shape(),
      unspecifiedClientError: PropType.shape(),
      unspecifiedServerError: PropType.shape(),
      upsupportedSpectrum: PropType.shape(),
    })
  ),
};

export default ErrorChart;
