import axios from "axios";

export const fetchDeviceDetailById = async (deviceId) => {
  const response = await axios.get(
    `${window.config.API_ROOT}/deviceDetail/${deviceId}`,
    {
      headers: {
        accept: "application/json",
      },
    }
  );
  return response.data;
};
