import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Box, ThemeProvider } from "@mui/system";
import { createTheme, CssBaseline, Skeleton } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import logo from "./images/logo.png";
import "@aws-amplify/ui-react/styles.css";
import AmplifyHubBridge from "./components/AmplifyHub/AmplifyHubBridge";
import {
  selectDeviceMapParams,
  updateLocation,
} from "./redux/deviceMapParams/deviceMapParamsSlice";

Amplify.configure(window.config.AWS_CONFIG);

const theme = createTheme({
  palette: {
    pantone: {
      darkBlue: "#002050",
      blue: "#335773",
      lightBlue: "#a3b3c0",
      darkGreen: "#32e35d",
      green: "#84ee9e",
      lightGreen: "#dsfodf",
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container);
const mapRender = (status) => {
  switch (status) {
    case Status.LOADING:
      return <Skeleton />;
    case Status.FAILURE:
      return <h1>Failure</h1>;
  }
};

const components = {
  Header() {
    return (
      <Box
        sx={{
          width: 1,
          height: "auto",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          backgroundColor: "pantone.darkBlue",
          borderTopLeftRadius: "2px",
          borderTopRightRadius: "2px",
          boxShadow:
            "var(--amplify-components-authenticator-router-box-shadow)",
        }}
      >
        <Box component="img" src={logo} sx={{ width: 1, padding: "20px" }} />
      </Box>
    );
  },
};

const renderPage = () => (
  <Provider store={store}>
    <AmplifyHubBridge />
    <BrowserRouter>
      <Wrapper apiKey={window.config.GOOGLE_MAPS_API_KEY} render={mapRender}>
        <CssBaseline />
        <App />
      </Wrapper>
    </BrowserRouter>
  </Provider>
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      {NO_AUTH ? (
        renderPage()
      ) : (
        <Authenticator hideSignUp={true} components={components}>
          {renderPage}
        </Authenticator>
      )}
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// For automating map moves for integration testing
window.integration_console = {
  update_zoom_center: (zoom, lat, lng) =>
    store.dispatch(updateLocation(zoom, { lat, lng })),
  get_zoom_center: () => {
    const location = selectDeviceMapParams(store.getState()).location;
    console.log(
      `Zoom: ${location.zoom}, Lat: ${location.center.lat}, Lng: ${location.center.lng}`
    );
    return location;
  },
};
