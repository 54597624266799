import { useEffect } from "react";
import PropType from "prop-types";

import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { Box, TextField } from "@mui/material";
import DateRangeFilter from "../../../Kpi/Filters/DateRangeFilter";
import { intervalTypes } from "../../../Kpi/Filters/intervalTypes";
import { deepEqual } from "fast-equals";
import { removeUndefinedProperties } from "../../../../util";

const Filter = ({ currentFilter, onChange }) => {
  const [interval, setInterval] = useState(
    currentFilter.interval?.id
      ? intervalTypes[currentFilter.interval.id]
      : intervalTypes.DAILY
  );
  const [dateRange, setDateRange] = useState(
    currentFilter.dateRange ?? {
      startDate: interval.defaultStartDate(),
      endDate: interval.defaultEndDate(),
    }
  );

  const getNewFilter = () => ({
    intervalType: interval.id,
    startDate: dateRange?.startDate,
    endDate: dateRange?.endDate,
  });

  // Initial value set
  useEffect(() => {
    onChange(getNewFilter());
  }, []);

  useEffect(() => {
    const newFilter = getNewFilter();
    if (!deepEqual(newFilter, currentFilter)) {
      removeUndefinedProperties(newFilter);
      onChange(newFilter);
    }
  }, [interval, dateRange]);

  const handleDateRangeChange = (newValue) => {
    setDateRange(newValue);
  };

  const handleIntervalChange = (interval) => {
    setInterval(interval);
    setDateRange({
      startDate: interval.defaultStartDate(),
      endDate: interval.defaultEndDate(),
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", pt: "1rem" }}>
      <Box sx={{ paddingRight: "16px" }}>
        <TextField
          name="interval"
          label="Interval"
          select
          onChange={(event) => handleIntervalChange(event.target.value)}
          value={interval}
        >
          <MenuItem value={intervalTypes.DAILY}>Daily</MenuItem>
          <MenuItem value={intervalTypes.MONTHLY}>Monthly</MenuItem>
        </TextField>
      </Box>
      <DateRangeFilter
        interval={interval}
        onChange={handleDateRangeChange}
        start={dateRange.startDate}
        end={dateRange.endDate}
      />
    </Box>
  );
};

Filter.propTypes = {
  currentFilter: PropType.object,
  onChange: PropType.func.isRequired,
};

export default Filter;
