import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchOrganizations } from "./getOrganizationsAPI";

export const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const getOrganizations = createAsyncThunk(
  "organization/fetch",
  async (_, thunkApi) => {
    try {
      return await fetchOrganizations();
    } catch (err) {
      return thunkApi.rejectWithValue(err.toString());
    }
  }
);

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrganizations.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(getOrganizations.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export default organizationSlice.reducer;
